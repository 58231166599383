.checkout--confirmation-page {
  .registration-content {
    &__create-account {
      ul.error_messages {
        color: $color-red;
        margin-bottom: 10px;
      }
    }
  }
}

.checkout {
  &.samples-page {
    .samples-content {
      &__button-group--top {
        display: none;
      }
    }
    .product-list {
      li.product {
        list-style: none;
        width: 24.5%;
        padding: 0 10px 0;
        margin: 0 0 20px;
        display: inline-block;
        text-align: center;
        vertical-align: top;
        a.sample-select-button {
          width: auto;
          margin: 15px 0 0;
        }
        input[type='checkbox'] ~ label,
        input[type='checkbox'] ~ .label {
          text-align: left;
          &:before {
            float: none;
            vertical-align: top;
          }
        }
        input.sample-select {
          float: left;
          margin-right: 0.3em;
        }
        .product-brief__name--title {
          display: inline-block;
        }
      }
      @include breakpoint($large-down) {
        li.product {
          width: 100%;
        }
        .product-name,
        .product-subhead,
        .product-size {
          font-size: 11px;
        }
      }
    }
  }
  &-panel__sub-heading {
    &.registration-content {
      &__heading {
        margin-bottom: 25px;
        span {
          padding: 0;
        }
      }
    }
  }
  &.viewcart {
    .cart-items {
      &.products {
        .qty {
          &.cart-items__item--qty {
            margin-top: 14px;
            min-height: 40px;
          }
          &.label {
            padding-left: 15px;
            line-height: 48px;
            @include breakpoint($medium-up) {
              padding-left: 20px;
              line-height: unset;
            }
          }
        }
      }
    }
  }
  &__sidebar {
    @include breakpoint($medium-up) {
      padding: 50px 34px;
    }
    .checkout-panel--order-summary {
      .retail-label {
        width: 55%;
        display: ruby;
        text-wrap: balance;
        padding-#{$rdirection}: 0;
      }
    }
  }
}

.viewcart {
  .paypal-smart-button {
    display: flex;
    column-gap: 8px;
    width: 100%;
    height: 50px;
    margin-bottom: 10px;
    padding: 1px;
    &.paypal-loading {
      position: relative;
      background: $color-white url(/media/images/global/ajax-loading.gif) no-repeat center center;
      text-align: center;
    }
    .paypal-payment-spinner {
      display: flex;
      height: 100%;
      align-items: center;
      justify-content: center;
      background: $color-white;
      &__icon {
        display: inline-block;
        background: url(/media/images/global/ajax-loading.gif) no-repeat left center;
        width: 31px;
        height: 31px;
        margin-right: 8px;
      }
    }
  }
  .checkout-panel--bottom-viewcart-buttons {
    .paypal-smart-button {
      @include breakpoint($medium-up) {
        width: 368px;
        margin-#{$ldirection}: auto;
        margin-bottom: 10px;
      }
    }
  }
  .checkout__sidebar {
    .checkout-panel--offer-code {
      .offer-code__messages {
        &,
        a {
          color: $color-error-message;
        }
        &.success {
          &,
          a {
            color: $color-success-message;
          }
        }
        &.deferred {
          &,
          a {
            color: $color-alert-message;
          }
        }
      }
    }
  }
  .checkout-panel {
    .messages {
      .single-message {
        &,
        a {
          color: $color-error-message;
        }
        &[id^='error_offer_criteria_met'] {
          &,
          a {
            color: $color-success-message;
          }
        }
        &.threshold-messages {
          &,
          a {
            color: $color-alert-message;
          }
        }
      }
    }
  }
}

.gift-options-content {
  .giftwrap {
    &__message--option {
      padding-bottom: 20px;
      width: 100%;
      padding-right: 0;
      float: none;
      @include breakpoint($medium-up) {
        padding-bottom: 25px;
        float: left;
        padding-right: 13px;
      }
    }
  }
  .giftCard {
    &-message {
      width: 100%;
    }
  }
}

.ship-method {
  &__selectbox {
    @include breakpoint($medium-up) {
      width: 100%;
    }
    &.selectBox-dropdown,
    &-selectBox-dropdown-menu {
      .selectBox-label,
      li a {
        @include swap_direction(padding, 0 10px);
        font-size: 11px;
      }
    }
  }
}

#viewcart {
  .checkout-panel {
    min-height: auto;
    .donation-price {
      &__input-field {
        border-#{$ldirection}: 0;
        border-#{$rdirection}: 0;
        font-size: 14px;
        padding: 0 2px;
        width: 30%;
        @include breakpoint($large-down) {
          font-size: 12px;
          width: 40%;
        }
      }
      &__update-field {
        border: 1px solid $color-gray;
        &:disabled {
          &::before {
            opacity: 0.2;
          }
        }
      }
    }
    &--multi-donation {
      border: 1px solid $color-gray;
      margin-bottom: 30px;
      padding: 15px;
      .view-content-button {
        #{$rdirection}: 0;
        cursor: pointer;
        opacity: 0.54;
        position: absolute;
        top: 0;
      }
      .checkout-panel__heading {
        cursor: pointer;
        font-weight: bold;
        margin-bottom: 0;
      }
      .promo-multi-donation {
        margin-top: 15px;
        &__img-container {
          display: flex;
          height: 120px;
          width: auto;
          .product-image {
            @include swap_direction(margin, 10px 20px 10px 0);
            border: 1px solid $color-gray;
            border-radius: 8px;
            box-shadow: 0 0 1px $color-black;
            cursor: pointer;
            max-width: 155px;
            padding: 8px;
            @include breakpoint($medium-up) {
              max-width: 170px;
            }
            &.active {
              border: 1px solid $color-black;
            }
          }
        }
        &__container {
          width: 100%;
          .donation-amounts {
            margin-bottom: 25px;
            width: 100%;
          }
          .donation-items,
          .donation-select {
            @include swap_direction(margin, 10px 6px 0 0);
            display: inline-block;
            width: 30%;
            @include breakpoint($medium-up) {
              margin-#{$rdirection}: 10px;
              width: 18%;
            }
            .donation-label {
              font-size: 16px;
            }
          }
        }
        &__heading,
        &__product-name,
        &__header {
          font-weight: bold;
        }
        &__product-name,
        .promo-bca-content__note {
          font-size: 16px;
          margin: 5px 0;
          text-transform: capitalize;
        }
        &__description {
          font-size: 15px;
          margin: 0 0 5px;
        }
        &__header {
          font-size: 18px;
        }
      }
    }
    .donation {
      .cart-items__item {
        &--qty {
          .donation-price-label {
            margin: 2px 0 10px;
          }
        }
        &--price {
          margin-top: 55px;
        }
        &--total {
          margin-top: 56px;
        }
      }
    }
  }
}

#signin {
  .checkout-panel--order-summary {
    overflow: inherit;
    .retail-label {
      width: 69%;
      padding-#{$rdirection}: 0;
      @include breakpoint($medium-up) {
        width: 65%;
      }
    }
    .terms-tooltip {
      bottom: 2px;
    }
  }
}
