// Specific styles for the customer service contact us form.
// @todo styleguide updates - commented out styles are so styleguide can prevail
// @todo remove commented code

.cs-contact-form {
  margin-bottom: 1em;
  &__section {
    max-width: 100%;
    padding: 0 1em;
    @include breakpoint($landscape-up) {
      padding: 0;
    }
    input[type='email'],
    input[type='email'] + label,
    input[type='tel'],
    input[type='tel'] + label,
    input[type='password'],
    input[type='password'] + label,
    input[type='text'],
    input[type='text'] + label,
    textarea + label,
    select {
      font-size: 10px;
      @include breakpoint($landscape-up) {
        font-size: 12px;
      }
    }
    .adpl & {
      textarea + label {
        height: calc(8em - -18px);
        margin-top: calc(-8em - 18px);
        @include breakpoint($landscape-up) {
          height: calc(8em - -2px);
          margin-top: calc(-8em - 2px);
        }
      }
    }
    input[type='radio'].error + label {
      color: $color-red;
    }
    select {
      height: calc(3.2em - -2px);
      line-height: 34px;
      @include breakpoint($landscape-up) {
        line-height: 40px;
      }
    }
    .cs-contact-form {
      &__header {
        font-size: 16px;
        font-weight: normal;
        letter-spacing: 0.1em;
        line-height: 24px;
        padding: 0 0 20px;
        position: relative;
        text-align: center;
        overflow: hidden;
        @include breakpoint($landscape-up) {
          font-size: 18px;
          padding: 0 0 40px;
        }
        span {
          display: inline-block;
          position: relative;
          padding: 0 20px;
          vertical-align: baseline;
          zoom: 1;
          &:before {
            #{$rdirection}: 100%;
          }
          &:after {
            #{$ldirection}: 100%;
          }
          &:before,
          &:after {
            border-top: 1px solid $color-border;
            content: '';
            display: block;
            width: 10000px;
            position: absolute;
            top: 12px;
          }
        }
      }
      &__category-content {
        margin-bottom: 10px;
        margin-top: 25px;
        @include breakpoint($landscape-up) {
          margin-bottom: 5px;
          margin-top: 60px;
        }
      }
    }
    #contact_us & {
      &:last-child {
        padding-top: 5px;
        @include breakpoint($landscape-up) {
          padding-top: 0;
        }
      }
    }
    &.comments {
      margin-bottom: 5px;
    }
    .section_chooser {
      color: $color-darker-gray;
      width: 100%;
      @include breakpoint($landscape-up) {
        width: 85%;
      }
    }
    &.contact_information {
      margin-top: 4em;
      .cs-contact-information {
        &.left {
          @include breakpoint($landscape-up) {
            float: #{$ldirection};
            margin-#{$rdirection}: 30px;
            width: 47%;
          }
        }
        &.right {
          @include breakpoint($landscape-up) {
            float: #{$rdirection};
            width: 48%;
          }
        }
        .cs-contact-information {
          &__text {
            font-size: 13px;
            margin-bottom: 40px;
          }
          &__days {
            ul {
              li {
                .bold {
                  font-weight: 500;
                }
              }
            }
          }
        }
      }
      .online_customer,
      .brand_store,
      .general_queries {
        font-size: 15px;
        font-weight: 500;
      }
    }
  }
  input[type='number'],
  input[type='text'],
  input[type='submit'],
  textarea {
    width: 100%;
  }
  &__input {
    margin: 0.5em 0;
    @include breakpoint($landscape-up) {
      width: 100%;
      margin: 1em 0 0;
    }
    input {
      border-color: $color-border;
    }
    textarea {
      &.adpl--processed {
        padding-top: 0;
        @include breakpoint($landscape-up) {
          padding-top: 10px;
        }
      }
    }
    &--half {
      margin: 0 0 1.5em;
      @include breakpoint($landscape-up) {
        float: #{$ldirection};
        width: 49%;
        &.left {
          @include swap_direction(margin, 1em 1em 0.5em 0);
          .country_field {
            width: 100%;
          }
        }
        &.right {
          @include swap_direction(margin, 1em 0 0.5em 0);
        }
      }
      &.left {
        select {
          width: 100%;
        }
        &:first-child {
          padding-top: 5px;
          @include breakpoint($landscape-up) {
            padding-top: 0;
          }
        }
        &.registered_question {
          margin: 3em 0 1em;
          width: 100%;
          @include breakpoint($landscape-up) {
            margin: 2em 0 0;
          }
          label {
            &.registerLabel {
              color: $color-black;
              display: block;
              margin-bottom: 1em;
            }
          }
        }
        &.email_promotions {
          margin-bottom: 3em;
          width: 100%;
          @include breakpoint($landscape-up) {
            width: 67%;
          }
          label {
            color: $color-black;
            line-height: 17px;
            &:before {
              @include swap_direction(margin, 0 10px 0 0);
              display: inline-block;
              float: #{$ldirection};
              font-family: 'icons';
              font-style: normal;
              font-weight: normal;
              line-height: inherit;
              vertical-align: middle;
              speak: none;
              text-decoration: inherit;
              text-transform: none;
              text-rendering: optimizeLegibility;
            }
            .label-content {
              display: table;
              letter-spacing: 0.02em;
              padding-#{$ldirection}: 8px;
            }
          }
          input[type='checkbox'] {
            border: 1px solid $color-darker-gray;
            border-color: $color-darker-gray;
            outline: none;
            resize: none;
            &:checked {
              & ~ label:before {
                color: $color-darker-gray;
              }
            }
          }
        }
      }
      input,
      select {
        border-color: $color-border;
      }
    }
  }
  &__submit {
    width: 100%;
    @include breakpoint($landscape-up) {
      display: inline-block;
      float: #{$rdirection};
      margin-top: 7em;
      width: 20%;
    }
    input {
      width: 100%;
      @include breakpoint($landscape-up) {
        width: auto;
      }
    }
    .button-loading {
      width: 100%;
      line-height: normal;
      padding-top: 12px;
      padding-bottom: 12px;
    }
  }
  &__categories {
    @include clearfix;
    margin-bottom: 1em;
  }
  &__category {
    @include button;
    @include swap_direction(margin, 0 0.5em 0.5em 0);
    float: #{$ldirection};
    width: 48%;
    padding: 0.5em 1em;
    color: $color-black;
    background: $color-white;
    border-width: 1px;
    cursor: pointer;
    text-align: center;
    &.active {
      color: $color-white;
      background: $color-black;
    }
  }
  &__radio {
    display: inline-block;
    height: 2em;
    line-height: 1em;
    margin: 0;
    width: 25%;
    @include breakpoint($landscape-up) {
      width: 13%;
    }
    &--input {
      vertical-align: middle;
    }
    &--label {
      margin-#{$ldirection}: 1em;
    }
  }
  &__errors {
    @include swap_direction(margin, 0.5em 0 1em 0);
    color: $color-error;
    padding: 0 1em;
    display: none;
    li {
      margin-bottom: 0.5em;
      color: $color-error;
    }
  }
}

.cs-page--contact-form {
  .cs-page__title {
    @include swap_direction(margin, 0 0 1em 0);
    padding: 0 0.5em;
  }
}
