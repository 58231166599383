.selectBox-dropdown-menu {
  &.selectBox-attached-selectBox-dropdown-menu {
    z-index: 10;
  }
}

.gnav-util--search {
  .esearch-nav__suggestions-wrapper {
    .esearch-product--typeahead {
      .endeca-product__image {
        width: 65%;
      }
    }
  }
  .typeahead-wrapper {
    .product-results {
      padding-top: 5px;
    }
  }
}

.content-block2 {
  &.content-block2__textbox-pos--right,
  .content-block2--v1 .content-block2__textbox-pos--right {
    .content-block2__inner {
      top: 92%;
      $ldirection: 47%;
    }
  }
}
.basic-noderef--sticky-livechat {
  float: $ldirection;
  display: block;
  position: fixed;
  top: 86%;
  z-index: 100;
  width: 20%;
  #{$rdirection}: 30px;
  @include breakpoint($medium-up) {
    width: 6%;
  }
}
