.content-block4 {
  width: 100%;
  overflow: hidden;
  @include breakpoint($medium-up) {
    margin: 50px 0;
  }
  &__text-heading {
    margin-top: 30px;
    @include breakpoint($medium-up) {
      margin-top: 10px;
    }
  }
  &__image-side,
  &__text-side {
    @include breakpoint($medium-up) {
      width: 50%;
      clear: none;
      float: #{$ldirection};
    }
  }
  &__text-side {
    padding: 0 30px;
    @include breakpoint($medium-up) {
      padding: 0 4%;
    }
  }
  &__image-side {
    padding: 0 50px;
    @include breakpoint($medium-up) {
      @include swap_direction(padding, 0 4% 0 0);
    }
    .slick-prev,
    .slick-next {
      &:before {
        font-size: 24px;
        @include breakpoint($medium-up) {
          font-size: 50px;
        }
      }
      &:hover {
        color: black;
      }
    }
    .slick-prev {
      #{$ldirection}: -40px;
      @include breakpoint($medium-up) {
        #{$ldirection}: -50px;
      }
    }
    .slick-next {
      #{$rdirection}: 9959px;
      @include breakpoint($medium-up) {
        #{$rdirection}: 9949px;
      }
    }
  }
  &__carousel {
    text-align: center;
    &.image-full-width {
      .content-block4__carousel-slide {
        width: 100%;
      }
    }
  }
  &--right {
    @include breakpoint($medium-up) {
      float: #{$rdirection};
    }
  }
  &__align-left {
    text-align: #{$ldirection};
  }
  &__align-center {
    text-align: center;
  }
  &__align-right {
    text-align: #{$rdirection};
  }
}
