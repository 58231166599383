///
/// customer service internal page
///
.cs-page {
  header {
    background-color: $color-lighter-gray;
    margin-bottom: 30px;
    padding: 20px 0;
    @include breakpoint($medium-up) {
      margin-bottom: 10px;
      padding: 60px 0;
    }
    .cs-page {
      &__title {
        border: none;
        h1 {
          font-family: $font--heading;
          font-size: 40px;
          line-height: 50px;
          @include breakpoint($medium-up) {
            font-size: 50px;
          }
        }
      }
    }
  }
  .content-container {
    border: none;
    margin: 0 auto;
    max-width: 1280px;
    padding: 0;
    position: relative;
    @include breakpoint($medium-up) {
      padding: 0 75px 15px;
    }
    .cs-page__content {
      padding: 0 20px;
      width: 100%;
      @include breakpoint($medium-up) {
        padding: 0;
        width: 62%;
      }
      &-item {
        .cs-page {
          &__faq-wrapper {
            border: none;
            margin-bottom: 20px;
            padding-top: 0;
            @include breakpoint($medium-up) {
              border-top: 1px solid $color-gray;
            }
            .cs-faq-show {
              font-size: 15px;
              line-height: 15px;
              margin: 25px 0;
              padding-#{$ldirection}: 1em;
              text-transform: uppercase;
              &__content {
                margin: 40px 0;
                padding: 0 1em;
              }
            }
            .collapsible-block {
              border-bottom: 1px solid $color-gray;
              padding: 1em 2em;
              &:first-child {
                border-top: 1px solid $color-gray;
              }
              @include breakpoint($medium-up) {
                padding: 1em;
              }
              header {
                background-color: transparent;
                margin-bottom: 0;
                padding: 0;
              }
              &__title {
                font-size: 13px;
                font-weight: 500;
                text-transform: uppercase;
                @include breakpoint($medium-up) {
                  font-size: 15px;
                }
                span {
                  display: block;
                  margin-#{$rdirection}: 45px;
                  @include breakpoint($medium-up) {
                    margin-#{$rdirection}: 0;
                  }
                }
              }
              &__content {
                padding-top: 0;
              }
            }
          }
          &__contact-wrapper {
            .cs-page__contacts {
              text-align: center;
              &-item {
                float: none;
                display: inline-block;
                margin: 1em 0;
                padding: 0;
                width: 38.333%;
                @include breakpoint($medium-up) {
                  @include swap_direction(margin, 0 5em 0 0);
                  padding: 50px 0;
                  width: 30.333%;
                }
                img {
                  display: block;
                  height: auto;
                  margin: 0 auto;
                  @include breakpoint($medium-up) {
                    height: 67px;
                  }
                }
                &--call {
                  margin-#{$rdirection}: 3.5em;
                  margin-#{$ldirection}: 2em;
                  @include breakpoint($medium-up) {
                    margin-#{$ldirection}: 0;
                    margin-#{$rdirection}: 5em;
                  }
                }
                &--email {
                  margin-#{$rdirection}: 2em;
                  @include breakpoint($medium-up) {
                    margin-#{$rdirection}: 0;
                  }
                }
              }
              &-text {
                padding: 1em 0;
                text-align: justify;
                @include breakpoint($medium-up) {
                  padding: 0;
                }
              }
            }
          }
        }
      }
    }
    .cs-page__navigation {
      @include swap_direction(margin, 25px 10px 60px 0);
      background-color: $color-lighter-gray;
      border: none;
      padding: 0;
      width: 100%;
      @include breakpoint($medium-up) {
        @include swap_direction(padding, 10px 35px 0 35px);
        margin-top: 0;
        width: 32%;
      }
      .content {
        h2 {
          font-size: 18px;
          letter-spacing: 0.1em;
          line-height: 24px;
          margin-bottom: 25px;
          text-transform: uppercase;
        }
        .h2 {
          padding: 35px 35px 20px;
          margin: 0 1em;
        }
        .collapsible-menu {
          .cs-menu {
            &__parent,
            &__static {
              border: 0;
              margin-bottom: 20px;
              padding: 0;
              &.collapsible-menu__parent--expanded {
                display: block;
                .collapsible-sub-menu {
                  display: block;
                  margin: 1em;
                  padding: 0 18px;
                  .cs-menu__children {
                    padding-top: 0;
                    a {
                      padding-top: 0;
                    }
                  }
                }
              }
              .collapsible-menu-trigger {
                cursor: text;
                margin: 1em 2em;
                text-transform: uppercase;
                &:after {
                  display: block;
                  @include breakpoint($medium-up) {
                    display: none;
                  }
                }
                @include breakpoint($medium-up) {
                  @include swap_direction(margin, 0 0 1em 0);
                }
              }
              .collapsible-sub-menu {
                display: none;
                @include breakpoint($medium-up) {
                  display: block;
                }
                .cs-menu__children {
                  @include swap_direction(padding, 7px 0 0 0);
                  li {
                    a {
                      @include swap_direction(padding, 10px 0 0 0);
                      border: none;
                      color: $color-darker-gray;
                      display: inline-block;
                      font-size: 14px;
                      font-weight: 500;
                      letter-spacing: 0.1em;
                      text-transform: uppercase;
                    }
                  }
                }
              }
            }
            &__parent {
              border-bottom: 1px solid $color-gray;
              margin: 1em 0;
              @include breakpoint($medium-up) {
                border-bottom: none;
                @include swap_direction(margin, 0 0 4em 0);
              }
            }
            &__static {
              margin-bottom: 0;
              &:nth-child(2) {
                margin-bottom: 60px;
              }
              a {
                @include swap_direction(padding, 10px 0 0 0);
                border: none;
                color: $color-darker-gray;
                display: inline-block;
                font-size: 14px;
                font-weight: 500;
                letter-spacing: 0.1em;
                text-transform: uppercase;
              }
              &.cs-menu__faq {
                a {
                  color: $color-black;
                }
              }
            }
          }
        }
      }
    }
  }
}

.cs-page__title {
  text-align: center;
  border-bottom: $color-black solid 1px;
}

.cs-page__footer {
  position: relative;
}

///
/// CS expanding menu
///
.cs-menu {
}

.cs-menu__parent {
  > h3 {
    &:after {
      font-size: 12px;
    }
  }
}

.cs-menu__children {
  @include swap_direction(padding, 0 0 1em 1em);
  line-height: 2em;
}
// static link inside expando-menu
.collapsible-menu__static {
}
