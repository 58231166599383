.welcomeoffer-overlay {
  #cboxLoadedContent {
    @include breakpoint($medium-up) {
      padding: 0;
    }
    background: $color-transparent-dark;
    .popover-email-signup {
      @include breakpoint($medium-up) {
        width: 100%;
        min-width: 721px;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        overflow: hidden;
      }
      .popup-offer {
        &__sign-up-form-error-messages {
          &.error {
            color: $color-red;
          }
        }
      }
      .site-email-signup {
        padding: 40px 20px 30px;
        background: $color-white;
        @include breakpoint($medium-up) {
          padding: 20px;
        }
        &-bg {
          float: none;
          display: block;
          margin: 0 auto;
          @include breakpoint($medium-up) {
            float: $rdirection;
          }
        }
        &--wrapper {
          float: none;
          @include breakpoint($medium-up) {
            float: $ldirection;
            width: 50%;
            max-width: 360px;
          }
        }
        &--bg_content {
          @include breakpoint($medium-up) {
            float: $rdirection;
            width: 50%;
            max-width: 360px;
          }
        }
        &-offer {
          text-align: center;
        }
        &-header {
          margin-bottom: 20px;
        }
        form {
          margin-top: 20px;
        }
        .form--newsletter {
          &__form-items {
            .form-type-textfield {
              @include swap_direction(margin, 0 0 10px 0);
              @include swap_direction(padding, 10px 0 0 0);
            }
            .sign-in-component {
              &__birthday-program {
                text-align: center;
                .select-month,
                .select-day,
                .select-year {
                  @include swap_direction(margin, 10px 0 10px 0);
                  width: 100%;
                  @include breakpoint($medium-up) {
                    min-width: 100px;
                    width: auto;
                  }
                }
              }
            }
            .form-submit {
              margin-top: 25px;
            }
          }
        }
      }
    }
    .millennials-popover {
      background-size: contain;
      background-position: center;
      background-color: $color-black;
      .site-email-signup--form {
        .section-head__header {
          font-size: 11px;
          margin-bottom: 0;
          span {
            padding: 0 10px;
          }
        }
      }
      .site-email-signup {
        padding: 25px 35px;
        @include breakpoint($medium-up) {
          padding: 25px 50px;
        }
        .section-head {
          margin-#{$ldirection}: -25px;
          margin-#{$rdirection}: -25px;
        }
        form {
          margin-top: 10px;
          .form--newsletter__form-items {
            .sign-in-component__birthday-program {
              text-align: $ldirection;
              margin-top: 20px;
              label {
                font-size: 12px;
                font-weight: bold;
                color: $color-black;
                letter-spacing: 0.3px;
              }
            }
            @include breakpoint($medium-up) {
              .day-of-birth {
                &__collection-field {
                  select {
                    background-position: calc(100% + 6px);
                    padding-#{$ldirection}: 8px;
                  }
                  .select-day {
                    min-width: auto;
                    width: 71px;
                    margin-#{$rdirection}: 5px;
                  }
                  .select-month {
                    min-width: auto;
                    width: 85px;
                    margin-#{$rdirection}: 5px;
                  }
                  .select-year {
                    min-width: auto;
                    width: 85px;
                  }
                }
              }
            }
          }
        }
      }
      .site-email-interest {
        span {
          font-weight: bold;
          margin: 15px 0 8px;
          display: inline-block;
          font-size: 12px;
        }
        .teaser-check {
          position: relative;
          clear: both;
          margin-bottom: 12px;
          input[type='checkbox'] {
            opacity: 0;
            #{$ldirection}: 0;
            width: 20px;
            height: 20px;
            top: 4px;
          }
          label {
            font-size: 12px;
            line-height: 26px;
            &:before {
              font-size: 20px;
            }
          }
        }
      }
      .user_terms {
        font-size: 11px;
        line-height: 16px;
        margin-top: 22px;
        margin-bottom: 0;
      }
    }
  }
  #cboxClose {
    @include swap_direction(padding, 10px 10px 0 0);
    color: $color-white;
    @include breakpoint($medium-up) {
      padding: 0;
    }
  }
  &.success {
    #cboxLoadedContent {
      background: $color-white;
    }
    #cboxClose {
      color: $color-black;
    }
  }
}
