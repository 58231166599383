.teaser-countdown {
  width: 100%;
  margin: 0 auto;
  background-color: $color-black;
  .teaser-align-left {
    margin-top: 42px;
    width: 100%;
    @media screen and (min-width: $medium-up) {
      margin: 0;
      width: 50%;
    }
  }
  .teaser-arrow-align {
    position: relative;
    span {
      position: absolute;
      #{$rdirection}: 10px;
      top: 5px;
    }
  }
  .teaser-banner {
    position: relative;
    text-align: center;
    &-video {
      position: relative;
      z-index: 1;
      top: 0;
      bottom: 0;
      width: 100%;
      overflow: hidden;
      height: 600px;
      @media screen and (min-width: $medium-up) {
        height: auto;
      }
      video {
        min-width: 100%;
        min-height: 100%;
        object-fit: cover;
      }
    }
    &-content {
      color: $color-button-light-gray;
      position: absolute;
      #{$ldirection}: 0;
      #{$rdirection}: 0;
      top: 50%;
      transform: translateY(-50%);
      z-index: 1;
      font-family: 'Impact', Arial, sans-serif;
      padding: 0 25px;
      @media screen and (min-width: $medium-up) {
        padding: 0;
      }
      button {
        margin-top: 25px;
        color: $color-button-light-gray;
        background-color: transparent;
        border: 1px solid $color-button-gray;
        cursor: pointer;
        font-family: 'Impact', Arial, sans-serif;
        letter-spacing: 2px;
        padding: 15px 0;
        font-size: 15px;
        width: 100%;
        @media screen and (min-width: $medium-up) {
          padding: 11px 35px;
          font-size: 12px;
          width: auto;
        }
      }
      &__slidearrow {
        display: none;
        margin-top: 35px;
        background-repeat: no-repeat;
        background-size: 30px;
        padding: 20px 0;
        background-position: center;
        cursor: pointer;
        @media screen and (min-width: $medium-up) {
          background-size: 22px;
        }
      }
    }
  }
  .teaser-signup {
    width: 100%;
    background: $color-bg-black;
    padding: 35px 0;
    @media screen and (min-width: $medium-up) {
      padding: 70px 0;
    }
    select {
      border: 1px solid $color-near-gray;
      width: 100%;
      font-size: 10px;
      letter-spacing: 1px;
      color: $color-button-light-gray;
      background: transparent;
      line-height: 1;
      padding-#{$ldirection}: 16px;
      height: 40px;
      @media screen and (min-width: $medium-up) {
        font-size: 12px;
        height: 50px;
      }
      option {
        background: $color-bg-black;
        color: $color-button-light-gray;
      }
    }
    &-bg {
      width: 100%;
      padding: 0 15px;
      margin: 0 auto;
      @media screen and (min-width: $medium-up) {
        width: 820px;
        padding: 0;
      }
      &__form {
        width: 100%;
        margin: 0 auto;
        color: $color-button-light-gray;
        font-family: 'Helvetica Neue LT Pro', sans-serif;
        font-weight: 500;
        font-style: normal;
        @media screen and (min-width: $medium-up) {
          padding: 0 20px;
        }
      }
      &__important {
        margin-top: 40px;
        span {
          display: block;
          text-align: #{$ldirection};
          font-size: 12px;
          font-family: 'Helvetica Neue LT Pro', sans-serif;
          font-weight: normal;
          font-style: normal;
          @media screen and (min-width: $medium-up) {
            font-size: 14px;
          }
        }
        &--error {
          ul {
            margin: 5px 0;
            li {
              color: $color-red;
              font-size: 12px;
              @media screen and (min-width: $medium-up) {
                font-size: 14px;
              }
            }
          }
        }
      }
      &__details {
        overflow: hidden;
        .teaser-left {
          width: 100%;
          float: none;
          padding: 0;
          display: block;
          margin-top: 17px;
          @media screen and (min-width: $medium-up) {
            width: 50%;
            float: #{$ldirection};
          }
          input {
            border: 1px solid $color-near-gray;
            color: $color-white;
            background: transparent;
            @include input-placeholder {
              color: $color-button-light-gray;
              font-family: 'Helvetica Neue LT Pro', sans-serif;
              font-weight: 500;
              font-style: normal;
            }
          }
          &:nth-child(odd) {
            padding: 0;
            @media screen and (min-width: $medium-up) {
              padding-#{$rdirection}: 15px;
            }
          }
          .error {
            border: 1px solid $color-red;
            @include input-placeholder {
              color: $color-red;
            }
          }
        }
      }
      &__interest {
        overflow: hidden;
        margin: 0;
        @media screen and (min-width: $medium-up) {
          margin: 20px 0;
        }
        &--most {
          float: none;
          text-align: #{$ldirection};
          @media screen and (min-width: $medium-up) {
            float: #{$ldirection};
          }
          .teaser-check {
            &:not(:last-child) {
              margin-bottom: 20px;
              @media screen and (min-width: $medium-up) {
                margin-bottom: 5px;
              }
            }
          }
        }
        &--dob {
          float: none;
          text-align: #{$ldirection};
          @media screen and (min-width: $medium-up) {
            float: #{$rdirection};
          }
          select {
            @media screen and (min-width: $medium-up) {
              padding-#{$ldirection}: 10px;
            }
            &:nth-child(3),
            &:nth-child(2) {
              margin-#{$rdirection}: 5px;
            }
          }
          .error {
            border: 1px solid $color-red;
            color: $color-red;
          }
          &.show-year {
            .teaser-left {
              width: 50%;
            }
          }
          .teaser-left {
            width: 33.333%;
            float: #{$ldirection};
            &:not(:last-child) {
              padding-#{$rdirection}: 10px;
            }
          }
        }
        h5 {
          font-size: 15px;
          letter-spacing: 1px;
          font-family: 'Helvetica Neue LT Pro', sans-serif;
          font-weight: 500;
          font-style: normal;
          margin-bottom: 20px;
          @media screen and (min-width: $medium-up) {
            margin-bottom: 10px;
          }
        }
      }
      &__gender {
        overflow: hidden;
        .teaser-check {
          display: inline-block;
          width: 50%;
          margin-top: 22px;
          margin-#{$rdirection}: 0;
          float: #{$ldirection};
          color: $color-button-light-gray;
          @media screen and (min-width: $medium-up) {
            @include swap_direction(margin, 10px 16px 0 0);
            width: auto;
          }
        }
        h5 {
          font-size: 15px;
          letter-spacing: 1px;
        }
      }
      &__accept {
        width: 100%;
        margin-top: 40px;
        padding: 0;
        @media screen and (min-width: $medium-up) {
          width: 50%;
          padding-#{$rdirection}: 15px;
        }
        &--policy {
          float: #{$ldirection};
          width: 95%;
          padding-#{$ldirection}: 25px;
          p {
            font-size: 12px;
            letter-spacing: 1px;
            color: $color-button-light-gray;
            margin-top: 0;
            line-height: 20px;
            @media screen and (min-width: $medium-up) {
              margin-top: 8px;
              letter-spacing: 0;
              line-height: 17px;
            }
            &:last-child {
              margin-top: 12px;
            }
          }
          a {
            color: $color-button-light-gray;
            text-decoration: underline;
            cursor: none;
            @media screen and (min-width: $medium-up) {
              cursor: pointer;
            }
          }
        }
        &--check {
          float: #{$ldirection};
          width: 5%;
        }
        button {
          margin-top: 30px;
          width: 100%;
          @media screen and (min-width: $medium-up) {
            padding: 10px 15px;
            letter-spacing: 1px;
          }
        }
      }
    }
  }
  .teaser-action {
    padding: 10px 15px;
    font-weight: normal;
    cursor: pointer;
    font-family: 'Impact', Arial, sans-serif;
    letter-spacing: 2px;
    background-color: $color-button-light-gray;
    border: none;
    color: $color-black;
    font-size: 14px;
    @media screen and (min-width: $medium-up) {
      padding: 12px 15px;
      font-size: 14px;
    }
  }
  .teaser-control {
    width: 100%;
    font-size: 10px;
    text-indent: 12px;
    letter-spacing: 1px;
    color: $color-button-light-gray;
    padding: 15px 0;
    height: 40px;
    @media screen and (min-width: $medium-up) {
      padding: 0;
      font-size: 12px;
      height: 50px;
    }
  }
  .teaser-check {
    display: block;
    position: relative;
    cursor: none;
    user-select: none;
    overflow: hidden;
    font-family: 'Helvetica Neue LT Pro', sans-serif;
    font-weight: normal;
    font-style: normal;
    @media screen and (min-width: $medium-up) {
      cursor: pointer;
    }
    input {
      position: absolute;
      opacity: 0;
      cursor: none;
      #{$ldirection}: 0;
      z-index: 11;
      width: 20px;
      height: 20px;
      top: 4px;
      @media screen and (min-width: $medium-up) {
        cursor: pointer;
      }
    }
    label {
      font-size: 12px;
      letter-spacing: 1px;
      color: $color-button-light-gray;
      line-height: 1;
      cursor: none;
      vertical-align: sub;
      @media screen and (min-width: $medium-up) {
        letter-spacing: 0;
        line-height: 1.5;
        cursor: pointer;
      }
      &:before {
        font-size: 25px;
        color: $color-gray;
        margin-#{$rdirection}: 15px;
        margin-top: 0;
        @media screen and (min-width: $medium-up) {
          font-size: 20px;
          margin-#{$rdirection}: 10px;
        }
      }
    }
  }
  .teaser-form-title {
    width: 100%;
    text-align: center;
    position: relative;
    .teaser-tag {
      border: 1px solid $color-near-gray;
      position: absolute;
      top: 50%;
      #{$ldirection}: 0;
      #{$rdirection}: 0;
    }
    h4 {
      background-color: $color-bg-black;
      position: relative;
      width: 100%;
      margin: 0 auto;
      font-size: 14px;
      color: $color-button-light-gray;
      letter-spacing: 2px;
      text-align: #{$ldirection};
      font-family: 'Helvetica Neue LT Pro', sans-serif;
      font-weight: 500;
      font-style: normal;
      z-index: 2;
      padding: 0 7px;
      @media screen and (min-width: $medium-up) {
        width: 650px;
        text-align: center;
        font-size: 15px;
      }
    }
  }
  .teaser-user-thankyou {
    background-color: $color-bg-black;
    display: none;
    &_form {
      width: 100%;
      padding: 45px 15px;
      margin: 0 auto;
      @media screen and (min-width: $medium-up) {
        width: 820px;
        padding: 80px 0;
        text-align: center;
      }
      button {
        margin-top: 40px;
        width: 100%;
        a {
          border: none;
          &:hover {
            color: $color-black;
          }
        }
        @media screen and (min-width: $medium-up) {
          width: 50%;
          margin-top: 45px;
        }
      }
      h4 {
        font-weight: normal;
        width: 80%;
        text-align: center;
        @media screen and (min-width: $medium-up) {
          width: 550px;
          line-height: 24px;
        }
      }
    }
  }
  .teaser-zero-alert {
    color: $color-alert-red;
  }
}

/* ************* Countdown Styles Starts **************** */
.site-header {
  &.countdown-timer {
    height: auto;
    .site-header__main {
      position: relative;
    }
    .gnav-util__content {
      top: 174px;
    }
  }
}

.teaser-banner-content__timer {
  width: 100%;
  margin: auto;
  display: none;
  &--2 {
    display: block;
    text-align: center;
  }
  @media screen and (min-width: $medium-up) {
    width: 800px;
  }
  &--count {
    display: inline-block;
    padding: 12px 10px;
    text-align: center;
    h1 {
      font-size: 45px;
      letter-spacing: 5px;
      line-height: 1.3;
      font-family: 'Impact';
      @include breakpoint($landscape-up) {
        font-size: 110px;
        line-height: 100px;
        letter-spacing: 15px;
      }
    }
    h4 {
      font-size: 14px;
      font-weight: normal;
      letter-spacing: 2px;
      margin: 0;
      font-family: 'Impact';
      text-transform: uppercase;
      @include breakpoint($landscape-up) {
        font-size: 18px;
      }
    }
  }
  h3 {
    font-size: 20px;
    font-weight: normal;
    padding: 0;
    margin-top: 0;
    letter-spacing: 3px;
    font-family: 'Impact';
    line-height: 25px;
    @include breakpoint($landscape-up) {
      font-size: 22px;
    }
  }
  canvas {
    display: none;
  }
}

.teaser-header-timer {
  display: none;
  text-align: center;
  background: $color-darker-red;
  line-height: normal;
  padding: 10px 0;
  &__options {
    &--1,
    &--2 {
      display: block;
    }
  }
  &_count {
    display: inline-block;
    margin-#{$rdirection}: 5px;
    h2,
    h5 {
      display: inline-block;
      font-size: 14px;
      letter-spacing: 1px;
      text-transform: uppercase;
      margin: 0;
      span {
        color: inherit;
      }
      a {
        color: inherit;
        text-decoration: underline;
        border: none;
      }
      &.days,
      &.hours,
      &.minutes {
        font-weight: 500;
      }
    }
    h2 {
      font-weight: normal;
    }
  }
  &__content {
    &--header,
    &--readmore {
      display: block;
      @include breakpoint($landscape-up) {
        display: inline-block;
      }
    }
  }
}

/* ************* Countdown Styles Ends **************** */
