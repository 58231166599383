/* Order Details */
.order-details-page {
  &__content {
    .order {
      &-details {
        &__payment {
          border-bottom: none;
          margin-bottom: 0;
          padding-bottom: 0;
        }
      }
      &-products {
        .cart-item {
          width: 100%;
          position: relative;
          padding: 20px 0 0;
          border-bottom: solid 1px $color-silver;
          &__wrap {
            &.mobile-hidden {
              display: none;
              @include breakpoint($landscape-up) {
                display: block;
              }
            }
            border-bottom: solid 1px $color-silver;
          }
          &__product-subname,
          &__product-sub_line {
            display: none;
          }
          &__tracking,
          &__status,
          &__price {
            min-height: 20px;
          }
          &__price {
            font-size: inherit;
          }
          &__price-label,
          &__total-label,
          &__shade-label,
          &__size-label,
          &__tracking-label,
          &__status-label,
          &__qty-label {
            display: inline-block;
            @include breakpoint($landscape-up) {
              display: none;
            }
          }
          &__shade {
            margin: 0;
          }
          &__error--out-of-stock,
          &__replenishment {
            clear: both;
            display: inline-block;
            margin: 10px 0 0;
            width: 100%;
            p {
              margin: 0;
            }
          }
          &__header {
            float: #{$ldirection};
            font-size: 15px;
            font-weight: bold;
            text-transform: uppercase;
          }
          @include breakpoint($landscape-up) {
            &__products,
            &__thumb,
            &__desc,
            &__price,
            &__qty,
            &__tracking,
            &__status,
            &__total {
              float: #{$ldirection};
              text-align: #{$ldirection};
              padding-#{$rdirection}: 1%;
              width: 11%;
              word-wrap: break-word;
            }
            &__tracking {
              width: 20%;
            }
            &__total {
              width: 17%;
              padding-#{$ldirection}: 1.7%;
              padding-#{$rdirection}: 0;
            }
            &__qty {
              text-align: center;
              width: 16%;
              margin-bottom: 0;
            }
            &__shade-label,
            &__size-label {
              display: inline-block;
            }
          }
          &__products {
            @include breakpoint($landscape-up) {
              width: 56%;
            }
            .cart-item {
              &__thumb {
                &-image {
                  width: auto;
                }
                @include breakpoint($landscape-up) {
                  float: #{$ldirection};
                  width: 24%;
                  height: auto;
                  &-image {
                    width: 100%;
                  }
                }
              }
              &__desc {
                text-transform: uppercase;
                word-break: break-word;
                margin-top: 10px;
                @include breakpoint($landscape-up) {
                  width: 70%;
                  margin-top: 0;
                }
              }
              &__product-name {
                @include breakpoint($landscape-up) {
                  position: relative;
                  bottom: 3px;
                  margin-bottom: 0;
                }
              }
            }
          }
          &.product {
            .cart-item {
              &__price,
              &__qty {
                float: none;
                text-align: #{$ldirection};
                width: 100%;
                @include breakpoint($medium-up) {
                  float: #{$ldirection};
                  width: 16%;
                }
              }
              &__price {
                .product-price {
                  margin-bottom: 5px;
                  text-decoration: line-through;
                  @include breakpoint($medium-up) {
                    margin-bottom: 10px;
                  }
                }
                @include breakpoint($medium-up) {
                  text-align: #{$ldirection};
                  width: 11%;
                }
              }
              &__qty {
                text-align: #{$ldirection};
                @include breakpoint($medium-up) {
                  text-align: center;
                }
              }
            }
          }
        }
      }
      &-total {
        float: none;
        width: 100%;
        margin: 10px 0;
        @include breakpoint($landscape-up) {
          float: #{$rdirection};
          width: 40%;
        }
        table {
          width: 100%;
          tr {
            line-height: 30px;
            th {
              padding-#{$ldirection}: 0;
              width: 60%;
              @include breakpoint($landscape-up) {
                padding-#{$ldirection}: 10%;
              }
            }
            td {
              padding-#{$ldirection}: 0;
              width: 40%;
              text-align: #{$rdirection};
              @include breakpoint($landscape-up) {
                padding-#{$ldirection}: 7%;
              }
            }
          }
        }
        .order-summary {
          padding: 2rem;
        }
      }
    }
  }
  .terms-tooltip {
    bottom: 2px;
  }
}

.order-products {
  margin-top: 80px;
}
