///
/// @file template_api/endeca/_search_results.scss
///
/// \brief Search Results
///

.search-wrapper {
  //@include responsive-container;
  position: relative;
  min-height: 300px;
  &__inner {
    max-width: 1230px;
    margin: 0 auto;
  }
  // overlay
  &:before {
    content: '';
    display: none;
    position: absolute;
    background: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
    top: 0px;
    #{$ldirection}: 0px;
    z-index: 1;
  }
  body.typeahead-active & {
    &:before {
      display: block;
    }
  }
  @include breakpoint($medium-up) {
    min-height: 500px;
  }
  .search-wrapper__loading {
    text-align: center;
    padding: 20px 0px;
    &--img {
      max-width: 40px;
    }
  }
  .search-wrapper__header {
    @include h1;
    text-align: center;
  }
  .search-wrapper__results {
    @include pie-clearfix;
    margin: 0 auto;
    max-width: $max-width-large;
    .product-grid {
      .product-grid__item {
        float: #{$ldirection};
        @include breakpoint($small-up) {
          min-height: 290px;
        }
        @include breakpoint($medium-up) {
          min-height: 400px;
        }
        .product-brief {
          &__name,
          &__sub-name,
          &__price {
            font-size: 12px;
            line-height: 13px;
            @include breakpoint($medium-up) {
              margin-top: 12px;
              font-size: 13px;
              line-height: 14px;
            }
          }
          &__name {
            @include breakpoint($medium-up) {
              margin-top: 28px;
            }
          }
          &__quickshop-launch-wrapper {
            // someone ammended the quickshop js to not remove
            // based on non-quickshop class. so doing it here
            display: none;
          }
        }
      }
    }
  }
  &__header {
    h1 {
      body.endeca-no-results & {
        //display: none;
      }
      color: $color-black;
      @include h1;
      margin: 50px 0px;
      @include breakpoint($landscape-up) {
        margin: 75px 0px;
      }
    }
  }
  .summary {
    body.endeca-has-results & {
      display: none !important;
    }
    padding: 20px 0;
    text-align: center;
    text-transform: uppercase;
    span {
      font-weight: bold;
      .not-bold {
        font-weight: normal;
      }
    }
  }
  .results {
    .results__products {
      max-width: 100%;
      .products {
        .products__header {
          // temp hide sort
          display: none;
        }
      }
    }
    .results__content {
      .results-summary {
        // temp hide sort
        display: none;
      }
    }
  }
  .search-wrapper__bestsellers {
    // This is the main wrapper for the "no results" content
    .bestseller-results {
      padding-top: 40px;
    }
  }
}

// Search results page:
.search-results {
  padding: 0;
  @include breakpoint($medium-up) {
    padding: 0 20px;
  }
}

@include breakpoint($medium-up) {
  body.toolbar.device-pc {
    .page-wrapper {
      .search-results__header {
        padding-top: 2em;
      }
    }
  }
}

.search-results__header {
  background: $color-light-silver;
  padding: 30px 0 0 0;
  @include breakpoint($medium-up) {
    padding: 45px 0 0 0;
  }
  &--results {
    min-height: auto;
  }
  .search-form--results {
    min-height: auto;
  }
}

.search_results__summary {
  padding: 0 10px;
  text-align: center;
}

// smashbox_base/template_api/endeca/esearch_results_pc_v1.mustache
.results__header--column {
  display: none;
  margin: 0 12px;
  margin-bottom: 1em;
  @include breakpoint($medium-up) {
    @include swap_direction(margin, 0 60px 0 0);
    border: none;
    width: 37.5%;
    max-width: 375px;
    float: #{$ldirection};
    height: 100%;
    display: block;
    margin-bottom: 1em;
  }
}

.results__header__inner {
  @include breakpoint($medium-up) {
    background: $color-light-silver;
    padding: 50px;
  }
}

.results__products--column {
  @include swap_direction(margin, 0 7px 20px 7px);
  @include breakpoint($medium-up) {
    margin: 0;
    width: 64%;
    float: #{$ldirection};
    padding-bottom: 60px;
  }
  // due to header-column max-width. we want this column to fill space
  // and not header column past 1000px
  @include breakpoint(1000px) {
    width: calc(100% - 375px - 60px);
  }
  .results__header--dimensions {
    text-align: center;
    margin-bottom: 2em;
  }
}

.esearch-product {
  width: 100%;
}

.results__header-filter-content--mobile-collapsed {
  display: none;
  @include breakpoint($medium-up) {
    display: block;
  }
}

.breadcrumbs__link {
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
}

.breadcrumbs__link-close {
  font-size: 12px;
  height: 12px;
  line-height: 9px;
  width: 12px;
}
// plain styles for responsive result on search page
.endeca-product-result {
  padding: 1em 0;
}

// dimensions / filters
.results-filter__header {
  @include icon('plus', after);
  &.collapse {
    @include icon('minus', after);
  }
  &:after {
    @include swap_direction(padding, 0 0 6px 10px);
    font-size: 12px;
    @include breakpoint($medium-up) {
      display: none;
    }
  }
}

.results__header {
  text-transform: uppercase;
  .results-header {
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.13em;
    @include breakpoint($medium-up) {
      @include swap_direction(margin, 0 0 10px 0);
      font-size: 15px;
      line-height: 24px;
      letter-spacing: 0.13em;
    }
    &.dimensions__header {
    }
  }
}

.recent-searches__results {
  a {
    @include font-face--helvetica-medium;
    color: $color-darker-gray;
    text-decoration: none;
    border: 0;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.13em;
    @include breakpoint($medium-up) {
      font-size: 13px;
      line-height: 50px;
    }
  }
}
