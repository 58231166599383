.check-giftcard-balance {
  .giftcard-balance {
    margin: 15px;
    @include breakpoint($medium-up) {
      margin: 30px auto;
      max-width: 665px;
    }
    &__header {
      @include h2;
      background-color: $color-white;
      font-weight: 700;
      margin: 0 auto;
      max-width: 175px;
      padding: 0 15px;
      text-align: center;
      text-transform: uppercase;
      @include breakpoint($medium-up) {
        max-width: 250px;
      }
      &-wrapper {
        margin-bottom: 15px;
        position: relative;
        &::after {
          border-bottom: 1px solid $color-light-stone-gray;
          content: '';
          display: block;
          position: absolute;
          top: 50%;
          width: 100%;
          z-index: -1;
        }
      }
    }
    &__form-content {
      @include breakpoint($medium-up) {
        margin-#{$ldirection}: 10px;
      }
    }
    &__form-element {
      margin: 15px 0;
      @include breakpoint($medium-up) {
        display: inline-block;
        padding: 0 10px;
        width: 30%;
      }
      input {
        display: block;
        margin: 5px 0;
        width: 100%;
      }
    }
    .fe_validatable {
      input[type='tel'] {
        &.gc_giftcard_number,
        &.gc_giftcard_pin {
          border-color: $color-darker-gray;
          &.checkedEmpty {
            border-color: $color-red;
          }
        }
      }
    }
    &__label-span {
      text-transform: uppercase;
    }
    &__information {
      margin: 10px auto;
      padding-#{$ldirection}: 18px;
      li {
        list-style-type: disc;
        padding: 3px 0;
      }
    }
    &__errors {
      margin: 10px 0;
    }
    &__remaining {
      font-weight: 700;
      margin-#{$ldirection}: 20px;
    }
  }
}
