// Full-width template
.content-block2 {
  height: auto;
  position: relative;
  overflow: hidden;
  width: 100%;
  // Inner Text styling
  &__heading {
    color: $color-white;
    @include h1;
    @media screen and (#{$medium-up}) {
      @include h1;
    }
    @include breakpoint($medium-up) {
      margin-bottom: 20px;
    }
  }
  &__subheading {
    margin: 40px 0px;
    @include breakpoint($medium-up) {
      margin-bottom: 33px;
      margin-top: 20px;
    }
  }
  &__subheading-text {
    background-color: transparent;
    display: inline-block;
    text-align: center;
    color: $color-white;
    padding: 0 8px;
    @include h6;
    //font-size: 18px;
    font-weight: normal; // 55 roman
    margin-#{$ldirection}: auto;
    margin-#{$rdirection}: auto;
  }
  &__subheading--hasline {
    .content-block2__subheading-text {
      text-align: center;
      @include line-behind-text();
      @include h2;
      // Line though text decoration
      @include breakpoint($medium-up) {
        @include line-behind-header();
      }
    }
  }
  &__maintext {
    text-align: center;
    color: $color-white;
    margin-bottom: 22px;
    visibility: visible;
    height: auto;
    opacity: 1;
    transition: all 0.5s ease-in-out;
    &--style {
      &-body {
        @include text-body;
      }
      &-intro {
        @include text-intro;
      }
      &-pull_quote {
        @include text-pull_quote;
      }
    }
    &--align {
      &-center {
        position: center;
      }
      &-justify {
        position: justify;
      }
      &-top25 {
        padding-top: 25px;
      }
      &-bottom25 {
        padding-bottom: 25px;
      }
    }
  }
  // open close behavior
  &__readmore {
    color: $color-white;
    margin-bottom: 22px;
    font-size: 11px;
    display: block;
    text-transform: uppercase;
    position: relative;
    & .readmore-arrow {
      -moz-transform: rotateX(180deg);
      -webkit-transform: rotateX(180deg);
      transform: rotateX(180deg);
      font-size: 28px;
      font-family: serif;
      display: block;
      margin-top: -14px;
    }
    &_container {
      overflow: hidden;
      transition: all 0.5s ease-in-out;
      // hide by default
      height: 0;
      visibility: hidden;
      .readmore-label {
        height: auto;
        visibility: visible;
      }
      .readless-label {
        height: 0;
        visibility: hidden;
      }
      // When expanded
      &.readmore-expanded {
        // show container contents
        height: auto;
        visibility: visible;
        .readmore-label {
          height: 0;
          visibility: hidden;
        }
        .readless-label {
          height: auto;
          visibility: visible;
        }
      }
    }
  }
  &__button {
    display: inline-block;
    background-color: transparent;
    pointer-events: all;
    &--opt1 {
      border: 1px solid $color-white;
    }
    &--opt2 {
      border: 1px solid $color-black;
    }
    &--opt4 {
      @include button-secondary-inverted;
    }
  }
  // image ============================
  &__media,
  &__image {
    background: $color-black;
    text-align: center;
    width: 100%;
    & img {
      display: block;
      width: 100%;
      height: auto;
    }
  }
  // Inner text/button container ============
  &__inner {
    background-color: rgba(0, 0, 0, 1);
    width: 100%;
    text-align: center;
    position: absolute;
    z-index: 3;
    padding: 35px 0px;
    pointer-events: none;
    @include breakpoint($medium-up) {
      background-color: rgba(0, 0, 0, 0.85);
      padding-top: 0px;
      padding-bottom: 0px;
    }
    &-textbox {
      width: 90%;
      margin-#{$ldirection}: auto;
      margin-#{$rdirection}: auto;
      @include breakpoint($medium-up) {
        width: 80%;
      }
    }
  }
  // Mobile
  // Drop inner text area to Outerlow
  .content-block2__inner {
    position: static;
  }
  // Desktop
  @include breakpoint($medium-up) {
    &.content-block2__textbox-pos--centered {
      .content-block2__inner {
        @include vertical-horizontal-center;
        position: absolute;
      }
    }
    &.content-block2__textbox-pos--bottom {
      .content-block2__inner {
        padding-top: 40px;
        padding-bottom: 40px;
        bottom: 0;
        position: absolute;
        .content-block2__button {
          margin-bottom: 0;
        }
      }
    }
    // Positioned Left
    &.content-block2__textbox-pos--left {
      .content-block2__inner {
        width: 64%;
        height: 100%;
        -webkit-transform: translate(0, -50%);
        -ms-transform: translate(0, -50%);
        transform: translate(0, -50%);
        top: 50%;
        position: absolute;
        #{$ldirection}: 0;
        &-textbox {
          -webkit-transform: translate(0, -50%);
          -ms-transform: translate(0, -50%);
          transform: translate(0, -50%);
          top: 60%;
          position: absolute;
          #{$ldirection}: 10%;
          max-width: 550px;
        }
      }
    }
    // Positioned  Right
    &.content-block2__textbox-pos--right {
      .content-block2__inner {
        width: 50%;
        height: 100%;
        -webkit-transform: translate(0, -50%);
        -ms-transform: translate(0, -50%);
        transform: translate(0, -50%);
        top: 50%;
        position: absolute;
        #{$rdirection}: 0;
      }
    }
    // Positioned Outside Above Image
    &.content-block2__textbox-pos--outertop {
      display: table;
      .content-block2__media,
      .content-block2__image {
        display: table-row-group;
      }
      .content-block2__inner {
        position: static;
        display: table-header-group;
      }
    }
    // Positioned Outside Below Image
    &.content-block2__textbox-pos--outerlow {
      .content-block2 {
        &__inner {
          position: static;
          &-textbox {
            padding: 4% 0;
            .content-block2__button {
              margin-bottom: 0;
            }
          }
        }
      }
    }

    // Positioned Outside Left of Image
    &.content-block2__textbox-pos--outerleft {
      .content-block2 {
        &__media,
        &__image {
          position: static;
          float: #{$rdirection};
          width: 47.5%;
          transform: none;
        }
        &__inner {
          position: static;
          float: #{$ldirection};
          width: 47.5%;
          &-textbox {
            .content-block2__button {
              margin-bottom: 0;
            }
          }
        }
      }
    }

    // Positioned Outside Right of Image
    &.content-block2__textbox-pos--outerright {
      .content-block2 {
        &__media,
        &__image {
          position: static;
          transform: none;
          @include breakpoint($medium-up) {
            float: #{$ldirection};
            width: 50%;
          }
        }
        &__inner {
          position: static;
          @include breakpoint($medium-up) {
            width: 50%;
            float: #{$ldirection};
          }
          &-textbox {
            .content-block2__button {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
  div:empty {
    display: none;
  }
}

.content-block2.content-block2__style--image_right {
  display: table;
  .content-block2 {
    &__media,
    &__image {
      @include breakpoint($medium-up) {
        width: auto;
        max-width: 460px;
        float: #{$rdirection};
      }
      @include breakpoint($landscape-up) {
      }
    }
    &__inner {
      @include breakpoint($medium-up) {
        width: calc(100% - 360px);
        padding: 0px;
        &-textbox {
          top: 50%;
          #{$ldirection}: 50%;
          @include transform(translate(-50%, -50%));
        }
      }
      @include breakpoint($landscape-up) {
        width: calc(100% - 460px);
      }
    }
    &__subheading {
      margin-top: 0px;
      margin-bottom: 5px;
      @include breakpoint($medium-up) {
        margin-bottom: 22px;
      }
    }
    &__subheading-text {
      @include line-behind-text();
      @include h2;
      font-size: 11px;
      @include breakpoint($medium-up) {
        font-size: 14px;
      }
    }
    &__subheading--hasline {
      .content-block2__subheading-text {
        @include breakpoint($medium-up) {
          @include line-behind-header();
          &:before,
          &:after {
            top: 0.5em;
          }
        }
      }
    }
    &__maintext {
      font-size: 10px;
      letter-spacing: 0.13em;
      margin-bottom: 15px;
      @include breakpoint($medium-up) {
        margin-bottom: 22px;
        font-size: 17px;
        letter-spacing: 0.13em;
      }
    }
  }
}

.content-block2.content-block2__style--no_image {
  display: table;
  .content-block2 {
    &__media,
    &__image {
      display: none;
    }
    &__inner-textbox {
    }
    &__maintext {
      padding: 0px 15px;
    }
    &__subheading--hasline {
      .content-block2__subheading-text {
        @include line-behind-text();
        @include h2;
        @include line-behind-header();
        font-size: 11px;
        @include breakpoint($medium-up) {
          font-size: 14px;
        }
        &:before,
        &:after {
          top: 0.5em;
          color: #c8c4c3;
          border-color: $color-darker-gray-hr;
        }
        &:before {
          #{$rdirection}: 1.5%;
        }
        &:after {
          #{$ldirection}: 1.5%;
        }
      }
    }
  }
}

.content-block2.content-block2__style--quote_box {
  &.content-block2__textbox-pos--left {
    .content-block2 {
      &__inner {
        position: absolute;
        top: 0px;
        @include breakpoint($medium-up) {
          top: 50%;
        }
        &-textbox {
          width: 75%;
          #{$ldirection}: auto;
          #{$rdirection}: 20%;
          top: 50%;
          @include breakpoint($extra-large-up) {
            #{$rdirection}: 25%;
            width: 90%;
          }
        }
      }
      &__subheading {
        font-weight: 500;
        &-text {
          color: $color-button-light-gray;
        }
      }
      &__subheading--hasline {
        .content-block2__subheading-text {
          @include line-behind-text();
          @include h2;
          @include line-behind-header();
          &:before,
          &:after {
            border-top: 1px solid $color-button-gray;
            top: 0.5em;
          }
        }
        @include breakpoint($medium-up) {
          margin-top: 15px;
          margin-bottom: 28px;
        }
        @include breakpoint($landscape-up) {
          margin-top: 20px;
          margin-bottom: 33px;
        }
      }
      &__maintext {
        color: $color-button-light-gray;
        margin-top: 20px;
        margin-bottom: 30px;
        @include text-pull_quote;
        @include breakpoint($medium-up) {
          font-size: 18px;
          line-height: 24px;
          margin-top: 15px;
          margin-bottom: 25px;
        }
        @include breakpoint($landscape-up) {
          font-size: 26px;
          line-height: 40px;
          margin-top: 40px;
          margin-bottom: 50px;
        }
      }
      &__button {
        width: 100%;
        @include breakpoint($medium-up) {
          width: auto;
        }
        a {
          width: 100%;
          @include breakpoint($medium-up) {
            width: auto;
          }
        }
      }
    }
  }
}

.content-block2.content-block2__style--only_heading {
  .content-block2 {
    &__media,
    &__image {
      @include center-hero-image;
    }
    &__inner {
      background: rgba(0, 0, 0, 0.85);
      position: absolute;
      bottom: 0px;
      @include breakpoint($medium-up) {
        background: rgba(0, 0, 0, 0.5);
      }
    }
    &__heading {
      color: $color-white;
      margin: 0px;
      @include breakpoint($medium-up) {
        color: $color-pull-quote-gray;
      }
    }
  }
}

.content-block2.content-block2__style--quote_box_no_image {
  &.content-block2__textbox-pos--centered {
    .content-block2 {
      &__media,
      &__image {
        position: absolute;
        height: 100%;
        img {
          height: auto;
        }
      }
      &__inner {
        position: relative;
        top: auto;
        #{$ldirection}: auto;
        transform: none;
        @include breakpoint($medium-up) {
          padding: 0px;
        }
      }
      &__maintext {
        padding: 20px 20px;
        font-size: 17px;
        margin-bottom: 0px;
        @include breakpoint($medium-up) {
          padding: 100px 0px;
          font-size: 26px;
        }
      }
    }
  }
}

.content-block2.content-block2__style--only_image {
  .content-block2 {
    &__media,
    &__image {
      max-width: 1020px;
      margin-#{$ldirection}: auto;
      margin-#{$rdirection}: auto;
    }
    &__inner {
      display: none;
    }
  }
}

.homepage-hero {
  .content-block2 {
    &__media,
    &__image {
      @include center-hero-image;
    }
  }
}
