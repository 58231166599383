.static-links {
  padding: 1em;
  max-width: 1024px;
  margin: 0 auto;
  h2 {
    margin: 1em 0;
  }
  ul {
    margin-#{$ldirection}: 1em;
  }
  // unset styles that may accidentally get picked up with path classnames
  li {
    padding: 0 0 0.5em 0.5em !important;
    background: none !important;
    border-width: 0 !important;
    text-align: #{$ldirection} !important;
    position: relative !important;
    transition: none !important;
    width: auto !important;
    top: auto !important;
    height: auto !important;
    #{$ldirection}: auto !important;
    #{$rdirection}: auto !important;
    z-index: 1 !important;
  }
  //   .gnav-offers,
  //   .styleguide {
  //     background: transparent !important;
  //   }
}
