#cookie_popover_container {
  @include swap_direction(padding, 25px 30px 25px 22px);
  bottom: 0;
  #{$ldirection}: 0;
  border: 10px solid $color-black;
  background-color: $color-white;
  color: $color-black;
  font-size: 13px;
  height: auto;
  position: fixed;
  #{$rdirection}: 0;
  width: 100%;
  word-wrap: break-word;
  z-index: 10;
  @include breakpoint($medium-up) {
    width: 390px;
  }
  .cookie_legal-language {
    color: $color-dark-gray;
    .cookie_legal-close_box {
      position: absolute;
      top: 5px;
      #{$rdirection}: 10px;
      font-weight: bold;
      cursor: pointer;
    }
  }
}
