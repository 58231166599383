%account-page-grid {
  @include swap_direction(margin, 0 4% 80px 0);
  float: #{$ldirection};
  font-size: 12px;
  position: relative;
  width: 48%;
  &:nth-child(2n) {
    margin-#{$rdirection}: 0;
  }
  @include breakpoint($landscape-up) {
    margin-#{$rdirection}: 1.2%;
    width: 32.13%;
    &:nth-child(2n) {
      margin-#{$rdirection}: 1.2%;
    }
  }
}
/* Common Styles for Favorites and Past Purchases */
.favorites-page__content {
  padding-bottom: 10px;
  .favorites {
    @extend %account-page-grid;
    margin-bottom: 20px;
    .product-image {
      margin-bottom: 15px;
      img {
        width: 100%;
        height: 100%;
        @include breakpoint($landscape-up) {
          width: auto;
        }
      }
    }
    .product-info {
      &__name {
        margin-bottom: 5px;
      }
      &__sizes {
        margin-bottom: 5px;
      }
      &__button {
        margin: 10px 0;
      }
    }
  }
  .favorite-actions {
    a {
      text-transform: uppercase;
      text-decoration: underline;
      margin-#{$rdirection}: 5px;
      @include breakpoint($landscape-up) {
        margin-#{$rdirection}: 10px;
      }
    }
  }
  .favorites-mylists {
    clear: both;
  }
}
