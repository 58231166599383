///
/// collapsible-block
/// A content block that becomes click-expandable with a title
///
.collapsible-block {
  border-bottom: $color-black solid 1px;
  padding: 1em;
  position: relative;
}

.collapsible-block__title {
  font-size: 2em;
  position: relative;
  cursor: pointer;
}

.collapsible-block__icon {
  @include icon('plus', after);
  @include single-transition(
    $default-transition-property,
    $default-transition-duration,
    $default-transition-function
  );
  position: absolute;
  #{$rdirection}: 0;
  top: 0;
  #{$rdirection}: 1em;
  .collapsible-block--alt & {
    @include icon('minus', after);
  }
  .collapsible-block--mobile-collapsed & {
    @include breakpoint($medium-down) {
      @include icon('minus', after, $mq: true);
    }
  }
  .collapsible-block--mobile-collapsed.collapsible-block--alt & {
    @include breakpoint($medium-down) {
      @include icon('plus', after, $mq: true);
    }
  }
}

.collapsible-block__content {
  @include single-transition(
    $default-transition-property,
    $default-transition-duration,
    $default-transition-function
  );
  height: 0;
  overflow: hidden;
  padding-top: 1em;
  .collapsible-block--alt & {
    height: auto;
  }
  .collapsible-block--mobile-collapsed & {
    @include breakpoint($medium-down) {
      height: auto;
    }
  }
  .collapsible-block--mobile-collapsed.collapsible-block--alt & {
    @include breakpoint($medium-down) {
      height: 0;
      overflow: hidden;
    }
  }
}
