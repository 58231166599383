.checkout {
  .checkout-panel {
    &--viewcart {
      .checkout-buttons-content {
        text-align: center;
      }
    }
    &--recommended-products {
      .recommended-item {
        float: left;
        width: 32%;
        margin: 0 0 0 10px;
        &__button {
          padding: 10px;
        }
      }
    }
    &--payment {
      .cvv {
        input {
          width: 100%;
        }
      }
    }
    .registration-content {
      .checkout {
        &__privacy-policy-wrap {
          .accepted-privacy-policy {
            display: block;
          }
        }
      }
    }
  }
  &__sidebar {
    .checkout-panel--offer-code {
      ul {
        &.error_messages {
          li {
            color: $color-red;
            padding-bottom: 15px;
          }
        }
      }
    }
    .checkout-panel--order-summary {
      .label {
        padding-right: 5%;
      }
    }
  }
  .payment-form--card {
    &-type {
      label {
        font-weight: normal;
      }
    }
  }
}
