.slick {
  &-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
  }
  &-list {
    position: relative;
    overflow: hidden;
    display: block;
    margin: 0;
    padding: 0;
    &:focus {
      outline: none;
    }
    &.dragging {
      cursor: pointer;
      cursor: hand;
    }
  }
  &-track,
  &-list {
    @include transform(translate3d(0, 0, 0));
  }
  &-track {
    position: relative;
    #{$ldirection}: 0;
    top: 0;
    display: block;
    &:before,
    &:after {
      content: '';
      display: table;
    }
    &:after {
      clear: both;
    }
    .slick-loading & {
      visibility: hidden;
    }
  }
  &-slide {
    float: #{$ldirection};
    height: 100%;
    min-height: 1px;
    display: none;
    outline: none;
    [dir='rtl'] & {
      float: #{$rdirection};
    }
    &.slick-loading img {
      display: none;
    }
    &.dragging img {
      pointer-events: none;
    }
    .slick-initialized & {
      display: block;
    }
    .slick-loading & {
      visibility: hidden;
    }
    .slick-vertical & {
      display: block;
      height: auto;
      border: 1px solid transparent;
    }
  }
  &-prev,
  &-next {
    @include font-smoothing;
    @include appearance(none);
    //@include transition(opacity 300ms ease);
    position: absolute;
    z-index: 2;
    line-height: 0;
    font-size: 0;
    cursor: pointer;
    background: transparent;
    //color: transparent;
    top: 50%;
    margin-top: -18px;
    padding: 0;
    border: none;
    outline: none;
    opacity: 0.8;
    color: $color-black;
    letter-spacing: 0;
    font-weight: normal;
    &:hover {
      opacity: 1;
      background: transparent;
    }
    &:focus {
      outline: none;
    }
    &:before {
      font-size: 50px;
      line-height: 1;
    }
    &.slick-disabled {
      cursor: default;
      opacity: 0.4;
      &:hover {
        opacity: 0.4;
        background: transparent;
      }
    }
  }
  &-prev {
    @include icon('caret--left', $text-replace: true);
    #{$ldirection}: 0;
    position: absolute;
    z-index: 2;
    &:before {
      #{$rdirection}: auto;
    }
  }
  &-next {
    @include icon('caret--right', $text-replace: true);
    position: absolute;
    z-index: 2;
  }
}

// testing container specificity
.carousel-controls {
  position: absolute;
  height: 0;
  line-height: 0;
  width: 100%;
  display: block;
  top: 50%;
  #{$ldirection}: 0;
  text-align: #{$ldirection};
  .slick-prev,
  .slick-next {
    position: absolute;
    z-index: 1;
    cursor: pointer;
    top: 50%;
    padding: 0;
    border: none;
    outline: none;
    opacity: 1;
    color: $color-dark-gray;
    width: 25px;
    height: 25px;
    @include breakpoint($small-up) {
      width: 50px;
      height: 50px;
    }
    &:hover {
      color: $color-gray;
      background: transparent;
    }
    &:focus {
      outline: none;
    }
    &:before {
      width: 50px;
      height: 50px;
      line-height: 50px;
      font-size: 25px;
      @include breakpoint($medium-up) {
        font-size: 30px;
      }
    }
    &.slick-disabled {
      cursor: default;
      opacity: 0.4;
      &:hover {
        opacity: 0.4;
      }
    }
  }
  .slick-prev {
    @include icon('caret--left', $text-replace: true);
    #{$ldirection}: -10px;
    @include breakpoint($medium-up) {
      #{$ldirection}: 5px;
    }
    &:before {
      #{$rdirection}: auto;
      #{$ldirection}: 5px;
    }
  }
  .slick-next {
    @include icon('caret--right', $text-replace: true);
    #{$rdirection}: -10px;
    position: absolute;
    @include breakpoint($medium-up) {
      #{$rdirection}: 5px;
    }
    &:before {
      position: absolute;
      #{$rdirection}: 5px;
    }
  }
}

/// DOTS for mobile carousel

.carousel-dots {
  width: 100%;
  height: 30px;
  line-height: 30px;
  position: relative;
  &--basic {
    @include breakpoint($landscape-up) {
      display: none;
    }
  }
  .product-grid--carousel & {
    margin-top: -15px;
  }
}

// Default Variables

$slick-dot-color: $color-medium-stone-gray;
$slick-dot-color-active: $color-black;
$slick-dot-size: 8px;
$slick-opacity-default: 1;
$slick-opacity-on-hover: 0.75;
$slick-opacity-not-active: 0.25;

// Dots

.slick-dots {
  text-align: center;
  width: 100%;
  li {
    position: relative;
    display: inline-block;
    height: $slick-dot-size;
    width: $slick-dot-size;
    margin: 0 5px;
    padding: 0;
    cursor: pointer;
    button {
      border: 0;
      background: transparent;
      display: block;
      height: $slick-dot-size;
      width: $slick-dot-size;
      outline: none;
      line-height: 0px;
      font-size: 0px;
      color: transparent;
      padding: 5px;
      cursor: pointer;
      &:hover,
      &:focus {
        outline: none;
        &:before {
          opacity: $slick-opacity-on-hover;
        }
      }
      &:before {
        @include circle(9px, $color-gray);
        position: absolute;
        top: 0;
        #{$ldirection}: 0;
        content: '';
        width: $slick-dot-size;
        height: $slick-dot-size;
        //font-family: $slick-font-family;
        font-size: $slick-dot-size;
        line-height: $slick-dot-size;
        text-align: center;
        color: $slick-dot-color;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }
    }
    &.slick-active button:before {
      background: $color-black;
      color: $slick-dot-color-active;
      opacity: $slick-opacity-default;
    }
  }
}
