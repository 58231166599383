.sign-in {
  @include breakpoint($medium-up) {
    padding-bottom: 82px;
    background: $color-charcoal;
  }
  text-align: center;
}

.sign-in-page {
  background: $color-white;
  margin: 0 auto;
  @include breakpoint($medium-up) {
    margin-top: 82px;
    display: inline-block;
  }
  position: relative;
  text-align: #{$ldirection};
  overflow: auto;
  &__tab-nav {
    max-width: inherit;
    clear: both;
    overflow: auto;
  }
  .new-account {
    display: none;
  }
  &__container {
    float: #{$ldirection};
    max-width: 100%;
    width: 100%;
    @include breakpoint($medium-up) {
      max-width: 580px;
    }
    float: #{$ldirection};
  }
  &__link {
    float: #{$ldirection};
    width: 50%;
    text-align: center;
    cursor: pointer;
    text-transform: uppercase;
    font-weight: bold;
    padding: 18px;
    line-height: 2;
    @include breakpoint($medium-up) {
      padding: 28px 35px;
    }
    margin: 0;
    background-color: $color-light-silver;
    &.active {
      background-color: $color-white;
    }
  }
  .return-user,
  .new-account {
    padding-top: 43px;
    padding-bottom: 43px;
    clear: both;
    max-width: inherit;
    @include breakpoint($medium-up) {
      padding: 63px 78px 63px;
    }
    &__fieldset {
      .form-item {
        width: 100%;
      }
    }
    &__bg-container {
      width: 100%;
      max-width: 100%;
      position: relative;
      @include breakpoint($medium-up) {
        max-width: 610px;
        position: static;
        margin-top: 0;
      }
      display: block;
      float: #{$ldirection};
      &.inactive {
        position: absolute;
        opacity: 0;
        z-index: -1;
        #{$ldirection}: -100%;
      }
      video {
        opacity: 0;
        width: 100%;
        min-height: 420px;
      }
    }
    &__bg-image {
      position: absolute;
      top: 0;
      bottom: 0;
      #{$ldirection}: 0;
      @include breakpoint($medium-up) {
        #{$ldirection}: 580px;
      }
      #{$rdirection}: 0;
      margin: auto;
      background-position: 50% 50%;
      background-repeat: no-repeat;
      background-size: cover;
    }
    ul.error_messages {
      li {
        color: $color-red;
      }
    }
    form {
      padding: 0 1.5em;
    }
    .form-item {
      padding-#{$rdirection}: 0;
      .invalid_marker,
      .valid_marker {
        display: none;
      }
      input[type='checkbox']:checked {
        ~ label {
          color: $color-black;
        }
      }
      &.forgot-password {
        margin-top: 18px;
        margin-bottom: 2px;
        display: inline-block;
        line-height: 1;
        text-align: center;
        padding: 0;
      }
      input[type='email'],
      input[type='password'] {
        &:valid {
          border-color: $black;
          color: $black;
        }
      }
      &.email-promotions {
        margin-top: 26px;
      }
      &.privacy-policy {
        margin-top: 12px;
        input[type='checkbox'] {
          @include input-styled-check('checkbox');
        }
      }
      &.email-promotions,
      &.privacy-policy {
        label {
          color: $black;
          line-height: 17px;
          &::before {
            margin-top: 3px;
          }
        }
        .label-content {
          padding-#{$ldirection}: 8px;
          letter-spacing: 0.02em;
          a {
            display: inline;
            @include breakpoint($medium-up) {
              font-weight: bold;
            }
          }
        }
        input[type='checkbox'] {
          ~ label:before {
            color: $color-light-stone-gray;
          }
        }
        input[type='checkbox']:checked {
          ~ label:before {
            color: $color-stone-gray;
          }
        }
      }
      &.email-address {
        margin-top: 48px;
      }
      &.password {
        margin-top: 10px;
      }
      &.submit {
        input {
          @include swap_direction(padding, 18px 11px 16px 21px);
          letter-spacing: 0.3em;
        }
        margin-top: 23px;
      }
      input {
        border-radius: 0;
        &.checkedEmpty,
        &.error {
          color: $color-red;
          & ~ label {
            color: $color-red;
          }
        }
      }
    }
  }
  .return-user {
    padding-top: 20px;
    .re-register {
      margin: 0;
      padding: 0 1.5em 1.5em;
      @include breakpoint($medium-up) {
        padding-#{$ldirection}: 0;
        padding-#{$rdirection}: 0;
        padding-top: 0;
      }
    }
  }
}

//Password Request Page
.password-request-page {
  padding: 50px 25px 50px;
  @include breakpoint($landscape-up) {
    padding: 70px 25px 150px;
    margin: 0 auto;
    max-width: $max-width;
  }
  .password-request {
    .request-form {
      &__item {
        margin: 20px 0 0;
        span {
          font-weight: bold;
        }
      }
    }
  }
  &__header {
    @include account_page_sub_header();
  }
}

//Password Sent Page
.password-sent-page {
  padding: 50px 25px 50px;
  @include breakpoint($landscape-up) {
    padding: 70px 25px 150px;
    margin: 0 auto;
    max-width: $max-width;
  }
  .sent-info {
    &__text {
      &.email-info {
        margin-top: 10px;
      }
      a {
        margin: 10px 0;
        display: inline-block;
        text-decoration: underline;
        border: 0;
      }
    }
  }
  &__header {
    @include account_page_sub_header();
  }
}

//Password Reset Page
.password-reset-page {
  padding: 50px 25px 50px;
  @include breakpoint($landscape-up) {
    padding: 70px 25px 150px;
    margin: 0 auto;
    max-width: $max-width;
  }
  &__header {
    @include account_page_sub_header();
  }
  .password-reset {
    &__item {
      margin-bottom: 25px;
    }
    &__text {
      margin-bottom: 25px;
    }
    &__fieldset {
      margin-bottom: 25px;
    }
  }
}
