.address-book-page {
  .address-book-page__content {
    padding: 20px;
  }
  @include breakpoint($landscape-up) {
    @include swap_direction(padding, 50px 0 20px 0);
  }
  a.back-link {
    top: 2px;
  }
  &__content {
    .address-book,
    .payment-info {
      padding-bottom: 35px;
      margin-bottom: 23px;
      &__header {
        @include account_page_sub_header;
      }
      .address-item,
      .payment-item {
        padding: 16px 23px;
        margin-bottom: 20px;
        background: $color-gray;
        h2 {
          font-size: 13px;
          margin-bottom: 5px;
          font-weight: bold;
        }
        &__controls {
          margin: 10px 0;
          a {
            margin-#{$rdirection}: 10px;
            text-decoration: underline;
          }
        }
        &__default-address {
          display: block;
          margin: 10px 0 0;
          line-height: 18px;
        }
        .payment-item {
          padding: 0;
          width: auto;
          &__details {
            margin-bottom: 10px;
          }
        }
        /*        @include breakpoint($landscape-up) {
          float: left;
          margin-right: 2.5%;
          width: 39%;
        } */
      }
    }
  }
  &__overlay {
    padding: 20px;
    h2 {
      @include account_page_header;
    }
    .payment-delete,
    .address-delete {
      padding: 20px;
      h3 {
        @include account_page_header;
      }
      &__link {
        @include swap_direction(margin, 10px 10px 0 0);
        text-decoration: underline;
      }
    }
    .address-form {
      .form-item {
        width: 100%;
        @include breakpoint($landscape-up) {
          width: 50%;
          &.postal-code,
          &.state,
          &.city {
            width: 33%;
            float: #{$ldirection};
          }
          &.state {
            label {
              display: block;
            }
          }
        }
      }
    }
  }
}
/* Address Book - Account Payment */
.add-payment-page {
  padding: 20px;
  a.back-link {
    top: 2px;
  }
  &__header {
    @include account_page_sub_header;
    margin-bottom: 10px;
  }
  .address-item {
    &__controls {
      display: none;
    }
  }
  .payment-form,
  .payment-address {
    padding-bottom: 1em;
    margin-bottom: 1em;
    &__link {
      text-decoration: underline;
      cursor: pointer;
      display: inline-block;
      margin-bottom: 10px;
    }
    &__choose-address {
      select {
        margin: 10px 0;
        width: 100%;
        padding-#{$rdirection}: 1.3em;
        height: 35px;
        line-height: 35px;
        @include breakpoint($landscape-up) {
          width: 92%;
        }
      }
    }
    &__item {
      padding-bottom: 1.5em;
      @include breakpoint($landscape-up) {
        width: 50%;
        float: #{$ldirection};
        padding-#{$rdirection}: 1.33em;
      }
      &--full {
        width: 100%;
        padding-bottom: 10px;
      }
      select,
      input {
        width: 100%;
      }
      select {
        height: 35px;
        line-height: 35px;
      }
    }
    &__payment-default {
      label {
        line-height: 14px;
        text-transform: uppercase;
      }
    }
  }
}
