.change-password {
  .profile__change-password-overlay {
    .profile__reset-password {
      .profile-info {
        &__header {
          text-align: center;
          font-size: 20px;
        }
        &__item {
          margin: 15px 0;
          input {
            width: 100%;
          }
          .button {
            width: 48%;
          }
          .form-cancel {
            float: #{$ldirection};
            margin-#{$rdirection}: 4%;
            padding: 14px 0;
            border-radius: 0;
          }
          .form-submit {
            padding: 14px 0;
          }
        }
        &__rules {
          margin: 10px 0;
          .password-strength {
            display: inline-block;
            &:before {
              margin-#{$rdirection}: 6px;
              content: $cross;
              display: inline-block;
              color: $color-red;
              font-size: 16px;
              line-height: 0;
            }
            &:after {
              content: ' ';
            }
            &.pass {
              color: $color-green;
              &:before {
                content: $tick;
                color: $color-green;
              }
            }
            &:last-child {
              &:after {
                content: ' ';
              }
            }
          }
        }
        &__meter-status {
          font-weight: bold;
        }
        &__meter {
          height: 8px;
          border-radius: 4px;
          &--container {
            margin-top: 6px;
            background-color: $color-gray;
          }
          &--progress {
            background-color: $color-gray;
            transition: all 0.3s linear;
          }
        }
        &__meter-wrapper {
          .profile-info {
            &__strength-list {
              display: inline-block;
              li {
                display: none;
                font-style: italic;
              }
            }
          }
          &.weak,
          &.no-score {
            .profile-info {
              &__strength-list {
                .no-score {
                  display: inline-block;
                }
              }
            }
          }
          &.bad {
            .profile-info {
              &__meter {
                &--progress {
                  width: 25%;
                  background-color: $color-red;
                }
              }
              &__strength-list {
                .bad {
                  display: inline-block;
                }
              }
            }
          }
          &.average {
            .profile-info {
              &__meter {
                &--progress {
                  width: 50%;
                  background-color: $color-average;
                }
              }
              &__strength-list {
                .average {
                  display: inline-block;
                }
              }
            }
          }
          &.good {
            .profile-info {
              &__meter {
                &--progress {
                  width: 75%;
                  background-color: $color-good;
                }
              }
              &__strength-list {
                .good {
                  display: inline-block;
                }
              }
            }
          }
          &.strong {
            .profile-info {
              &__meter {
                &--progress {
                  width: 100%;
                  background-color: $color-green;
                }
              }
              &__strength-list {
                .strong {
                  display: inline-block;
                }
              }
            }
          }
        }
      }
    }
  }
  fieldset {
    overflow: visible;
  }
  .password-reset-page {
    .password-reset {
      &__fieldset {
        .form-item {
          float: none;
          width: auto;
          padding-#{$rdirection}: 0;
          padding-bottom: 0;
        }
      }
      .password-field {
        &__info {
          @include breakpoint($landscape-up) {
            width: 35%;
          }
        }
        &__rules {
          @include breakpoint($landscape-up) {
            padding: 10px;
          }
        }
      }
    }
  }
  .password-field {
    position: relative;
    &__info {
      &-reset {
        @include breakpoint($landscape-up) {
          position: absolute;
          #{$ldirection}: 103%;
          top: 50%;
          transform: translateY(-50%);
          width: 45%;
          border: 1px solid $color-gray;
        }
        @include breakpoint($medium-portrait-only) {
          #{$rdirection}: 102%;
          width: 50%;
        }
        @include breakpoint($ipad-pro-portrait-only) {
          position: absolute;
          #{$rdirection}: 103%;
          top: 50%;
          transform: translateY(-50%);
          width: 37%;
          border: 1px solid $color-gray;
        }
      }
      width: 100%;
      background-color: $color-white;
      z-index: 99;
      margin-top: 5px;
      &-reset:before {
        @include breakpoint($landscape-up) {
          content: '';
          position: absolute;
          top: 43%;
          #{$rdirection}: 100%;
          border: 5px solid transparent;
          border-#{$rdirection}-color: $color-gray;
          -ms-transform: rotate(180deg);
          -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
        }
      }
    }
    &__rules {
      column-count: 2;
      &-reset {
        column-count: 2;
        @include breakpoint($landscape-up) {
          column-count: 1;
        }
      }
      padding: 10px 0;
      list-style: none;
      .password-strength {
        display: flex;
        align-items: center;
        padding: 3px 0;
        color: $color-gray;
        transition: 0.2s;
        white-space: nowrap;
        &:before {
          content: $cross;
          display: inline-block;
          color: $color-red;
          font-size: 16px;
          line-height: 0;
          margin-#{$rdirection}: 6px;
          transition: 0.2s;
        }
        &.pass {
          color: $color-success;
          &:before {
            color: $color-success;
            content: $tick;
            text-shadow: 0 0 8px ($color-black, 0.5);
          }
        }
      }
    }
  }
  ul.error_messages {
    li {
      color: $color-red;
    }
  }
}
