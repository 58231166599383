.account-page {
  .account-section__nav {
    display: block;
  }
  &__section {
    border-bottom: solid 1px $color-light-stone-gray;
    margin-bottom: 20px;
    padding-bottom: 15px;
    .section {
      &-header {
        overflow: auto;
        h3 {
          float: #{$ldirection};
          @include account_page_sub_header();
        }
        .link {
          float: #{$rdirection};
          line-height: normal;
        }
      }
      &-content {
        > div {
          overflow: auto;
        }
        span,
        p {
          margin-bottom: 0;
          float: #{$ldirection};
        }
      }
    }
    @include breakpoint($medium-up) {
      float: #{$rdirection};
    }
    @include breakpoint($landscape-up) {
      border-bottom: 0;
      padding: 2%;
      width: 50%;
      float: #{$ldirection};
      &:nth-child(2n + 2) {
        border-#{$ldirection}: solid 1px $color-light-stone-gray;
        margin-#{$ldirection}: -1px;
        padding-#{$rdirection}: 0;
      }
      &:nth-child(2n + 1) {
        margin-#{$ldirection}: -1px;
        clear: both;
        border-#{$rdirection}: solid 1px $color-light-stone-gray;
        padding-#{$ldirection}: 0;
      }
      .section {
        &-content {
          h4 {
            margin-bottom: 10px;
          }
        }
      }
    }
  }
  .error_messages {
    display: none;
  }
}
