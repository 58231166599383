.store-locator {
  position: relative;

  // Genie
  .hidden,
  .inactive,
  .hide {
    display: none;
  }
  &__header {
    @include swap_direction(padding, 30px 15px 35px 15px);
    background: $color-light-silver;
    text-align: center;
    @include breakpoint($landscape-up) {
      padding-top: 50px;
      padding-bottom: 60px;
    }
  }
  &__header-container {
    @include swap_direction(margin, 0 0 10px 0);
    @include breakpoint($landscape-up) {
      margin-bottom: 25px;
    }
  }
  &__title {
    @include h1;
  }
  &__description {
    text-transform: uppercase;
    letter-spacing: 0.13em;
    font-size: 11px;
    width: 90%;
    margin: 0 auto;
    @include breakpoint($landscape-up) {
      font-size: 12px;
    }
  }
  &__messages {
    color: $color-error;
  }
  &__form {
    @include clearfix;
    @include swap_direction(padding, 15px 0 0 0);
    max-width: 100%;
    select,
    input,
    a.selectBox {
      width: 100%;
      background-color: $color-light-silver;
      @include breakpoint($landscape-up) {
        height: 50px;
        line-height: 50px;
      }
    }
    button {
      width: 100%;
      @include breakpoint($landscape-up) {
        height: 50px;
        line-height: 50px;
        padding: 0 40px;
      }
    }
    .country {
      a.selectBox {
        @include breakpoint($medium-up) {
          min-width: 250px;
        }
      }
    }
  }
  &__form-column {
    @include swap_direction(margin, 0 0 15px 0);
    @include breakpoint($landscape-up) {
      display: inline-block;
      @include swap_direction(margin, 0 0 0 10px);
      &:first-child {
        margin-#{$ldirection}: 0;
      }
    }
    &.neither {
      display: none;
    }
    &--search {
      @include breakpoint($landscape-up) {
        min-width: 320px;
      }
    }
    &--submit {
      @include swap_direction(margin, 22px 0 0 0);
      @include breakpoint($landscape-up) {
        @include swap_direction(margin, 0 0 0 10px);
      }
    }
  }
  &__dutyfree {
    @include swap_direction(padding, 20px 0 0 0);
  }
  &__landing-content {
    position: relative;
  }
  &__results-container,
  &__map-canvas-container {
    position: absolute;
    #{$ldirection}: -100%;
    height: 100%;
    width: 100%;
    min-height: 150px;
    @include breakpoint($landscape-up) {
      position: static;
    }
    &.active {
      position: static;
    }
  }
  &__results-container {
    @include breakpoint($medium-down) {
      padding: 0 10px;
    }
    @include breakpoint($landscape-up) {
      clear: #{$ldirection};
      float: #{$ldirection};
      width: 27%;
      margin-#{$rdirection}: 4%;
    }
    .directions-form {
      &__errors {
        padding: 5px 0px;
      }
      &__submit {
        @include breakpoint($medium-down) {
          @include swap_direction(margin, 0 0 5px 0);
          line-height: 17px;
        }
      }
    }
    .directions-form__results {
      .adp-directions {
        td.adp-substep {
          word-break: break-all;
          &:nth-child(1) {
            width: 7%;
          }
          &:nth-child(2) {
            width: 10%;
          }
          &:nth-child(3) {
            width: 70%;
          }
          &:nth-child(4) {
            width: 12%;
          }
        }
      }
      .adp-placemark {
        width: 100%;
      }
    }
  }
  &__map-canvas-container {
    @include breakpoint($landscape-up) {
      float: #{$rdirection};
      width: 69%;
      background: $color-light-silver;
      padding: 40px;
    }
  }
  &__map-canvas-holder,
  &__directions-map-holder {
    height: 285px;
    @include breakpoint($landscape-up) {
      height: 545px;
    }
  }
  &__map-canvas,
  &__directions-map {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    position: relative;
    // fix for a squashed image issue, with the map controls
    // (see: http://stackoverflow.com/questions/9904379/google-map-zoom-controls-not-displaying-correctly)
    .gmnoprint img {
      max-width: none;
    }
  }
  &__touts {
    position: relative;
    .store-locator--results & {
      display: none;
    }
    &--results {
      @include swap_direction(padding, 20px 0 0 0);
      display: none;
      clear: #{$rdirection};
      @include breakpoint($landscape-up) {
        padding-top: 40px;
      }
      .store-locator--results & {
        display: block;
      }
    }
  }
  &__body {
    display: none;
    max-width: $max-width;
    margin: 0 auto;
    padding: 30px 15px;
    @include breakpoint($landscape-up) {
      @include swap_direction(padding, 40px 40px 60px 40px);
    }
    .store-locator--results & {
      display: block;
    }
  }
  &__info {
    @include swap_direction(margin, 0 0 10px 0);
    text-align: center;
    text-transform: uppercase;
    font-size: 12px;
  }
  &__sections {
    @include swap_direction(margin, 0 0 30px 0);
    border: 1px solid $color-silver;
    background: $color-silver;
    @include breakpoint($landscape-up) {
      display: none;
    }
  }
  &__button {
    text-transform: uppercase;
    letter-spacing: 0.13em;
    text-align: center;
    height: 45px;
    line-height: 45px;
    float: #{$ldirection};
    width: 50%;
    border: 0;
    font-weight: 500;
    &.active {
      background: $color-white;
    }
  }
  &__results-header {
    // @extend h3;
    // @include line-behind-text();
    // text-transform: uppercase;
    @include swap_direction(margin, 0 0 20px 0);
    @include breakpoint($landscape-up) {
      margin-bottom: 35px;
    }
  }
  &__stores {
    @include breakpoint($landscape-up) {
      height: 663px;
      overflow: auto;
      -webkit-overflow-scrolling: touch;
      background: $color-light-silver;
      padding: 10px;
    }
  }
  &-section--list_item {
    border-top: 1px solid $color-gray;
    &:first-child {
      border-top: 0;
    }
  }
  &-section--list_item-location {
    display: none;
  }
  &__tooltip {
    @include transition(background-color 0.1s ease-in-out);
    font-size: 12px;
    padding: 25px 20px;
    position: relative;
    cursor: pointer;
    &:hover,
    &.selected {
      background-color: $color-white;
    }
    @include breakpoint($landscape-up) {
      // padding: 25px 0;
      font-size: 13px;
      padding-#{$rdirection}: 5%;
    }
  }
  &__tooltip-left-col {
    float: #{$ldirection};
    width: 45%;
    margin-#{$rdirection}: 7%;
    position: relative;
    padding-#{$ldirection}: 26px;
    @include breakpoint($landscape-up) {
      float: none;
      width: 100%;
      margin: 0;
      padding-#{$ldirection}: 42px;
    }
  }
  &__tooltip-right-col {
    float: #{$rdirection};
    width: 48%;
    line-height: 15px;
    @include breakpoint($landscape-up) {
      float: none;
      width: 100%;
      line-height: 17px;
      padding-#{$ldirection}: 42px;
    }
  }
  &__tooltip-name {
    @include swap_direction(margin, 0 0 7px 0);
    line-height: 17px;
    text-transform: uppercase;
    a {
      text-decoration: none;
      border-bottom: 0;
    }
    @include breakpoint($landscape-up) {
      font-size: 14px;
      line-height: 18px;
      // width: 70%;
      margin-bottom: 20px;
    }
    .icon {
      font-size: 24px;
      position: absolute;
      top: 1px;
      #{$ldirection}: 0;
      @include breakpoint($landscape-up) {
        font-size: 27px;
        #{$ldirection}: 8px;
      }
    }
    .result_number {
      display: none;
    }
  }
  &__result-pin {
    cursor: pointer;
    &,
    &-image {
      height: 30px;
      width: 20.4px;
    }
  }
  &__tooltip.info_window {
    line-height: 15px;
    padding: 5px 0;
    &:hover {
      background: none;
    }
    .store-locator__tooltip-name {
      @include swap_direction(margin, 0 0 5px 0);
      @include breakpoint($landscape-up) {
        margin-bottom: 7px;
      }
    }
    .store-locator__tooltip-address {
      @include swap_direction(margin, 0 0 2px 0);
    }
    .store-locator__tooltip-directions {
      margin-top: 8px;
    }
  }
  &__tooltip-distance {
    font-size: 11px;
    margin-top: 5px;
    @include breakpoint($landscape-up) {
      margin-top: 0;
      position: absolute;
      top: 1px;
      #{$rdirection}: 0;
    }
  }
  &__tooltip-address,
  &__tooltip-phone {
    text-decoration: none;
    &:hover {
      text-decoration: none;
    }
  }
  &__tooltip-address {
    @include swap_direction(margin, 0 0 2px 0);
    @include breakpoint($landscape-up) {
      margin-bottom: 3px;
    }
  }
  &__tooltip-phone {
    display: inline-block;
    margin: 10px 0;
    clear: both;
    @include breakpoint($landscape-up) {
      margin-bottom: 20px;
    }
  }
  &__tooltip-hours {
    @include swap_direction(margin, 12px 0 0 0);
    div {
      margin-bottom: 2px;
      @include breakpoint($landscape-up) {
        margin-bottom: 3px;
      }
    }
  }
  &__tooltip-links {
    @include swap_direction(margin, 12px 0 0 0);
  }
  &__tooltip-events,
  &__tooltip-directions {
    text-transform: uppercase;
    @include breakpoint($landscape-up) {
      font-size: 12px;
      text-decoration: underline;
      a:hover {
        text-decoration: none;
      }
    }
  }
  &__tooltip-events {
    @include breakpoint($landscape-up) {
      display: none;
    }
  }
  &__tooltip-directions {
    margin-top: 2px;
    @include breakpoint($landscape-up) {
      margin-top: 3px;
    }
  }
  .doors_results {
    width: 100%;
  }
  &__map-recentering-search {
    @include breakpoint($landscape-up) {
      @include swap_direction(margin, 10px 0 0 0);
      float: #{$rdirection};
    }
  }
  &__map-recentering-search-prompt-button {
    position: absolute;
    bottom: 1px;
    #{$ldirection}: 1px;
    @include breakpoint($landscape-up) {
      position: static;
    }
  }
  &__loading-image {
    @include swap_direction(margin, 32px auto 0 auto);
    display: block;
    .store-locator__map-canvas & {
      @include vertical-horizontal-center;
      position: absolute;
      margin: 0 auto;
    }
  }
  &__directions-results {
    .adp-text {
      width: 93%;
      padding-#{$ldirection}: 15px;
      vertical-align: middle;
    }
  }
  &__directions-destination {
    float: #{$ldirection};
    margin-#{$rdirection}: 10px;
  }
  &__directions-location {
    .directions-form__transportation-modes {
      padding: 10px;
      .directions-form__transportation-mode-link.active,
      .directions-form__transportation-mode-link:hover {
        color: $color-red;
      }
    }
  }
}

// This handles adding a marker icon to the left sidebar results
#store_results {
  .store-locator {
    &__tooltip {
      @include breakpoint($landscape-up) {
        background-image: url(/media/images/locator/result-pin.png);
        background-repeat: no-repeat;
        background-size: 20px 29px;
        background-position: 16px 25px;
        .column {
          padding-#{$ldirection}: 35px;
        }
      }
    }
  }
}

// override selectbox
.distance-selectBox-dropdown-menu,
.country-selectBox-dropdown-menu {
  //background-color: $color-light-silver;
}

#search-feature {
  &-content {
    #store-locator {
      &-form {
        &-div {
          .label-field {
            width: 120px;
            padding-top: 8px;
          }
          .text-field {
            padding-top: 8px;
          }
          .field-width-168 {
            width: 168px;
          }
          .field-width-160 {
            width: 160px;
          }
          .or-separator {
            padding-top: 8px;
            padding-#{$rdirection}: 8px;
            text-transform: uppercase;
          }
          #find_stores_intl {
            &.header {
              padding-top: 20px;
            }
          }
          #PERIMITER {
            width: 80px;
            display: inline;
          }
          .align-rgt {
            text-align: #{$rdirection};
          }
          #search-locator-button {
            cursor: pointer;
            padding-top: 15px;
          }
          #driving-button {
            cursor: pointer;
          }
          #sign-up-button {
            cursor: pointer;
            padding-top: 5px;
            padding-#{$ldirection}: 2px;
          }
        }
      }
    }
    #show_map {
      width: 450px;
      height: 380px;
      #map {
        width: 450px;
        height: 340px;
      }
    }
    #show_driving_map {
      width: 450px;
      height: 420px;
      #driving_map {
        width: 450px;
        height: 340px;
      }
    }
    #special-events-detail {
      position: relative;
      height: 185px;
      padding: 0 0 50px;
    }
    #route {
      padding: 5px;
    }
  }
}
