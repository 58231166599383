// Mixin function for review star black background image
@mixin pr-black-background-image {
  $pr-count: (0, 25, 50, 75, 100);
  $pr-height-value: 15px;
  $pr-width-value: 15px;
  @each $pr-value in $pr-count {
    .pr-star-v4-#{$pr-value}-filled {
      background-image: url('/media/images/global/rating/rating-black-large-#{$pr-value}.png');
      height: $pr-height-value;
      width: $pr-width-value;
      background-size: 100%;
    }
  }
}

// Mixin function for review star white background image
@mixin pr-white-background-image {
  $pr-count: (0, 25, 50, 75, 100);
  $pr-height-value: 25px;
  $pr-width-value: 26px;
  @each $pr-value in $pr-count {
    .pr-star-v4-#{$pr-value}-filled {
      background-image: url('/media/images/global/rating/rating-white-large-#{$pr-value}.png');
      height: $pr-height-value;
      width: $pr-width-value;
    }
  }
}

// Mixin function for button
@mixin pr-sub-btn {
  font-size: 12px;
  letter-spacing: 0.16em;
  color: $color-white;
  font-family: $font--text;
  background: $color-black;
  border: 1px solid $color-black;
  font-weight: 500;
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
  padding: 13px 15px;
  border-radius: 0;
}

// Mixin function for form field placeholder
@mixin pr-form-field-placeholder {
  font-family: $font--text;
  font-size: 12px;
  color: $color-stone-gray;
}

// Common Section render for MPP, SPP, Review, Endeca search and Display Page
#pr-write,
#pr-reviewdisplay,
.product-grid__content,
.product-full__details,
.product-full__review-snippet,
.review-snippet,
.search-wrapper__results {
  .p-w-r {
    a,
    button {
      color: $color-black;
      font-family: $font--text;
      font-size: 14px;
      font-weight: normal;
      letter-spacing: 0.02em;
      &:focus {
        outline: none;
        box-shadow: none;
      }
    }
    span,
    p,
    strong {
      font-family: $font--text;
      font-size: 14px;
      font-weight: normal;
      line-height: 1.66667;
      letter-spacing: 0.02em;
      color: $color-black;
    }
    .pr {
      &-textinput,
      &-textarea {
        font-size: 14px;
        font-family: $font--text;
        color: $color-black;
        &::placeholder {
          font-size: 12px;
          font-family: $font--text;
        }
      }
      &-alert-container,
      &-header-product-img,
      &-logo-container,
      &-header-product-name,
      &-review-snapshot-header-intro,
      &-snippet-rating-decimal,
      &-snippet-reco-to-friend,
      &-verified_reviewer {
        display: none;
      }
      &-review-display {
        dt,
        dd,
        dl {
          color: $color-black;
          font-family: $font--text;
          font-size: 14px;
          background: transparent;
        }
      }
    }
  }
}

// MPP page
.product-grid__item {
  .review-snippet {
    .p-w-r {
      @include pr-black-background-image();
      .pr-snippet-rating-decimal,
      .pr-category-snippet__total {
        display: none;
      }
    }
    .pr-no-reviews {
      .pr-snippet {
        display: none;
      }
    }
  }
  .p-w-r {
    .pr-snippet {
      .pr-snippet-stars-png {
        @include pr-black-background-image();
      }
    }
  }
}

// SPP page
.review-snippet {
  .p-w-r {
    @include pr-white-background-image();
    margin: 0;
    .pr-snippet {
      .pr-rating-stars {
        .pr-star-v4 {
          height: 15px;
          width: 15px;
        }
      }
    }
    .pr-snippet-stars-reco-inline {
      margin-bottom: 15px;
      .pr-snippet-stars-container {
        .pr-snippet-stars {
          background: transparent;
        }
      }
      .pr-snippet-read-and-write {
        .pr-snippet-review-count {
          text-transform: uppercase;
          text-decoration: none;
          border-bottom: 1px solid $color-white;
          color: $color-white;
          font-size: 11px !important;
          padding: 0;
          padding-bottom: 2px;
          margin-#{$ldirection}: 15px;
        }
      }
      &.pr-snippet-minimal {
        .pr-snippet-stars-container {
          display: inline-block;
          @include breakpoint($medium-up) {
            margin-#{$rdirection}: 15px;
          }
          @include breakpoint($large-up) {
            margin-#{$rdirection}: 0;
          }
        }
      }
      &.pr-snippet-compact {
        .pr-snippet-read-and-write {
          margin-top: 2px;
        }
      }
      .pr-snippet-write-review-link {
        display: none;
      }
    }
    .pr-no-reviews {
      .pr-snippet-stars-reco-inline {
        .pr-snippet-stars-container {
          .pr-snippet-stars {
            background: transparent;
          }
          .pr-snippet-rating-decimal {
            display: none;
          }
        }
        .pr-snippet-read-and-write {
          .pr-snippet-review-count {
            display: none;
          }
        }
        .pr-snippet-write-review-link {
          @include swap_direction(margin, 0 0 0 15px);
          @include swap_direction(padding, 0 0 2px 0);
          color: $color-white;
          border-bottom: 1px solid $color-white;
          display: inline;
          text-transform: uppercase;
          @include breakpoint($medium-up) {
            margin-#{$ldirection}: 0;
          }
          @include breakpoint($large-up) {
            margin-#{$ldirection}: 15px;
          }
          &:hover {
            @include swap_direction(margin, 0 0 0 15px);
            @include swap_direction(padding, 0 0 2px 0);
            color: $color-white;
            border-bottom: 1px solid $color-white;
            text-decoration: none;
            @include breakpoint($medium-up) {
              margin-#{$ldirection}: 0;
            }
            @include breakpoint($large-up) {
              margin-#{$ldirection}: 15px;
            }
          }
          &:focus {
            text-decoration: none;
          }
        }
      }
    }
  }
}

.pr-review-title {
  padding: 0;
  font-family: $font--text;
  color: $color-black;
  font-size: 18px;
  letter-spacing: 0.06em;
  font-weight: 500;
  text-transform: uppercase;
  position: relative;
  text-align: center;
  margin-bottom: 0;
  &-align {
    top: 68px;
    text-align: #{$ldirection};
    padding-#{$ldirection}: 15%;
    margin-#{$ldirection}: 15px;
    @include breakpoint($medium-up) {
      padding: 0 41px;
      margin-#{$ldirection}: 0;
    }
  }
}

#pr-reviewdisplay {
  padding: 20px 0;
  margin-#{$ldirection}: auto;
  margin-#{$rdirection}: auto;
  max-width: 1280px;
  .product-makeup-vto--active & {
    display: none;
    @include breakpoint($medium-up) {
      display: initial;
    }
  }
  .p-w-r {
    @include pr-black-background-image();
    margin: 0;
    .pr-review-snapshot {
      background-color: $color-lighter-gray;
      padding: 0 20px;
      @include breakpoint($medium-up) {
        padding: 0 40px;
      }
      .pr-review-snapshot-block-container,
      .pr-review-snapshot-msq-container {
        display: none;
      }
      .pr-review-snapshot-header {
        padding-top: 80px;
        .pr-snippet-stars-reco-inline {
          position: relative;
          .pr-snippet-stars-reco-stars {
            float: none;
            text-align: #{$ldirection};
          }
          .pr-snippet-stars-container {
            display: block;
            @include breakpoint($medium-up) {
              display: inline-block;
            }
          }
          .pr-snippet-read-and-write {
            display: block;
            .pr-snippet-review-count {
              display: inline-block;
              margin: 0;
              padding-bottom: 2px;
              text-align: center;
              font-size: 12px !important;
              border-bottom: 1px solid $color-black;
              text-transform: uppercase;
              font-weight: 500;
              padding: 0;
              position: absolute;
              #{$ldirection}: 15%;
              margin-#{$ldirection}: 94px;
              top: 2px;
              @include breakpoint($medium-up) {
                margin-top: 5px;
                text-align: #{$ldirection};
                margin-#{$ldirection}: 17px;
                display: inline;
                margin-#{$rdirection}: 0;
                float: $ldirection;
                position: static;
                #{$ldirection}: 0;
              }
            }
          }
        }
        .pr-snippet-stars-reco-stars {
          float: none;
          .pr-snippet-write-review-link {
            @include pr-sub-btn;
            display: block;
            #{$ldirection}: 0;
            top: 18px;
            margin: 0 auto;
            width: 70%;
            float: none;
            position: relative;
            font-size: 12px !important;
            padding: 13px 30px;
            @include breakpoint($medium-up) {
              float: #{$rdirection};
              border: 1px solid $color-black;
              width: auto;
              display: inline;
              #{$ldirection}: inherit;
              top: 0;
              margin: 0;
            }
          }
        }
        .pr-snippet-stars-container {
          float: none;
          @include breakpoint($medium-up) {
            float: #{$ldirection};
          }
          .pr-snippet-stars-png {
            background: transparent;
            padding-#{$ldirection}: 15%;
            display: block;
            @include breakpoint($medium-up) {
              padding-#{$ldirection}: 0;
              display: inline-block;
            }
          }
        }
      }
    }
    .pr-review-display {
      .pr-rd-main-header {
        margin-top: 0;
        padding: 10px 20px 50px;
        @include breakpoint($medium-up) {
          padding: 0 270px 50px;
          margin: -70px 0 0;
        }
        @include breakpoint($tablet-only) {
          padding: 0 220px 50px;
        }
        .pr-rd-review-total {
          display: none;
        }
        .pr-rd-sort-group {
          margin-#{$rdirection}: 0;
          text-align: center;
          @include breakpoint($medium-up) {
            text-align: #{$ldirection};
            position: relative;
            z-index: 1;
          }
          @include breakpoint($tablet-only) {
            margin-#{$rdirection}: 10%;
            text-align: #{$rdirection};
          }
          label {
            font-family: $font--text;
            color: $color-black;
            margin-#{$rdirection}: 8px;
          }
          select {
            border: 0;
            background-color: transparent;
            text-transform: none;
            font-family: $font--text;
            color: $color-black;
            width: auto;
            padding-top: 8px;
            background-position: 100% 18px;
            @include breakpoint($medium-up) {
              padding-top: 5px;
            }
            &:focus {
              outline: none;
              box-shadow: none;
            }
          }
        }
      }
      .pr-review {
        padding: 40px 20px;
        @include breakpoint($medium-up) {
          padding: 50px 40px 20px;
          margin-bottom: 0;
        }
        &:nth-child(even) {
          background-color: $color-lighter-gray;
        }
        .pr-rd-header {
          .pr-rd-star-rating,
          .pr-rd-review-headline {
            float: none;
          }
          .pr-rd-review-headline {
            @include swap_direction(margin, 25px 0 10px 0);
            text-decoration: none;
            font-family: $font--text;
            color: $color-black;
            font-size: 14px;
            font-weight: 700;
          }
        }
        .pr-rd-description {
          .pr-rd-right {
            .pr-rd-details {
              .pr-rd-bold {
                font-weight: normal;
              }
              time,
              span {
                color: $color-black;
                font-weight: 500;
              }
            }
          }
        }
        .pr-accordion {
          .pr-accordion-btn {
            span {
              font-size: 12px;
              text-transform: uppercase;
              color: $color-black;
              &:first-of-type {
                padding-#{$rdirection}: 15px;
                padding-top: 6px;
              }
            }
            .pr-caret-icon__line {
              stroke: $color-black;
            }
          }
          &.pr-accordion-exapanded {
            span {
              &:first-of-type {
                padding-top: 2px;
              }
            }
          }
          .pr-accordion-content {
            .pr-rd-tags {
              dl[data-reactid-powerreviews$='$tag-groups-pros'],
              dl[data-reactid-powerreviews$='$tag-groups-cons'] {
                display: none;
              }
              dl {
                display: list-item;
                @include breakpoint($medium-up) {
                  display: block;
                }
              }
              dt {
                float: $ldirection;
              }
              dd {
                text-transform: capitalize;
                padding: 0;
              }
            }
            .pr-rd-tags,
            .pr-rd-subratings {
              width: 100%;
              clear: both;
              margin-bottom: 0;
              @include breakpoint($medium-up) {
                width: 95%;
              }
              dl {
                width: 100%;
                display: list-item;
                margin-bottom: 2px;
                overflow: auto;
                @include breakpoint($medium-up) {
                  margin-bottom: 2px;
                }
              }
              dt {
                width: 65%;
                font-weight: normal;
                float: $ldirection;
                height: auto;
                padding-#{$rdirection}: 5px;
                @include breakpoint($medium-up) {
                  @include swap_direction(padding, 0 15px 0 0);
                  width: 400px;
                }
              }
              dd {
                width: 35%;
                display: table-cell;
                font-weight: 500;
                text-transform: capitalize;
                padding: 0;
                float: $ldirection;
                min-width: auto;
                height: auto;
                @include breakpoint($medium-up) {
                  width: auto;
                }
                &:not(:first-of-type) {
                  width: 100%;
                  padding-#{$ldirection}: 65%;
                  padding-bottom: 5px;
                  @include breakpoint($medium-up) {
                    padding-#{$ldirection}: 400px;
                  }
                }
              }
            }
          }
        }
        .pr-modal-container {
          z-index: 9999;
          .pr-flag-review {
            .pr-control-label {
              font-size: 14px;
              color: $color-black;
              font-family: $font--text;
            }
            .pr-flag-review-input {
              position: relative;
              #{$ldirection}: 0;
            }
            .pr-flag-review-btn {
              @include pr-sub-btn;
            }
            .pr-form-control {
              &:focus {
                outline: none;
                box-shadow: none;
                border: 1px solid $color-black;
                border-radius: 0;
              }
            }
          }
        }
      }
      .pr-rd-main-footer {
        border-top: 1px solid $color-border;
        border-bottom: 1px solid $color-border;
        padding: 15px 0;
        margin: 0 20px;
        @include breakpoint($medium-up) {
          margin: 0 40px;
        }
        @include breakpoint($tablet-only) {
          position: relative;
        }
        .pr-rd-content-block {
          width: 100%;
          .pr-rd-to-top {
            margin-#{$ldirection}: 0;
            text-align: center;
            text-decoration: none;
            padding-bottom: 2px;
            border-bottom: 1px solid $color-black;
            @include breakpoint($medium-up) {
              margin-#{$ldirection}: 33%;
            }
            @include breakpoint($tablet-only) {
              position: absolute;
              bottom: 0;
              #{$ldirection}: 50%;
              margin: 0;
              transform: translateX(-50%);
            }
          }
          .pr-rd-pagination {
            border: 0;
            @include breakpoint($medium-up) {
              border: none;
            }
          }
          .pr-rd-pagination-btn,
          .pr-rd-review-position span {
            font-size: 14px;
            color: $color-black;
            font-family: $font--text;
          }
        }
      }
      &.pr-rd-display-desktop {
        .pr-rd-side-content-block {
          width: 260px;
          #{$rdirection}: -33%;
          @include breakpoint($medium-up) {
            width: auto;
            max-width: 300px;
            padding-#{$rdirection}: 0;
          }
        }
      }
      .pr-snippet-stars {
        background: none;
      }
      .pr-modal {
        .pr-flag-review-label {
          line-height: 19px;
          font-size: 14px;
          font-family: $font--text;
          font-weight: 500;
          margin-bottom: 7px;
          color: $color-black;
        }
        .pr-textinput,
        .pr-textarea {
          border-color: $color-border;
          border-radius: 0;
          &:focus {
            border-color: $color-black;
          }
        }
        .pr-flagging-radio {
          span {
            font-size: 12px;
          }
          label {
            margin-bottom: 0;
            display: block;
          }
        }
        .pr-flag-review-input {
          &:focus {
            outline: none;
            box-shadow: none;
          }
        }
        .pr-has-error {
          .pr-textinput,
          .pr-textarea {
            border-color: $color-red;
          }
          .pr-flag-error {
            color: $color-red;
          }
        }
        .pr-flag-review-btn {
          background: $color-black;
          color: $color-white;
          padding: 10px 30px;
          font-size: 12px;
          font-family: $font--text;
          text-transform: uppercase;
          border: 1px solid $color-black;
          letter-spacing: 1.92px;
          line-height: 20.5715px;
          border-radius: 0;
        }
        .pr-flag-review-btn-cancel {
          background: none;
          color: $color-black;
          border: 1px solid $color-black;
          &:hover {
            background: $color-button-dark-fill;
            color: $color-white;
            border: 1px solid $color-button-dark-fill;
          }
        }
        .pr-flagging-radio {
          padding-#{$ldirection}: 0;
          span {
            text-transform: uppercase;
            font-size: 13px;
            &:last-child {
              position: relative;
              padding-#{$ldirection}: 20px;
              display: block;
              line-height: 16px;
              margin-bottom: 8px;
              &:before {
                content: '';
                position: absolute;
                #{$ldirection}: 0;
                top: 1px;
                width: 14px;
                height: 14px;
                border-radius: 50%;
                border: 1px solid $color-black;
              }
              &:after {
                content: '';
                position: absolute;
                #{$ldirection}: 3px;
                top: 4px;
                width: 8px;
                height: 8px;
                border-radius: 50%;
                background: $color-black;
                opacity: 0;
              }
            }
          }
          .pr-flag-review-input {
            opacity: 0;
            &:checked {
              & ~ span {
                &:last-child {
                  &:after {
                    opacity: 1;
                  }
                }
              }
            }
          }
        }
      }
    }
    .pr-rd-footer {
      background: none;
      padding-bottom: 0;
      .pr-rd-bottomline {
        padding-bottom: 2px;
        .pr-rd-bold {
          display: none;
        }
        span {
          font-weight: 500;
        }
      }
      .pr-rd-helpful-text {
        margin-top: 10px;
        font-weight: 500;
      }
      .pr-helpful {
        &-count {
          font-size: 14px;
          text-transform: uppercase;
          color: $color-black;
          font-weight: 500;
        }
        &-btn {
          border: none;
          padding: 12px 0;
          background: transparent;
          vertical-align: top;
          font-weight: 700;
          .pr-thumbs-icon {
            display: none;
          }
          &.pr-helpful-active {
            background: none !important;
          }
        }
        &-yes {
          margin-#{$rdirection}: 40px;
          @include breakpoint($medium-up) {
            margin-#{$rdirection}: 25px;
          }
          .pr-helpful-count {
            &:before {
              content: 'yes -';
              visibility: visible;
              text-transform: uppercase;
              font-size: 14px;
              margin-#{$rdirection}: 5px;
            }
          }
        }
        &-no {
          @include breakpoint($medium-up) {
            margin-#{$rdirection}: 10px;
          }
          .pr-helpful-count {
            &:before {
              content: 'no -';
              visibility: visible;
              margin-#{$rdirection}: 5px;
              text-transform: uppercase;
              font-size: 14px;
            }
          }
        }
      }
      .pr-rd-flag-review-container {
        a {
          text-decoration: none;
          padding-bottom: 2px;
          border-bottom: 1px solid $color-black;
          text-transform: uppercase;
          font-size: 11px;
          letter-spacing: 0.05em;
        }
        .pr-flag-icon {
          position: relative;
          bottom: 5px;
        }
      }
      .pr-modal-content {
        overflow: scroll;
        height: 600px;
        @include breakpoint($medium-up) {
          overflow: auto;
          height: auto;
        }
      }
      .pr-modal {
        top: 45%;
        @include breakpoint($medium-up) {
          top: 50%;
        }
      }
      .pr-flag-review {
        .pr-form-group {
          width: 45%;
        }
        input[type='radio'] {
          #{$ldirection}: auto;
        }
      }
      .button {
        &__close--dark {
          width: 30px;
          height: 30px;
          top: 10px;
          #{$rdirection}: 10px;
          @include breakpoint($medium-up) {
            width: 50px;
            height: 50px;
            top: 30px;
            #{$rdirection}: 30px;
          }
          .pr-cross-icon {
            width: 30px;
            height: 30px;
            @include breakpoint($medium-up) {
              width: 50px;
              height: 50px;
            }
            &__circle,
            &__line {
              stroke: $color-black;
              @include breakpoint($medium-up) {
                stroke: $color-white;
              }
            }
          }
        }
      }
    }
    .pr-rd-footer {
      .pr-rd-bottomline,
      .pr-rd-helpful-action,
      .pr-rd-flag-review-container {
        vertical-align: middle;
      }
    }
  }
  .pr-rd-no-reviews {
    padding: 0 10px;
    .pr-snippet-write-review-link {
      font-family: $font--text;
      font-size: 12px;
      color: $color-black;
      text-transform: uppercase;
      text-decoration: underline;
    }
  }
  #pr-image-display {
    display: none;
  }
}

//WAR page

#power_review_container {
  .product-full__container {
    padding: 0 15px 20px;
    min-height: auto;
    background-color: $color-white;
    @include breakpoint($medium-up) {
      min-height: 680px;
    }
    .product-full__image {
      width: 100%;
      margin-bottom: 20px;
      display: inline-block;
      @include breakpoint($medium-up) {
        width: 45%;
        margin: 0;
      }
    }
    .product-full__details {
      width: 100%;
      margin-#{$ldirection}: 0;
      display: inline-block;
      @include breakpoint($medium-up) {
        width: 50%;
        margin-#{$ldirection}: 5%;
        padding-top: 80px;
        padding-bottom: 30px;
      }
      > a {
        font-weight: 500;
        font-size: 18px;
        border: none;
        display: block;
        margin-bottom: 30px;
        pointer-events: none;
        &,
        h1 {
          padding-top: 20px;
        }
      }
    }
    .product-full__name {
      margin-bottom: 16px;
    }
  }
  .email_check,
  .full_sign_in {
    h2 {
      &:nth-child(1) {
        font-size: 15px;
        line-height: 18px;
        color: $color-black;
        margin-bottom: 20px;
        text-transform: uppercase;
        letter-spacing: 1.5px;
        @include breakpoint($medium-up) {
          margin-bottom: 40px;
        }
      }
    }
    h3,
    h2:nth-child(2) {
      font-size: 15px;
      line-height: 18px;
      color: $color-black;
      margin-bottom: 20px;
      letter-spacing: 0.28px;
    }
    input[type='email'],
    input[type='password'] {
      width: 50%;
      margin-bottom: 25px;
      color: $color-black;
      min-width: 280px;
    }
    input[type='submit'] {
      width: 180px;
      margin-bottom: 20px;
    }
    .error {
      margin-bottom: 15px;
      color: $color-red;
    }
    .pr-forgot-password {
      font-weight: 500;
      margin-bottom: 35px;
      display: inline-block;
      letter-spacing: 1.4px;
    }
  }
  #review_container {
    > a {
      display: block;
      padding-bottom: 20px;
    }
    .pr-heading {
      padding-bottom: 20px;
      color: $color-black;
      font-family: $font--text;
      font-weight: 500;
      font-size: 16px;
    }
  }
}

#pr-write {
  .p-w-r {
    #pr-rating,
    .thank-you-page {
      .pr-rating-stars {
        @include pr-black-background-image();
        .pr-star-v4 {
          height: 25px;
          width: 25px;
          &:before {
            display: none;
          }
        }
      }
    }
  }
  .pr-war {
    .pr-header {
      .pr-table-cell {
        padding-#{$ldirection}: 0;
        .pr-header-title {
          font-family: $font--text;
          color: $color-black;
        }
      }
      &-table {
        display: none;
      }
    }
    .pr-submit {
      .pr-subscript {
        margin-bottom: 25px;
        a {
          font-weight: 700;
          text-decoration: none;
          border-bottom: 1px solid $color-black;
          padding-bottom: 2px;
        }
      }
      .pr-accessible-btn {
        @include pr-sub-btn;
        width: 100%;
        @include breakpoint($medium-up) {
          width: auto;
        }
      }
    }
    select,
    .pr-btn,
    .pr-tag-label {
      @include pr-form-field-placeholder;
    }
    .pr-textinput,
    .pr-textarea {
      &::placeholder {
        @include pr-form-field-placeholder;
      }
      &:-ms-input-placeholder {
        @include pr-form-field-placeholder;
      }
    }
  }
  .thank-you-page {
    .pr-row {
      .header {
        .title,
        .subtitle {
          font-family: $font--text;
          color: $color-black;
        }
        .title {
          font-weight: 500;
          font-size: 24px;
        }
        .subtitle {
          font-weight: 500;
          font-size: 14px;
        }
        h5 {
          a {
            text-decoration: none;
            border-bottom: 1px solid $color-black;
            padding-bottom: 2px;
          }
        }
      }
      #war-ty-cons {
        display: none;
      }
      #war-ty-best-uses,
      #war-ty-pros {
        padding: 0;
        .pr-attribute-header {
          background-color: transparent;
          padding-#{$ldirection}: 15px;
          font-family: $font--text;
          color: $color-black;
          font-weight: 500;
        }
        .pr-attribute-list {
          li {
            font-family: $font--text;
            color: $color-black;
            padding-#{$ldirection}: 15px;
            font-size: 14px;
          }
        }
      }
      .col-sm-12 {
        .headline {
          font-family: $font--text;
          color: $color-black;
          font-weight: 500;
          font-size: 18px;
        }
        .pr-rating-number {
          display: none;
        }
        .pr-rating-date {
          font-family: $font--text;
          color: $color-black;
          border: 0;
        }
        .pr-text {
          overflow: hidden;
          .pr-dark {
            color: $color-black;
            width: 100%;
            display: inline-block;
            font-weight: 500;
            float: #{$ldirection};
            @include breakpoint($medium-up) {
              width: 48%;
            }
          }
          span {
            width: 100%;
            display: inline-block;
            @include breakpoint($medium-up) {
              width: 52%;
            }
          }
        }
      }
    }
  }
  .pr-template-load-alert {
    .pr-alert-container {
      display: block;
    }
  }
}

#pr-war-form {
  .form-group {
    margin-bottom: 20px;
    .pr-control-label {
      span {
        font-weight: 500;
      }
      .pr-required-indicator {
        color: $color-red;
      }
    }
    input,
    textarea,
    select {
      &:focus {
        outline: none;
        box-shadow: none;
        border: 1px solid $color-black;
      }
    }
    input,
    select,
    textarea {
      border-radius: 0;
    }
    select {
      background-image: url('#{$base-theme-path}img/select-box/caret--down.png');
    }
    .pr-helper-text {
      display: none;
    }
    .pr-clear-all-radios {
      font-size: 11px;
    }
    .btn-toggle {
      .pr-btn {
        &.active {
          color: $color-white;
          background-color: $color-black;
          border: 1px solid $color-black;
        }
        &:focus {
          outline: none;
          box-shadow: none;
        }
      }
    }
    .tag-container {
      .pr-btn-add-tag {
        a {
          text-decoration: none;
          color: $color-white;
          background-color: $color-black;
          border: 1px solid $color-black;
          text-transform: uppercase;
          width: 100%;
          @include breakpoint($medium-up) {
            width: inherit;
          }
          &:hover {
            color: $color-white;
            background-color: $color-button-dark-fill;
            border: 1px solid $color-black;
            &:after {
              color: $color-white;
            }
          }
          &:after {
            color: $color-white;
          }
        }
      }
      .input-group-btn {
        .pr-btn-add-tag-submit {
          &:hover {
            background-color: $color-black;
            color: $color-white;
            border: 1px solid $color-black;
          }
        }
      }
      .pr-cb-tag-container {
        .pr-label-control {
          &.checked {
            background-color: $color-black;
            color: $color-white;
            border: 1px solid $color-black;
          }
        }
      }
    }
    .pr-btn-delete {
      background-color: $color-black;
      color: $color-white;
      border: 1px solid $color-black;
    }
    .pr-btn-fileinput {
      span {
        line-height: normal;
      }
    }
    &.pr-has-error {
      .pr-control-label {
        span {
          color: $color-red;
        }
      }
      .form-control {
        border: 1px solid $color-red;
        &:focus {
          box-shadow: none;
        }
      }
    }
    &.pr-media_image-form-group,
    &.pr-media_videourl-form-group {
      .pr-btn-fileinput {
        width: 100% !important;
        color: $color-white;
        background-color: $color-black;
        border: 1px solid $color-black;
        text-transform: uppercase;
        @include breakpoint($medium-up) {
          width: inherit !important;
        }
        &:hover {
          color: $color-white;
          background-color: $color-button-dark-fill;
          border: 1px solid $color-black;
          &:after {
            color: $color-white;
          }
        }
        span {
          color: $color-white;
          &:after {
            color: $color-white;
          }
        }
      }
    }
  }
  .tag-group {
    .pr-label-control {
      &.checked {
        span {
          text-transform: uppercase;
          color: $color-white;
        }
      }
      span {
        text-transform: uppercase;
      }
    }
  }
  .pr-cons-form-group,
  .pr-wasthisagift-form-group,
  .pr-pros-form-group,
  .pr-bestuses-form-group,
  .pr-describeyourself-form-group {
    display: none;
  }
}

.mpp__container,
.search-wrapper {
  .product-brief__container {
    padding-bottom: 35px;
    position: relative;
  }
  .product-rating {
    position: absolute;
    bottom: 0;
    #{$ldirection}: 0;
    #{$rdirection}: 0;
    text-align: center;
  }
}

#pr-write .pr-email_collection-form-group {
  // Hide email field from write a review.
  /* stylelint-disable-next-line declaration-no-important */
  display: none !important;
}
