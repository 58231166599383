.search-form {
  @include pie-clearfix;
  width: 100%;
  //max-width: 1000px;
  margin: 0 auto;
  position: relative;
  &__fields {
    @include pie-clearfix;
    @include swap_direction(margin, 0 0 30px 15px);
    position: relative;
    font-size: 12px;
    width: 78.125%; //250 @ 320px
    .search-results__header & {
      @include breakpoint($medium-down) {
        @include swap_direction(margin, 0 auto 30px auto);
      }
    }
    @include breakpoint($medium-up) {
      @include swap_direction(margin, 0 auto 44px auto);
      width: 100%;
      max-width: 380px;
    }
  }
  input[type='text'].search-form__field,
  input[type='submit'].search-form__submit {
    display: block;
    border: none;
  }
  input[type='text'].search-form__field {
    @include font-face--helvetica-medium;
    @include swap_direction(padding, 0 30px 0 22px);
    color: $color-black;
    font-size: 10px;
    letter-spacing: 0.13em;
    line-height: 45px;
    width: 100%;
    background: $color-white;
    height: auto;
    text-transform: uppercase;
    @include breakpoint($medium-up) {
      @include swap_direction(padding, 0 40px 0 32px);
      font-size: 12px;
      height: 60px;
      line-height: 60px;
      @include input-placeholder {
        color: $color-darker-gray;
        font-size: 12px;
        height: 60px;
      }
    }
  }
  input[type='submit'].search-form__submit {
    height: 60px;
    line-height: 45px;
    display: block;
    width: 40px;
    padding: 0;
    position: absolute;
    #{$rdirection}: 0;
    opacity: 0; // we hide the button with opacity, the icon is visible
    z-index: 2;
    @include breakpoint($medium-up) {
      top: 0;
      line-height: 60px;
    }
  }
  .icon--search {
    color: $color-darker-gray;
    position: absolute;
    font-size: 24px;
    line-height: 45px;
    top: 0;
    #{$rdirection}: 25px;
    @include breakpoint($medium-up) {
      line-height: 60px;
    }
  }
  &__actions {
    display: none; // for now this is undefined behavior.
    text-align: center;
    .esearch__reset {
      @include font-face--helvetica-medium;
      letter-spacing: 0.1em;
      display: inline-block;
      font-size: 12px;
      line-height: 12px;
      position: relative;
      text-transform: uppercase;
      margin-bottom: 50px;

      //@include icon("close", $text-replace: false);

      @include breakpoint($medium-up) {
        display: inline-block;
      }
      &:before {
        content: 'X';
        position: absolute;
        #{$ldirection}: -18px;
        text-decoration: none;
      }
    }
  }
}
