.orders {
  &-list {
    &__container {
      display: block;
    }
    &__link {
      display: inline-block;
    }
    &__item {
      ul {
        overflow: auto;
      }
      li {
        @include breakpoint($landscape-up) {
          float: #{$ldirection};
        }
        &.orders-list__table--order-accordion {
          cursor: pointer;
          float: #{$rdirection};
        }
      }
      span {
        width: 50%;
        display: inline-block;
        text-transform: uppercase;
        font-weight: bold;
        &.pc-hidden {
          @include breakpoint($landscape-up) {
            display: none;
          }
        }
      }
      @include account_page_header_border();
      margin-bottom: 0;
      padding: 10px 0;
      .past-purchases__item {
        padding: 0;
        .product-header {
          padding: 1.3rem 0;
          overflow: auto;
          h6 {
            font-size: 14px;
            line-height: 24px;
            clear: none;
          }
          .product {
            width: 50%;
            float: #{$ldirection};
            padding-#{$rdirection}: 1em;
            opacity: 0;
          }
          .quantity {
            width: 25%;
            float: #{$ldirection};
            padding-#{$rdirection}: 1em;
            text-align: center;
          }
          .price {
            width: 25%;
            float: #{$rdirection};
            padding-#{$rdirection}: 0;
            @include breakpoint($landscape-up) {
              width: 11%;
              float: #{$ldirection};
              padding-#{$rdirection}: 1em;
            }
            margin-#{$ldirection}: 0;
            margin-#{$rdirection}: 0;
            text-align: center;
          }
        }
        .product {
          &-content {
            clear: both;
          }
          &--teaser {
            padding: 0;
            @include breakpoint($landscape-up) {
              padding: 1em 0;
            }
            border: none;
            min-height: 0;
            overflow: auto;
            &-info {
              width: 50%;
              float: #{$ldirection};
              overflow: auto;
            }
          }
          &-info__link {
            text-decoration: none;
            border: 0;
            display: inline-block;
          }
          &__image {
            width: 100%;
            max-width: 120px;
            @include breakpoint($landscape-up) {
              width: 22%;
            }
            float: #{$ldirection};
            clear: none;
            padding-#{$rdirection}: 1em;
            img {
              width: 100%;
              max-width: 120px;
              vertical-align: top;
            }
          }
          &__detail {
            width: 100%;
            @include breakpoint($landscape-up) {
              width: 56%;
              margin-top: 13px;
            }
            float: #{$ldirection};
            clear: none;
            padding-#{$rdirection}: 1em;
            height: 100%;
            .product__name {
              text-transform: uppercase;
              .product-info__link {
                text-decoration: none;
                font-weight: bold;
              }
              @include breakpoint($landscape-up) {
                padding-#{$rdirection}: 95px;
              }
            }
            .product__description-short {
              padding-bottom: 0;
              margin: 0;
              display: none;
              @include breakpoint($landscape-up) {
                @include swap_direction(margin, 6px 80px 6px 0);
              }
              position: relative;
              z-index: 100;
            }
          }
          &__status {
            @include breakpoint($landscape-up) {
              width: 22%;
            }
            float: #{$ldirection};
            clear: none;
            padding-#{$rdirection}: 1em;
            @include breakpoint($landscape-up) {
              line-height: 47px;
            }
          }
          &__qty {
            width: 25%;
            float: #{$ldirection};
            clear: none;
            padding-#{$rdirection}: 1em;
            text-align: center;
            @include breakpoint($landscape-up) {
              line-height: 47px;
            }
          }
          &__price {
            width: 25%;
            @include breakpoint($landscape-up) {
              width: 11%;
            }
            float: #{$ldirection};
            clear: none;
            padding-#{$rdirection}: 1em;
            text-align: center;
            @include breakpoint($landscape-up) {
              line-height: 47px;
            }
          }
          &__add {
            width: 100%;
            float: #{$ldirection};
            margin-top: 10px;
            @include breakpoint($landscape-up) {
              width: 14%;
              float: #{$rdirection};
              margin-top: 0;
            }
            margin-#{$ldirection}: 0;
            margin-#{$rdirection}: 0;
            clear: none;
            padding-#{$rdirection}: 0;
            .button {
              min-width: auto;
              padding: 5px;
            }
          }
        }
      }
    }
    &__table {
      margin-top: 10px;
      overflow: auto;
      @include account_page_header_border();
      margin-bottom: 0;
      &.mobile-hidden {
        display: none;
        @include breakpoint($landscape-up) {
          display: block;
        }
      }
      &--title {
        @include breakpoint($landscape-up) {
          width: 20%;
          height: auto;
          float: #{$ldirection};
          text-transform: uppercase;
          font-weight: bold;
        }
      }
      @include breakpoint($landscape-up) {
        &--order {
          &-date {
            width: 14%;
          }
          &-number {
            width: 20%;
          }
          &-status {
            width: 14%;
          }
          &-total {
            width: 10%;
          }
        }
        &--shipping-carrier {
          width: 21%;
        }
        &--tracking-number {
          width: 20%;
        }
      }
    }
  }
}
