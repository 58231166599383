.past-purchases-page__content {
  .past-purchases .product-item {
    @extend %account-page-grid;
    margin-bottom: 50px;
    font-size: 14px;
    text-align: center;
    @include breakpoint($small-down) {
      min-height: 400px;
    }
    &__img {
      margin-bottom: 15px;
      a {
        border: 0;
      }
      img {
        width: 100%;
        height: 100%;
        @include breakpoint($landscape-up) {
          width: auto;
        }
      }
    }
    &__link {
      border: 0;
      &.button {
        width: 90%;
        padding-#{$ldirection}: 5px;
        padding-#{$rdirection}: 5px;
        @include breakpoint($landscape-up) {
          width: auto;
          padding-#{$ldirection}: 40px;
          padding-#{$rdirection}: 40px;
        }
      }
    }
    &__name {
      margin-bottom: 8px;
      text-transform: uppercase;
      font-size: 14px;
      a {
        border: 0;
        font-weight: bold;
      }
    }
    &__subhead-size {
      margin-bottom: 8px;
    }
    &__sub-line,
    &__size-label,
    &__price-label {
      display: none;
    }
    &__add-to-cart {
      margin: 0;
      position: absolute;
      top: 390px;
      #{$ldirection}: 35px;
    }
    &__subhead,
    &__size {
      display: inline;
    }
  }
  .past-purchases {
    overflow: auto;
  }
}
