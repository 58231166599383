///
/// @file template_api/_gnav-util.scss
///
/// \brief Styles for gnav utility links and expandable content wrappers
///
///
///

$gnav-util-rollover-common-offset: 2px;

@mixin gnav-util-add-border($border-height: 18px, $border-offset: 0px) {
  position: relative;
  // border

  &:after {
    content: '';
    height: $border-height;
    width: 1px;
    position: absolute;
    #{$rdirection}: 0;
    top: calc(50% - #{$border-height} / 2 + #{$border-offset});
    background-color: #9a9b9b;
    @include breakpoint($medium-up) {
      background-color: $color-button-gray;
    }
  }
}

@mixin gnav-util-remove-border() {
  &:after {
    display: none;
  }
}

.gnav-util {
  float: #{$rdirection};
  font-size: 130%;
  color: $color-util-icon;
  @include gnav-util-add-border(14px, 1px);
  @include breakpoint($medium-up) {
    @include gnav-util-add-border(18px);
  }
  @include breakpoint($large-up) {
    //.visuallyhidden, // DEV uncomment to show rollover text
    &:hover .visuallyhidden {
      @include font-face--helvetica-medium;
      color: $color-util-rollover-text;
      text-transform: uppercase;
      font-size: 10px;
      letter-spacing: 0.05em;
      clip: auto;
      height: auto;
      width: auto;
      margin: auto;
      top: calc(100% - 3px - #{$gnav-util-rollover-common-offset});
      #{$ldirection}: 50%;
      transform: translate(-50%, 0%);
      line-height: 1.2em;
    }
  }
  > a {
    display: block;
    padding: 0 0.7em;
    text-align: center;
    height: $header-height;
    border: 0;
    outline: 0;
    &:active,
    &:hover,
    &:focus {
      outline: 0;
    }
  }
  &.gnav-util--hamburger {
    float: #{$ldirection};
    font-size: 13px;
    @include breakpoint($medium-up) {
      font-size: 15px;
    }
    > a {
      padding: 0px 10px;
    }
    .gnav-util__icon-title {
      @include font-face--helvetica-medium;
      color: $color-util-rollover-text;
      font-size: 10px;
      text-transform: uppercase;
      letter-spacing: 0.1em;
      margin-#{$ldirection}: 3px;
      @include breakpoint($medium-up) {
        font-size: 10px;
      }
    }
  }
  &.gnav-util--search {
    .gnav-util__icon--search {
      // applies only to gnav
      .icon--search {
        @include breakpoint($medium-up) {
          display: block;
          position: relative;
          top: 11px;
        }
      }
    }
    .icon--search {
      // applies to gnav and search flyout/form
      font-size: 18px;
      @include breakpoint($medium-up) {
        font-size: 25px;
      }
    }
    > .gnav-util__icon {
      padding-#{$rdirection}: 15px;
      @include breakpoint($medium-up) {
        @include swap_direction(padding, 0 15px 0 15px);
      }
    }
    @include breakpoint($large-up) {
      @include gnav-util-remove-border;
    }
    .gnav-util__content {
      position: fixed;
      overflow-y: auto;
      overscroll-behavior-y: contain;
      width: 100vw;
      height: calc(100vh - #{$header-height});
      @include breakpoint($medium-up) {
        height: calc(100vh - #{$ipad-header-height});
      }
      @include breakpoint($large-up) {
        height: calc(100vh - #{$medium-up-header-height});
      }
    }
  }
  &.gnav-util--cart {
    @include breakpoint($medium-up) {
      margin-#{$rdirection}: 10px;
    }
    @include breakpoint($large-up) {
      margin-#{$rdirection}: $pc-content-well-padding;
    }
    .icon--bag {
      font-size: 18px;
      @include breakpoint($medium-up) {
        display: block;
        position: relative;
        top: 11px;
      }
    }
    > .gnav-util__icon {
      padding-#{$ldirection}: 15px;
      @include breakpoint($medium-up) {
        padding-#{$ldirection}: 10px;
        position: relative;
      }
    }
  }
  &.gnav-util--hamburger,
  &.gnav-util--cart {
    @include gnav-util-remove-border;
  }
  &.gnav-util--stores,
  &.gnav-util--account {
    display: none;
  }
  @include breakpoint($large-up) {
    &.gnav-util--search {
      float: #{$ldirection};
      margin-#{$ldirection}: $pc-content-well-padding;
      > .gnav-util__icon {
        padding: 0px;
      }
    }
  }
  @include breakpoint($medium-up) {
    font-size: 200%;
    &.gnav-util--account {
      .icon--account {
        font-size: 16px;
        display: block;
        position: relative;
        top: 15px;
      }
      > .gnav-util__icon {
        @include swap_direction(padding, 0 14px 0 15px);
      }
      .gnav-util__avatar {
        display: none;
      }
      .visuallyhidden {
        top: calc(100% - 1px - #{$gnav-util-rollover-common-offset});
      }
    }
    &.gnav-util--cart {
      margin-top: -1px;
      .icon--bag {
        font-size: 22px;
      }
      > .gnav-util__icon {
        padding-#{$ldirection}: 18px;
        padding-#{$rdirection}: 17px; // create space for count
      }
      .visuallyhidden {
        #{$ldirection}: calc(50% + 1px);
        top: calc(100% - 0px - #{$gnav-util-rollover-common-offset});
      }
    }
    &.gnav-util--stores {
      .icon--map-marker {
        font-size: 25px;
        display: block;
        position: relative;
        top: 11px;
      }
      > .gnav-util__icon {
        @include swap_direction(padding, 0 16px 0 15px);
        position: relative;
        top: 0px;
      }
      .visuallyhidden {
        top: calc(100% - 1px - #{$gnav-util-rollover-common-offset});
      }
    }
    &.gnav-util--stores,
    &.gnav-util--account {
      display: block;
    }
  }
  @include breakpoint($large-up) {
    &.gnav-util--hamburger {
      display: none;
    }
  }
}

.gnav-util__icon {
  color: inherit;
  outline: 0;
  &:hover {
    text-decoration: none;
    color: inherit;
    border: 0;
  }
}

.gnav-util__icon__cart-count {
  @include font-face--helvetica-medium;
  font-size: 11px;
  line-height: 0; /* prevents a bug with the height of the anchor */
  position: relative;
  top: 2px;
  #{$ldirection}: 1px;
  @include breakpoint($medium-up) {
    display: block;
    position: absolute;
    top: 27px;
    #{$ldirection}: 45px;
  }
}

// subnavs
.gnav-util__content {
  position: absolute;
  top: $header-height;
  #{$rdirection}: 0;
  width: 100%;
  z-index: 1000;
  background: $color-light-silver;
  @include breakpoint($medium-up) {
    top: $ipad-header-height;
  }
  @include breakpoint($large-up) {
    top: $medium-up-header-height;
  }
}

.gnav-util__content-close {
  position: absolute;
  #{$rdirection}: 18px;
  top: 44px;
  border: 0;
  font-size: 22px;
  line-height: 22px;
  z-index: 10;
  @include breakpoint($medium-up) {
    #{$rdirection}: 50px;
    top: 50px;
  }
  &:hover {
    color: $color-darker-gray;
    border: 0;
  }
}

.gnav-util__content-inner {
  margin: 0 auto;
  line-height: normal;
}

.gnav-util__content--search {
  .gnav-util__content-inner {
  }
}
