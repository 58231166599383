.link-based-offer {
  @include clearfix;
  padding: 20px;
  &__image {
    float: #{$ldirection};
  }
  &__description {
    float: #{$ldirection};
    margin-#{$ldirection}: 20px;
  }
  &__header {
    font-size: 20px;
    margin-bottom: 20px;
  }
  &__message {
  }
}
