.content-block-carousel-formatter {
  text-align: center;
  margin: 60px auto;
  width: 80%;
  &__title {
    @include breakpoint($medium-up) {
      width: 40%;
    }
    margin: 1em auto;
  }
  &__subtitle {
    margin-bottom: 2em;
  }
  button.slick-prev,
  button.slick-next {
    &:hover {
      color: $black;
    }
    &:before {
      font-size: 25px;
      @include breakpoint($medium-up) {
        font-size: 40px;
      }
    }
  }
  .slick-prev {
    #{$ldirection}: -25px;
    @include breakpoint($medium-up) {
      #{$ldirection}: -90px;
    }
  }
  .slick-next {
    #{$rdirection}: 9974px;
    @include breakpoint($medium-up) {
      #{$rdirection}: 9910px;
    }
  }
  .slick-dots {
    margin-bottom: 10px;
    @include breakpoint($medium-up) {
      margin-bottom: 0;
    }
  }
  &__cta {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

.content-block-carousel-slide {
  text-align: center;
  margin: 20px 0 10px;
  @include breakpoint($medium-up) {
    margin: 30px 0px;
  }
  &__title {
    text-transform: uppercase;
    font-weight: 500;
    @include breakpoint($medium-up) {
      padding-top: 2em;
      font-size: 16px;
    }
  }
  &__subtitle {
    font-size: 12px;
    margin-top: 10px;
    @include breakpoint($medium-up) {
      font-size: 14px;
    }
  }
}
