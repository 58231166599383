/* Dropdown control */
@mixin selectBox {
  @include font-smoothing(none);
  font-family: $font--text;
  font-weight: normal;
  font-size: 10px;
  letter-spacing: 0.1em;
  text-decoration: none;
  text-align: #{$ldirection};
  text-transform: uppercase;
  min-width: 150px;
  position: relative;
  border: 1px solid $color-light-stone-gray;
  color: $color-black;
  outline: none;
  vertical-align: middle;
  display: inline-block;
  cursor: pointer;
  height: 40px;
  line-height: 40px;
  background: $color-white;
  @include breakpoint($medium-up) {
    font-size: 12px;
  }
}

%selectBox {
  @include selectBox;
  &--inverted {
    border-color: $color-stone-gray;
    color: $color-light-stone-gray;
    background: $color-black;
  }
}

a.selectBox {
  @extend %selectBox;
  &--inverted {
    @extend %selectBox--inverted;
  }
  .selectBox-label {
    padding: 0 15px;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    @include breakpoint($medium-up) {
      padding: 0 20px;
    }
  }
  .selectBox-arrow {
    @include icon('caret--down', after); /* Down Arrow */
    position: absolute;
    top: 0;
    #{$rdirection}: 0;
    height: 100%;
    width: 7%;
    min-width: 40px;
    background-color: inherit;
    color: $color-stone-gray;
    &:after {
      top: 40%;
      position: absolute;
      #{$ldirection}: 35%;
    }
  }
  &.selectBox-menuShowing {
    .selectBox-arrow {
      @include icon('caret--up', after); /* Up Arrow */
    }
  }
  // mimic adaptive placeholder label treatment
  // <select class="selectBox" title="label goes here">
  &:before {
    @include breakpoint($medium-up) {
      @include font-smoothing;
      @include swap_direction(padding, 0 12px 0 14px);
      content: attr(title);
      font-size: 13px;
      line-height: 1;
      white-space: nowrap;
      background-color: inherit;
      color: inherit;
      position: absolute;
      top: 15px;
      #{$ldirection}: 50%;
      z-index: 1;
      -webkit-transform: translatex(-50%) translateY(-1.6em) translateY(-0.5px) scale(0.8, 0.8);
      transform: translatex(-50%) translateY(-1.6em) translateY(-0.5px) scale(0.8, 0.8);
    }
  }
}
/* Dropdown menu */
.selectBox-dropdown-menu {
  position: absolute;
  z-index: 99999;
  min-height: 1em;
  max-height: 200px;
  border: 1px solid $color-light-stone-gray;
  background: $color-white;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  .selectBox--inverted & {
    border-color: $color-stone-gray;
  }
  &.sku-menu-selectBox-dropdown-menu,
  &.product-qty-select__selectbox-selectBox-dropdown-menu {
    &.selectBox-attached-selectBox-dropdown-menu {
      z-index: auto;
    }
  }
}
/* Options */
@mixin selectBox-options {
  @include font-smoothing(none);
  font-family: $font--text;
  font-weight: normal;
  font-size: 10px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  text-decoration: none;
  text-align: #{$ldirection};
  list-style: none;
  display: block;
  padding: 10px 0;
  margin: 0;
  cursor: pointer;
  overflow: auto;
  @include breakpoint($medium-up) {
    font-size: 12px;
  }
}
%selectBox-options {
  @include selectBox-options;
  li {
    line-height: 30px;
    &:first-child {
      padding-top: 0;
    }
    a {
      display: block;
      color: $color-stone-gray;
      padding: 0 15px;
      white-space: nowrap;
      overflow: hidden;
      border: 0;
      &:hover {
        background: $color-light-silver;
      }
      @include breakpoint($medium-up) {
        padding: 0 20px;
      }
    }
    &.selectBox-selected a {
      color: $color-black;
    }
    &.selectBox-disabled a {
      color: $color-light-stone-gray;
      &:hover {
        background: $color-white;
        cursor: default;
      }
    }
  }
  &--inverted {
    background: $color-black;
    border-color: $color-stone-gray;
    li a {
      color: $color-light-stone-gray;
      &:hover {
        background: $color-charcoal;
      }
    }
    li.selectBox-selected a {
      color: $color-silver;
    }
    li.selectBox-disabled a {
      color: $color-stone-gray;
      &:hover {
        background: $color-black;
      }
    }
  }
}

.selectBox-options {
  @extend %selectBox-options;
  &.selectBox--inverted-selectBox-dropdown-menu {
    @extend %selectBox-options--inverted;
  }
}
