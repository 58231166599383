.brand-book--chapter {
  &--about {
    background-color: $color-almost-black;
    .basic-formatter__items {
      @include breakpoint($medium-up) {
        max-width: 1280px;
      }
      @include breakpoint($brandbook-max-up) {
        max-width: 1920px;
      }
      margin-left: auto;
      margin-right: auto;
    }
    .content-block5 {
      background-color: $brandbook-dark-gray;
      margin: 0px;
      &__inner {
        margin-top: 0px;
      }
      &__innerwrap {
        max-width: none;
        display: flex;
        flex-direction: column;
        @include breakpoint($medium-up) {
          display: block;
          margin: 0;
        }
        @include breakpoint($brandbook-max-up) {
          margin: 0;
        }
      }
      &.first-of-group .content-block5__innerwrap {
        margin-bottom: 0px;
      }
      &__image {
        background-color: transparent;
        padding: 0px 15px;
        @include breakpoint($medium-up) {
          padding: 0px;
        }
      }
      &__full-heading {
        margin: 0 -80px;
        &__text {
          @include text-brandbook-script-heading;
          font-style: normal;
          text-transform: none;
          max-width: 640px;
          margin-left: auto;
          margin-right: auto;
          &:before,
          &:after {
            display: none;
          }
          .about_heading_text {
            position: relative;
            top: 40px;
            z-index: 1;
          }
        }
      }
      &__box {
        flex: 0 0 auto;
        background-color: transparent;
        margin: 25px 0px;
        width: 100%;
        @include breakpoint($medium-up) {
          width: calc(50% - 15px);
          margin: 0px;
        }
        @include breakpoint($brandbook-max-up) {
          width: calc(50% - 30px);
        }
        &--left {
          order: 0;
          margin-bottom: 0px;
          @include breakpoint($medium-up) {
            margin-right: 30px;
          }
          @include breakpoint($brandbook-max-up) {
            margin-right: 60px;
          }
          .content-block5 {
            &__inner-textbox {
              position: absolute;
              top: 5%;
              .about_heading_text {
                font-family: $font--heading;
                font-size: 40px;
                margin-top: 30px;
              }
            }
            @include breakpoint($medium-up) {
              &__inner-textbox {
                position: absolute;
                left: 50%;
                top: 5%;
              }
            }
          }
        }
        &--right {
          order: 1;
          .content-block5 {
            &__inner-textbox {
              margin-top: 30px;
            }
            @include breakpoint($medium-up) {
              &__inner-textbox {
                margin-top: 20%;
              }
            }
            &__maintext {
              text-align: justify;
              margin: 20px 10px;
            }
          }
        }
      }
      &.flip-mobile .content-block5__box {
        &--left {
          order: 1;
          margin-bottom: 25px;
        }
        &--right {
          order: 0;
          margin-bottom: 0px;
        }
      }
      &.first-of-group .content-block5__box {
        margin-bottom: 0px;
      }
      &__maintext {
        margin-top: 20%;
        text-align: left;
        @include breakpoint($medium-up) {
          padding-right: 15px;
        }
      }
    }
  }
}

.intro-page {
  .basic-carousel {
    &__title {
      font-size: 12px;
      margin: 25px 0;
      width: 100%;
    }
    @include breakpoint($medium-up) {
      &__title {
        font-size: 16px;
      }
    }
    &__title-rule {
      border-top: 1px solid $color-darker-gray-hr;
      color: $color-grey;
      position: absolute;
      top: -0.5em;
      width: 100%;
      z-index: 1;
    }
    &__button {
      border: none;
      cursor: default;
      &:hover {
        background-color: $color-white;
        color: $color-black;
      }
    }
  }
  .about-carousel {
    .hero-carousel-formatter {
      margin: 0 20px;
    }
  }
  .intro-carousel,
  .about-carousel {
    .slick-next,
    .slick-prev {
      &:hover {
        color: $color-black;
      }
    }
    .tout {
      padding-top: 15%;
      margin: 0 15px;
      .tout {
        &__header {
          &--large {
            left: 75px;
            top: 20px;
            position: absolute;
          }
          @include breakpoint($medium-up) {
            &--large {
              position: absolute;
              top: -20px;
            }
          }
        }
        &__text {
          position: static;
        }
        &__copy {
          &--small {
            margin-top: 30px;
            height: 40px;
          }
        }
        &__cta {
          position: relative;
          top: -72px;
          a {
            border: 1px solid $color-grey;
            border-radius: 4px;
            cursor: pointer;
            color: $color-grey;
            font-size: 10px;
            line-height: 10px;
            padding: 8px 10px 6px;
          }
        }
      }
    }
    .basic-carousel-formatter {
      .carousel-controls {
        .slick-prev {
          left: 0;
        }
        .slick-next {
          right: 0;
        }
      }
    }
  }
  .store-address {
    .content-block5 {
      &__maintext {
        color: $color-black;
        text-align: left;
        margin-left: 25%;
        font-size: 12px;
      }
      @include breakpoint($medium-up) {
        &__maintext {
          color: $color-black;
          font-size: 16px;
          text-align: left;
        }
      }
      &__inner {
        margin-top: 0;
      }
    }
  }
  .discover-more {
    .basic-formatter {
      &__item {
        .tout {
          width: 100%;
          &__image {
            width: 28%;
            float: left;
            margin: 10px;
          }
          @include breakpoint($medium-up) {
            &__image {
              width: 30.2%;
              float: left;
              margin: 20px;
            }
          }
        }
      }
    }
    .more-url {
      position: relative;
      top: -200px;
      span {
        font-family: $font--heading;
        font-size: 45px;
        color: $color-white;
        text-transform: capitalize;
      }
      a {
        color: $color-blue;
        text-decoration: underline;
        cursor: pointer;
      }
    }
    .hero-carousel-formatter {
      .slick-next,
      .slick-prev {
        top: 35%;
        &:hover {
          color: $color-black;
        }
      }
      .content-block-carousel-slide {
        margin: 0 auto;
        width: 85%;
      }
    }
  }
  .about-header {
    .basic-formatter {
      &__header {
        text-align: center;
      }
    }
  }
  .basic-grid {
    &__item {
      .more-url {
        position: relative;
        top: -300px;
        span {
          font-family: $font--heading;
          font-size: 45px;
          color: $color-white;
          text-transform: capitalize;
        }
        a {
          color: $color-blue;
          text-decoration: underline;
          cursor: pointer;
          font-size: 14px;
        }
      }
    }
  }
}

.section-jp {
  .locate-interstitial-overlay {
    #cboxLoadedContent {
      background: $color-white;
    }
    #cboxClose {
      color: $color-black;
      position: absolute;
      top: 0;
      right: 0;
      padding: 10px;
      line-height: normal;
      font-size: 0;
    }
  }
}
