// typeahead

.esearch-nav {
  margin: 30px 0 0 0;
  @include breakpoint($medium-up) {
    @include swap_direction(margin, 50px 0 0 0);
  }
}

// moved to .search-form__fields
// .esearch-nav__form-fields {
//   font-size: 12px;
//   width: 78.125%; //250 @ 320px
//   margin: 0 0 30px 15px;
//   @include breakpoint ($medium-up) {
//     width: 100%;
//     max-width: 380px;
//     margin: 0 auto 44px auto;
//   }
// }

.esearch-nav__form-submit {
  display: none;
  &:before {
    @include icon('search');
  }
}

.esearch-product--typeahead {
  font-size: 12px;
  color: $color-black;
  padding: 0 0 22px $search-mobile-margin;
  @include breakpoint($medium-up) {
    @include swap_direction(margin, 0 1.53657% 0 0);
    max-width: 280px;
    text-align: center;
    float: #{$ldirection};
    width: 23%;
    padding: 0;
    &.last {
      margin-#{$rdirection}: 0;
    }
  }
}

.endeca-product__image__container {
  display: none;
  @include breakpoint($medium-up) {
    display: block;
    margin-bottom: 25px;
  }
}

.endeca-product__image {
  width: 100%;
}

.esearch-product {
  &__headline,
  &__subhead,
  &__price {
    font-size: 12px;
    line-height: 1.1em;
    @include breakpoint($medium-up) {
      font-size: 13px;
      line-height: 14px;
    }
  }
  &__price {
    margin-bottom: 0px;
  }
}

.esearch-product__headline,
.esearch-product__price {
  font-weight: 500;
  letter-spacing: 0.05em;
  text-transform: uppercase;
}

.esearch-product__headline,
.esearch-product__price,
.esearch-product__subhead {
  @include swap_direction(margin, 0 0 10px 0);
  @include breakpoint($medium-up) {
    @include swap_direction(margin, 0 0 10px 0);
  }
  a {
    text-decoration: none;
    border: none;
  }
}

.typeahead-wrapper {
  background: $color-white;
  .typeahead-suggestions__item {
    padding: 4px 0;
  }
  .search-suggestions__item {
    font-size: 10px;
    letter-spacing: 0.13em;
    text-transform: uppercase;
    background: $color-white;
    display: block;
    padding: 30px 0 30px $search-mobile-margin;
    @include breakpoint($small-only) {
      border: 0;
    }
    @include breakpoint($medium-up) {
      @include swap_direction(padding, 0 0 45px 0);
      font-size: 12px;
      letter-spacing: 0.13em;
      text-align: center;
      background: $color-light-silver;
    }
  }
  .search-suggestions__link {
    @include font-face--helvetica-medium;
    letter-spacing: 0.1em;
    display: inline-block;
    font-size: 12px;
    line-height: 12px;
    position: relative;
    text-transform: uppercase;
    border-bottom: 0px;
  }
}

.typeahead-wrapper {
  .product-results {
    padding: 0;
    @include breakpoint($medium-up) {
      @include pie-clearfix;
      padding: 50px;
      max-width: 1240px;
      margin: 0 auto;
    }
    > div {
      display: none;
      visibility: hidden;
      // hide all but the first 4
      &:nth-child(-n + 4) {
        display: block;
        visibility: visible;
      }
    }
  }
}
// search page header will populate this - hide in the nav
.search-page--loaded .esearch-nav__suggestions-wrapper {
  display: none !important;
}

/**
 * Search form in page content - special positioning
 * @todo audit this
 */
.site-content {
  .search-form {
    position: relative;
    .typeahead-wrapper {
      position: absolute;
      z-index: 999;
      width: 100%;
      background-color: $color-white;
      //border: $color-black solid 2px;
      //padding: 1em;
    }
  }
}
