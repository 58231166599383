$gnav-signup-max-width: 380px;

.site-email-signup {
  padding: 25px 0 0;
  max-width: $gnav-signup-max-width;
  margin: auto;
  &__title {
    text-align: center;
  }
  &__subtitle {
    text-align: center;
    font-style: italic;
  }
  &__copy {
    text-align: center;
  }
  &__messages {
    text-align: center;
  }
  &__message {
    &--error {
      color: red;
    }
    &--success {
      color: green;
    }
  }
  &__fields {
    @include pie-clearfix;
    width: 100%;
    margin: 0 auto;
  }
  &__field {
    display: block;
    width: 280px;
    margin: auto;
    margin-bottom: 1em;
    padding-bottom: 0;
  }
  input[type='email'].site-email-signup__field,
  input[type='tel'].site-email-signup__field {
    @include input-placeholder {
      color: $color-light-gray;
    }
  }
  &__terms-conditions {
    display: none;
    position: relative;
    &__checkbox,
    &__more {
      margin-bottom: 20px;
    }
    &__more a {
    }
  }
  &__submit {
    display: block;
    margin: auto;
    margin-bottom: 38px;
    min-width: 280px;
  }
  &__success {
    position: relative;
    text-align: center;
    padding: 2em 0;
    &-header {
      text-transform: uppercase;
    }
    &-offer {
      line-height: 1.5em;
      &__code {
        text-transform: uppercase;
      }
    }
    &-terms {
      margin-top: 1em;
      margin-bottom: 2em;
    }
    &-cta {
      margin-bottom: 2em;
      .button--medium {
        min-width: 172px;
      }
    }
    &-reminder {
    }
  }
}
