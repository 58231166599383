///////////////////////////////
/// HERO LARGE
/// bg image dictates container height
/// uses generic font and size classes
///////////////////////////////
.hero-large {
  height: auto;
  position: relative;
  width: 100%;
  &.hero-large--color-dark {
    background-color: $color-white;
  }
  &.hero-large--color-almost_black {
    background-color: $color-almost-black;
  }
  &.hero-large--color-dark_gray {
    background-color: $color-near-black;
  }
  &__hero-wrapper {
    position: relative;
  }
  &__inner {
    position: relative;
    width: 100%;
    @include breakpoint($landscape-up) {
      position: absolute;
      z-index: 3;
      top: 1em;
      bottom: auto;
      // content widths
      &.hero-large__inner-width--20 {
        width: 20%;
      }
      &.hero-large__inner-width--30 {
        width: 30%;
      }
      &.hero-large__inner-width--40 {
        width: 40%;
      }
      &.hero-large__inner-width--50 {
        width: 50%;
      }
      &.hero-large__inner-width--60 {
        width: 60%;
      }
      &.hero-large__inner-width--70 {
        width: 70%;
      }
      &.hero-large__inner-width--80 {
        width: 80%;
      }
      &.hero-large__inner-width--90 {
        width: 90%;
      }

      // horizontal position
      &-hpos--center {
        #{$ldirection}: 0;
        #{$rdirection}: 0;
        margin-#{$ldirection}: auto;
        margin-#{$rdirection}: auto;
      }
      &-hpos--left {
        #{$ldirection}: 0;
        // margin details
        &.hero-large__inner-margin--50 {
          #{$ldirection}: 50%;
        }
        &.hero-large__inner-margin--40 {
          #{$ldirection}: 40%;
        }
        &.hero-large__inner-margin--30 {
          #{$ldirection}: 30%;
        }
        &.hero-large__inner-margin--20 {
          #{$ldirection}: 20%;
        }
        &.hero-large__inner-margin--10 {
          #{$ldirection}: 10%;
        }
      }
      &-hpos--right {
        #{$rdirection}: 0;
        // margin details
        &.hero-large__inner-margin--50 {
          #{$rdirection}: 50%;
        }
        &.hero-large__inner-margin--40 {
          #{$rdirection}: 40%;
        }
        &.hero-large__inner-margin--30 {
          #{$rdirection}: 30%;
        }
        &.hero-large__inner-margin--20 {
          #{$rdirection}: 20%;
        }
        &.hero-large__inner-margin--10 {
          #{$rdirection}: 10%;
        }
      }
      // vertical position
      &-vpos--middle {
        height: 100%;
        top: 0;
        .hero-large__content {
          @include vertical-horizontal-center;
        }
      }
      &-vpos--top {
        top: 1em;
      }
      &-vpos--bottom {
        position: absolute;
        bottom: 1em;
        top: auto;
      }
    }
  }
  &__content {
    padding: 1em;
    @include breakpoint($landscape-up) {
      padding: 0;
    }
  }
  &__text {
    &.hero-large__text-style {
      &--brandbook_html {
        @include brandbook-html-rules;
      }
      &--brandbook_quote {
        @include text-brandbook-quote;
      }
      &--brandbook_attribution {
        @include text-brandbook-attribution;
      }
      &--brandbook_maintext {
        @include text-brandbook-maintext;
      }
    }
    margin-bottom: 10px;
    .hero-large--color-light & {
      color: $color-white;
    }
    .hero-large--color-dark & {
      color: $color-black;
    }
    .hero-large--color-almost_black & {
      color: $color-white;
    }
    .hero-large--color-dark_gray & {
      color: $color-white;
    }
    &.font--script {
      font-family: $font--heading;
      font-weight: normal;
    }
    p {
      margin: 0;
    }
  }
  &__cta {
  }
  &__image,
  &__image img {
    width: 100%;
  }
  &__image img {
    float: #{$ldirection};
  }
}

.hero-large.hero-large__style--no_image {
  .hero-large {
    &__image {
      display: none;
    }
    &__inner {
      position: static;
    }
    &__content {
      position: static;
      top: auto;
      #{$ldirection}: auto;
      transform: none;
    }
  }
}

.hero-large.hero-large__style--brandbook_home_slide {
  > .hero-large__node-below {
    display: none;
  }
  > .hero-large__hero-wrapper {
    > .hero-large__inner {
      display: none;
    }
    > .hero-large__image {
      img {
        float: none;
      }
    }
  }
  &.accordion-enabled {
    > .hero-large__hero-wrapper {
      // for whatever reason. this glyph is skinnier than caret--down/up
      @include icon(caret--right);
      &:before {
        color: rgba(255, 255, 255, 0.7);
        position: absolute;
        display: block;
        transition: transform 0.5s;
        @include transform(translate(-50%, 0%) rotate(90deg));
        #{$ldirection}: 50%;
        font-size: 30px;
        bottom: 8px;
        width: 43px; // enough space to center the glyph
        @include breakpoint($medium-up) {
          bottom: 23px;
        }
      }
    }
    &.accordion-open {
      > .hero-large__hero-wrapper {
        &:before {
          @include transform(translate(-50%, 0%) rotate(-90deg));
        }
      }
    }
  }
}

.hero-large.hero-large__style--brandbook_home_slide_with_text {
  .hero-large {
    &__image {
      img {
        float: none;
      }
    }
    &__inner {
      width: auto;
    }
    &__text {
      &.font--script {
        &.font--large {
          font-size: 35px;
          letter-spacing: 0.01em;
          @include breakpoint($medium-up) {
            font-size: 100px;
          }
          & > p:first-child:first-letter {
            @include breakpoint($medium-up) {
              font-size: 300px;
            }
          }
        }
      }
    }
    &__node-below {
      display: none;
    }
  }
}

.hero-large.hero-large__style--brandbook_text_only {
  .hero-large {
    &__image {
      display: none;
    }
    &__inner {
      position: static;
      @include breakpoint($medium-up) {
        padding: 37.5px 0px;
        max-width: 640px;
        &-hpos--center {
          #{$ldirection}: 0;
          #{$rdirection}: 0;
          margin-#{$ldirection}: auto;
          margin-#{$rdirection}: auto;
        }
      }
      @include breakpoint($brandbook-max-up) {
        padding: 75px 0px;
        max-width: 980px;
        margin-#{$ldirection}: auto;
        margin-#{$rdirection}: auto;
      }
    }
    &__text {
      margin: 25px 0px;
      @include breakpoint($medium-up) {
        margin: 25px 0px;
      }
      @include breakpoint($brandbook-max-up) {
        margin: 50px 0px;
      }
    }
    &__text p {
      margin-bottom: 1em;
    }
    &__content {
      padding: 0px 30px;
      @include breakpoint($medium-up) {
        padding: 0px;
      }
      position: static;
      top: auto;
      #{$ldirection}: auto;
      transform: none;
    }
  }
}
