///
/// collapsible-menu
/// A content block that becomes click-expandable with a title
///
.collapsible-menu {
}

.collapsible-menu-trigger {
  @include icon('plus', after);
  position: relative;
  cursor: pointer;
  &:after {
    position: absolute;
    #{$rdirection}: 0;
    font-size: 1em;
    top: 0.5em;
  }
  .collapsible-menu__parent--expanded & {
    @include icon('minus', after);
  }
}

.collapsible-sub-menu {
  display: none;
  .collapsible-menu__parent--expanded & {
    display: block;
  }
}
