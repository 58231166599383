.profile {
  &-page {
    margin: auto;
    @include breakpoint($medium-up) {
      overflow: hidden;
    }
    .profile-info,
    .optional-info,
    .privacy-policy,
    .pro-member-info,
    .newsletter-info,
    .sms-promotions,
    .sms-info {
      &__item {
        select {
          width: 32%;
        }
        input {
          width: 100%;
          border-radius: 0;
        }
      }
    }
    .optional-info {
      .birthday-fields {
        margin-bottom: 15px;
        @include breakpoint($medium-up) {
          margin-bottom: 39px;
        }
        &__content {
          text-transform: uppercase;
          font-size: 12px;
        }
      }
      .birth-month,
      .birth-day,
      .birth-year {
        margin-bottom: 14px;
        width: 100%;
        @include breakpoint($medium-up) {
          @include swap_direction(margin, 0 15px 0 0);
          width: 38%;
          float: #{$ldirection};
        }
        @include breakpoint($tablet-only) {
          @include swap_direction(margin, 0 15px 0 0);
          width: 31%;
        }
        a {
          width: 100%;
          text-transform: uppercase;
        }
      }
      .birth-day {
        @include breakpoint($medium-up) {
          width: 20%;
        }
        min-width: 0;
      }
      .birthday {
        &-title {
          font-weight: bold;
          color: $color-black;
        }
        &-program-copy {
          font-size: 80%;
          @include breakpoint($medium-up) {
            font-size: 100%;
          }
          padding: 0;
          margin: 0;
          line-height: 1;
          margin-bottom: 40px;
        }
      }
      .birth-year {
        margin-#{$rdirection}: 0;
      }
    }
    .newsletter-info {
      &__fieldset {
        margin-bottom: 25px;
      }
      &__text {
        &--new {
          margin-bottom: 40px;
          font-weight: bold;
        }
        &--provid {
          display: none;
        }
      }
      &__item {
        &--header {
          text-transform: uppercase;
          font-size: 12px;
          margin: 0 0 10px 0;
          @include breakpoint($medium-up) {
            font-size: 15px;
            margin: 0 20px;
          }
        }
        label {
          display: inline-block;
        }
      }
      &__submit {
        input {
          @include breakpoint($medium-up) {
            width: 256px;
            line-height: 30px;
            height: 30px;
          }
        }
      }
      .sms-promotions {
        @include breakpoint($medium-up) {
          @include swap_direction(margin, 27px 0 0 10px);
          float: #{$ldirection};
          width: 45%;
        }
        &__header {
          text-transform: uppercase;
          font-size: 12px;
          margin: 10px 0;
          @include breakpoint($medium-up) {
            font-size: 15px;
            margin: 20px 0;
          }
        }
        &__item {
          margin-top: 0;
          @include breakpoint($medium-up) {
            @include swap_direction(margin, 25px 0 0 2px);
          }
          label {
            .label-content {
              margin-#{$ldirection}: 1.6em;
              line-height: 22px;
              a {
                text-decoration: underline;
              }
            }
          }
        }
      }
    }
    .profile-info {
      margin-bottom: 25px;
      @include breakpoint($medium-up) {
        margin-bottom: 70px;
      }
      &__item {
        @include breakpoint($medium-up) {
          float: #{$ldirection};
          &:nth-child(odd) {
            margin-#{$ldirection}: 0;
          }
          &:nth-child(even) {
            padding-#{$rdirection}: 0;
            padding-#{$ldirection}: 13px;
          }
        }
        @include breakpoint($tablet-only) {
          width: 48%;
          @include swap_direction(margin, 4px 0 10px 18px);
        }
      }
      &__title {
        width: 100%;
        padding-#{$rdirection}: 0;
        margin-bottom: 25px;
        @include breakpoint($medium-up) {
          width: 50%;
          padding-#{$rdirection}: 13px;
        }
        .selectBox {
          width: 100%;
        }
      }
    }
    .form-submit {
      width: 100%;
      float: #{$rdirection};
      @include breakpoint($medium-up) {
        width: auto;
      }
    }
    fieldset {
      max-width: 100%;
      overflow: visible;
    }
    .profile-my-store {
      &__find-a-store {
        .selectBox-dropdown,
        select {
          width: 100%;
          margin-bottom: 10px;
          @include breakpoint($medium-up) {
            width: 32%;
          }
        }
      }
      &.account-page__section {
        @include breakpoint($medium-up) {
          border: 0;
          padding: 0;
          width: 100%;
        }
      }
    }
  }
  &-info {
    &__text {
      margin-bottom: 10px;
      line-height: normal;
    }
    &__required {
      float: #{$ldirection};
    }
    &__item {
      label {
        display: block;
      }
    }
  }
}
