.brand-book {
  position: relative;
  background-color: $color-almost-black;
  .basic-accordion-formatter__items {
    @include breakpoint($medium-up) {
      max-width: 1280px;
    }
    @include breakpoint($brandbook-max-up) {
      max-width: 1920px;
    }
    margin-#{$ldirection}: auto;
    margin-#{$rdirection}: auto;
  }
  .brand-book--header {
    background-color: $brandbook-menu-background;
    color: $color-white;
    z-index: 2;
    @include breakpoint($medium-up) {
      padding-top: 30px;
    }
    .hero-large {
      &__text {
        margin: 0px;
        font-size: 28px;
        @include breakpoint($medium-up) {
          font-size: 45px;
        }
      }
    }
  }
  &--nav {
    position: relative;
    background-color: $brandbook-menu-background;
    transition: top 0.5s ease-out;
    @include breakpoint($medium-up) {
      // on desktop we don't move things up and down
      top: 0px !important;
      display: block;
      position: relative;
    }
    .minisite-menu-hidden & {
      top: 0px !important;
    }
    &--menu-items {
      width: 100%;
      position: absolute;
      z-index: 1;
      background-color: rgba(0, 0, 0, 0.7);
      width: 100vw;
      padding: 50px 0px;
      @include transform(translateY(-100%));
      @include breakpoint($medium-up) {
        position: static;
        z-index: auto;
        background-color: transparent;
        width: auto;
        padding: 0px;
        @include transform(translateY(0%));
      }
    }
    .node-elc-nodeblock {
      text-align: center;
    }
    .basic-menu__nav-item {
      margin: 0px;
      display: block;
      margin: 0px 30px;
      border-top: 1px solid $color-menu-border-gray;
      padding: 0px;
      &:first-child {
        border-top: 0px;
      }
      @include breakpoint($medium-up) {
        line-height: 70px;
        display: inline-block;
        border-top: 0px;
        margin: 0px;
      }
      .menu__nav-item {
        @include font-face--helvetica-light;
        display: block;
        font-size: 12px;
        color: white;
        line-height: 45px;
        height: 45px;
        vertical-align: middle;
        @include breakpoint($medium-up) {
          margin: 0px;
          display: inline-block;
          padding: 0px 25px;
          line-height: 70px;
          height: 70px;
        }
      }
    }
    .menu-trigger {
      position: absolute;
      z-index: 3;
      bottom: 18px;
      #{$ldirection}: 10px;
      @include breakpoint($medium-up) {
        display: none;
      }
      .minisite-menu-active & {
        bottom: 15px;
      }
      i {
        // for whatever reason. this glyph is skinnier than caret--down/up
        @include icon(caret--right);
        &:before {
          transition: transform 0.5s;
          color: rgba(255, 255, 255, 0.7);
          font-size: 20px;
          height: 20px;
          @include transform(rotate(90deg));
          .minisite-menu-active & {
            @include transform(rotate(-90deg));
          }
        }
      }
      span {
        display: none;
      }
    }
  }
  &--unconventional {
    > .hero-large__hero-wrapper {
      .hero-large {
        &__inner {
          top: 50%;
          #{$ldirection}: calc(50%);
          @include transform(translate(0%, -50%));
          position: absolute;
        }
        &__text {
          line-height: 0.6em;
          padding-#{$ldirection}: 160px;
          & > p:first-child {
            margin-#{$ldirection}: -160px;
          }
          & > p:first-child:first-letter {
            @include breakpoint($medium-up) {
              vertical-align: -15px;
              letter-spacing: 15px;
            }
          }
        }
      }
    }
  }
  &--pillars {
    > .hero-large__hero-wrapper {
      .hero-large {
        &__inner {
          top: 13%;
          #{$ldirection}: 9%;
          position: absolute;
        }
        &__text {
          line-height: 1em;
          & > p:first-child {
            margin-#{$ldirection}: -60px;
          }
          & > p:first-child:first-letter {
            @include breakpoint($medium-up) {
              vertical-align: -15px;
            }
          }
        }
      }
    }
  }
  &--consumer {
    > .hero-large__hero-wrapper {
      .hero-large {
        &__inner {
          top: 20%;
          #{$ldirection}: auto;
          #{$rdirection}: calc(10%);
          position: absolute;
        }
        &__text {
          line-height: 1em;
          & > p:first-child {
            position: relative;
            #{$ldirection}: 270px;
            top: 90px;
          }
          & > p:first-child:first-letter {
            @include breakpoint($medium-up) {
              font-size: 100px !important;
            }
          }
          & > p:nth-child(2):first-letter {
            @include breakpoint($medium-up) {
              font-size: 300px;
              vertical-align: -15px;
              letter-spacing: 15px;
            }
          }
        }
      }
    }
  }
  &--collections {
    > .hero-large__hero-wrapper {
      .hero-large {
        &__inner {
          top: 20%;
          #{$ldirection}: calc(50% + 150px);
          position: absolute;
        }
      }
    }
  }
  &--codes {
    > .hero-large__hero-wrapper {
      .hero-large {
        &__inner {
          top: 20%;
          #{$ldirection}: calc(50% + 150px);
          position: absolute;
        }
      }
    }
  }
  &--working-culture {
    > .hero-large__hero-wrapper {
      .hero-large {
        &__inner {
          top: 20%;
          #{$ldirection}: calc(50% + 150px);
          position: absolute;
        }
      }
    }
  }
}

.brand-book--chapter {
  background-color: $color-almost-black;
  .basic-formatter__items {
    @include breakpoint($medium-up) {
      max-width: 1280px;
    }
    @include breakpoint($brandbook-max-up) {
      max-width: 1920px;
    }
    margin-#{$ldirection}: auto;
    margin-#{$rdirection}: auto;
  }
  .content-block2 {
    &__maintext {
      @include brandbook-html-rules;
    }
  }
  .custom-class-brand-book--overlay-centered {
    .content-block2 {
      &__inner {
        position: absolute;
        top: 50%;
        #{$ldirection}: 50%;
        width: calc(100% - 60px);
        height: calc(100% - 60px);
        @include transform(translate(-50%, -50%));
        background-color: rgba(0, 0, 0, 0.7);
        padding: 0px;
        &-textbox {
          width: auto;
          display: flex;
          height: 100%;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }
        @include breakpoint($medium-up) {
          width: 50%;
          height: auto;
        }
      }
      &__maintext {
        margin: 60px;
        padding: 0px;
        h2 {
          @include breakpoint($medium-up) {
            margin-bottom: 20px;
          }
        }
      }
    }
  }
  .content-block5 {
    background-color: $brandbook-dark-gray;
    margin: 0px;
    &__inner {
      margin-top: 0px;
    }
    &__innerwrap {
      max-width: none;
      display: flex;
      flex-direction: column;
      @include breakpoint($medium-up) {
        display: block;
        @include pie-clearfix;
        margin: 60px 90px;
      }
      @include breakpoint($brandbook-max-up) {
        margin: 120px 190px;
      }
    }
    &.first-of-group .content-block5__innerwrap {
      margin-bottom: 0px;
    }
    &__image {
      background-color: transparent;
      padding: 0px 15px;
      @include breakpoint($medium-up) {
        padding: 0px;
      }
      @include breakpoint($brandbook-max-up) {
      }
    }
    &__full-heading {
      &__text {
        @include text-brandbook-script-heading;
        font-style: normal;
        text-transform: none;
        // Line though text decoration
        @include line-behind-header();
        max-width: 640px;
        margin-#{$ldirection}: auto;
        margin-#{$rdirection}: auto;
      }
    }
    &__box {
      flex: 0 0 auto;
      background-color: transparent;
      margin: 25px 0px;
      width: 100%;
      @include breakpoint($medium-up) {
        width: calc(50% - 15px);
        margin: 0px;
      }
      @include breakpoint($brandbook-max-up) {
        width: calc(50% - 30px);
      }
      &--left {
        order: 0;
        margin-bottom: 0px;
        @include breakpoint($medium-up) {
          margin-#{$rdirection}: 30px;
        }
        @include breakpoint($brandbook-max-up) {
          margin-#{$rdirection}: 60px;
        }
      }
      &--right {
        order: 1;
      }
    }
    &.flip-mobile .content-block5__box {
      &--left {
        order: 1;
        margin-bottom: 25px;
      }
      &--right {
        order: 0;
        margin-bottom: 0px;
      }
    }
    &.first-of-group .content-block5__box {
      margin-bottom: 0px;
    }
    &__maintext {
      margin: 0px;
      padding: 0px 15px;
      @include breakpoint($medium-up) {
        padding: 0px;
      }
      @include brandbook-html-rules;
    }
  }
  &--brand-codes {
    background-color: $color-black;
    hr {
      color: #fff;
      padding: 0px 0px;
      margin: 50px auto;
      width: calc(100vw - 60px);
      @include breakpoint($medium-up) {
        margin: 70px auto;
        width: 500px;
      }
    }
    .tassel-candle {
      margin-bottom: 50px;
      @include breakpoint($medium-up) {
        margin-bottom: 70px;
      }
    }
    .content-block5 {
      background-color: $color-black;
      &__maintext {
        padding: 0px 30px;
        @include breakpoint($medium-up) {
          padding: 0px;
        }
        p {
          font-size: 14px;
        }
      }
      &__image {
        order: 0;
        img {
          width: auto;
          zoom: 50%;
          max-width: 100%;
        }
      }
      &.attach-to-edge {
        @include pie-clearfix;
        // get around the max-width
        position: relative;
        overflow: visible;
        @include breakpoint($extra-large-up) {
          //left: calc((100vw - 1280px) / -2 + 10px);
        }
        &--right {
          @include breakpoint($extra-large-up) {
            // non negative
            //left: calc((100vw - 1280px) / 2 + 10px);
          }
          .content-block5 {
            &__innerwrap {
              float: #{$rdirection};
            }
          }
        }
        .content-block5 {
          &__innerwrap {
            display: flex;
            flex-direction: column;
            @include breakpoint($medium-up) {
              flex-direction: row;
              margin: 0px;
              // The inner content well is 500px. We make the width
              // 500px + distance from edge of screen
              width: calc(((100vw - 500px) / 2) + 500px - 10px);

              // max width is basically width set at 1280px. max vw
              max-width: calc(((1280px - 500px) / 2) + 500px - 10px);
            }
          }
          &__box {
            float: none;
            position: relative;
            &--left {
              flex: 1 1 auto;
              width: auto;
              @include breakpoint($medium-up) {
                margin-#{$rdirection}: 10px;
              }
            }
            &--right {
              // text only
              @include breakpoint($medium-up) {
                flex: 0 0 auto;
                width: 240px;
                align-self: center;
                height: auto !important;
              }
            }
          }
        }
      }
      &.image-left {
        .content-block5 {
          &__box {
            &--left {
              display: flex;
              flex-direction: column;
              @include breakpoint($medium-up) {
              }
              .content-block5__image {
                order: 0;
              }
              .content-block5__inner {
                order: 1;
                @include breakpoint($medium-up) {
                  display: block;
                  width: 240px;
                  position: absolute;
                  top: 0px;
                  #{$rdirection}: 10px;
                  &-textbox {
                    margin: 0px;
                  }
                }
                // assume that if text exists for image block
                // that it goes within the 500px content well
              }
            }
          }
        }
      }

      // image shrinks/grows
      &.image-right {
        .content-block5 {
          &__box {
            &--left {
              // text only
              flex: 0 0 auto;
              order: 1;
              @include breakpoint($medium-up) {
                order: 0;
                width: 240px;
                align-self: center;
                height: auto !important;
              }
            }
            &--right {
              flex: 1 1 auto;
              order: 0;
              @include breakpoint($medium-up) {
                order: 1;
                width: auto;
              }
            }
          }
        }
      }
      &__maintext {
        text-align: justify;
      }
    }
    .tassle-candle {
      margin-bottom: 50px;
    }
    .content-block2 {
      &__textbox-pos {
        &--outertop {
          display: flex !important;
          flex: 0 0 auto;
          flex-direction: column;
          .content-block2__image {
            display: static;
            order: 1;
          }
          .content-block2__inner {
            display: static !important;
            order: 0;
          }
        }
      }
      &__image {
        img {
          width: auto;
          zoom: 50%;
        }
      }
      &__inner {
        text-align: #{$ldirection};
        width: 300px;
      }
      &__maintext {
        text-align: #{$ldirection};
      }
      .custom-class-tassel-block {
        align-items: flex-end;
      }
    }
  }
  &--pillars {
    .hero-large {
      &__inner {
        bottom: 0px;
        top: auto;
      }
    }
    &-foundation-wrapper {
      background-color: $brandbook-dark-gray;
      color: #fff;
      padding-bottom: 60px;
      @include breakpoint($medium-up) {
        padding-bottom: 60px;
      }
      .basic-formatter {
        &__header {
          font-size: 16px;
          text-align: center;
          padding: 30px 0px;
          p {
            margin: 0px;
          }
        }
      }
      .hero-large {
        @include breakpoint($medium-up) {
          width: calc(18.4vw);
          max-width: 235px;
        }
        @include breakpoint($brandbook-max-up) {
          max-width: 360px;
        }
        &__innerwrap {
          margin: 0px;
        }
        &__inner {
          position: absolute;
          bottom: 0px;
          color: #fff;
        }
        &__image {
          img {
            float: none;
          }
        }
        &__text {
          @include font-face--helvetica-light-oblique;
          line-height: 1.5em;
          text-align: center;
          font-size: 10px;
          @include breakpoint($medium-up) {
            font-size: 11px;
            margin-bottom: 25px;
          }
          @include breakpoint($brandbook-up) {
            font-size: 12px;
          }
          @include breakpoint($brandbook-max-up) {
            font-size: 13px;
          }
        }
      }
    }
    // block//
    &--foundation {
      margin: 0px 30px;
      @include breakpoint($medium-up) {
        margin: 0px;
        float: #{$ldirection};
        width: 18.4%;
      }
      .hero-large {
        &__text {
          font-size: 12px;
        }
      }
    }
    &--pillars {
      margin: 0px 30px;
      @include breakpoint($medium-up) {
        margin: 0px;
        float: #{$rdirection};
        width: 80.6%;
      }
      .basic-formatter {
        &__items {
          display: flex;
          flex-wrap: wrap;
          flex-direction: row;
          justify-content: space-between;
          @include breakpoint($medium-up) {
            display: flex;
            flex: 0 0 auto;
            flex-direction: row;
            justify-content: flex-end;
            flex-wrap: no-wrap;
          }
        }
        &__item:first-child {
          @include breakpoint($medium-up) {
            margin-#{$ldirection}: 0px;
          }
        }
        &__item {
          width: calc(50% - 10px);
          margin-bottom: 20px;
          @include breakpoint($medium-up) {
            float: none;
            width: 23.5%;
            margin-bottom: 0px;
            margin-#{$ldirection}: 1.5%;
          }
          br {
            @include breakpoint($medium-down) {
              display: none;
            }
          }
        }
        &__item:nth-child(2n + 2) {
          margin-#{$ldirection}: 19px;
        }
      }
    }
  }
}

.product-architecture--wrapper {
  color: $color-white;
  max-width: 1280px;
  margin-#{$ldirection}: auto;
  margin-#{$rdirection}: auto;
  position: relative;

  // border bottom
  padding-bottom: 40px;
  margin-bottom: 40px;
  @include breakpoint($medium-up) {
    padding-bottom: 50px;
    margin-bottom: 50px;
  }
  &:after {
    content: ' ';
    position: absolute;
    bottom: 0px;
    height: 1px;
    background-color: #fff;
    #{$ldirection}: 50%;
    max-width: 500px;
    // 60px = 30px mobile side padding * 2
    width: calc(100vw - 60px);
    @include transform(translateX(-50%));
  }
  .hero-large {
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    &__image {
      order: 0;
      width: auto;
      text-align: center;
      @include breakpoint($medium-up) {
        margin-bottom: 30px;
      }
      img {
        zoom: 50%;
        float: none;
        width: auto;
      }
    }
    &__inner {
      order: 1;
      position: static;
      bottom: auto;
      top: auto;
      text-align: center;
    }
  }
  .unbalanced-row {
    margin: 20px 0px;
  }
}

.unbalanced-row {
  .basic-formatter__items {
    @include breakpoint($medium-up) {
      display: flex;
      flex: 0 0 auto;
      flex-direction: row;
      justify-content: center;
      align-items: flex-end;
    }
    > div {
      float: #{$ldirection};
      width: 50%;
      @include breakpoint($medium-up) {
        float: none;
        width: 25%;
        min-width: 256px;
        max-width: 300px;
      }
    }
  }
  &.left {
    .basic-formatter__items {
      > div {
        &:first-child {
          width: 100%;
          @include breakpoint($medium-up) {
            width: 25%;
          }
          br {
            @include breakpoint($medium-down) {
              display: none;
            }
          }
        }
      }
    }
  }
  &.right {
    .basic-formatter__items {
      > div {
        // for now target last child this way
        &:last-child {
          width: 100%;
          @include breakpoint($medium-up) {
            width: 25%;
          }
          br {
            @include breakpoint($medium-down) {
              display: none;
            }
          }
        }
      }
    }
  }
}
