/* Auto-Replenishment Page UI */
.replenishment-page {
  padding: 20px;
  @include breakpoint($landscape-up) {
    padding: 10px 0 20px;
  }
  &__content {
    // Without AR Items
    .replenishment-upsell {
      text-align: center;
      margin-top: 10px;
      @include breakpoint($landscape-up) {
        text-align: left;
      }
      &__text {
        font-size: 14px;
        width: auto;
        letter-spacing: 0.075em;
        line-height: 20px;
        margin-bottom: 22px;
        @include breakpoint($landscape-up) {
          width: 70%;
        }
        a {
          text-decoration: underline;
        }
      }
      &__link {
        width: 100%;
        @include breakpoint($landscape-up) {
          width: auto;
        }
      }
    }
    .replenishment__text {
      margin-bottom: 20px;
    }
    // With AR Items
    .replenishment__products {
      .products-header {
        margin-bottom: 20px;
        border-bottom: 1px solid $color-light-gray;
        &__item {
          font-size: 16px;
          font-weight: bold;
          float: left;
          padding-bottom: 10px;
          text-transform: uppercase;
        }
        &__product {
          width: 48.5%;
        }
        &__price {
          width: 29.5%;
        }
        &__next-order {
          width: 22%;
        }
      }
    }
    .products-list__item {
      &:first-child {
        padding-top: 20px;
        @include breakpoint($landscape-up) {
          border-top: none;
          padding-top: 0;
        }
      }
      .product {
        &__frequency {
          margin-top: 5px;
          letter-spacing: 0;
          @include breakpoint($landscape-up) {
            float: left;
            width: 80%;
            margin-top: 18px;
          }
          span {
            display: block;
            margin-top: 5px;
            @include breakpoint($landscape-up) {
              display: inline;
              margin-left: 1%;
              letter-spacing: 0.11em;
            }
          }
          a {
            text-decoration: underline;
          }
        }
      }
    }
  }
  //More Info Link
  &__helplink {
    h2 {
      @include account_page_sub_header;
      font-size: 20px;
      margin: 20px 20px 10px;
      padding-bottom: 10px;
    }
    p {
      font-size: 13px;
      margin: 0 20px;
      line-height: 1.538em;
      padding-bottom: 10px;
    }
  }
  // Cancel Replenishment Popup
  &__overlay {
    .replenish-cancel-confirm {
      padding: 20px 20px 25px;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.075em;
      text-transform: uppercase;
      &__header {
        @include account_page_sub_header;
        font-size: 20px;
        margin-bottom: 10px;
        padding-bottom: 5px;
        border-bottom: 1px solid $color-light-gray;
      }
      &__link {
        text-decoration: underline;
      }
    }
  }
}
// Replenishment Details Page
.replenishment-detail-page {
  overflow: visible;
  .replenishment-page__header {
    margin-bottom: 15px;
  }
  &__content {
    font-size: 14px;
    line-height: 20px;
    padding: 20px;
    @include breakpoint($landscape-up) {
      padding: 0;
    }
    a {
      text-transform: uppercase;
      &.back-link {
        top: 33px;
      }
    }
    select,
    .select-box__label,
    .select-box__options,
    .select-box {
      width: 100%;
      min-width: 100%;
    }
    .error_messages {
      font-size: 14px;
      margin-bottom: 10px;
    }
    .product {
      a {
        text-decoration: none;
      }
    }
    .replenishment-controls {
      margin-bottom: 20px;
      &__text {
        line-height: 20px;
      }
      &__link {
        width: 100%;
        text-decoration: none;
      }
    }
    .shipping-frequency {
      padding-bottom: 20px;
      margin-bottom: 15px;
      border-bottom: 1px solid $color-light-gray;
      &__info {
        margin-bottom: 20px;
        p {
          margin-bottom: 0;
          line-height: 20px;
        }
      }
      .frequency-table {
        &__item {
          width: 100%;
          @include breakpoint($landscape-up) {
            width: 33.33%;
            float: left;
            position: relative;
            padding-bottom: 20px;
            min-height: 140px;
          }
        }
        &__link {
          display: block;
          margin: 10px 0 15px;
          @include breakpoint($landscape-up) {
            position: absolute;
            bottom: 0;
          }
        }
        &__order-info {
          font-weight: bold;
        }
        &__order-info-date {
          margin-right: 20px;
        }
        p {
          margin-bottom: 0;
        }
        .select-box {
          margin-top: 5px;
        }
      }
      p.frequency-table__order-info {
        display: inline-block;
      }
    }
    .shipping-address {
      padding-bottom: 20px;
      margin-right: 2%;
      margin-bottom: 15px;
      border-bottom: 1px solid $color-light-gray;
      @include breakpoint($landscape-up) {
        padding-bottom: 0;
        margin-bottom: 0;
        border-bottom: none;
      }
      &__dropdown {
        margin-top: 10px;
      }
      .address {
        &-container {
          padding-bottom: 10px;
        }
        &_controls__link {
          margin-bottom: 5px;
        }
        &-controls li:last-child {
          margin-top: 15px;
        }
      }
    }
    .payment-info {
      .payment-method {
        margin-top: 10px;
        @include breakpoint($landscape-up) {
          margin-top: 0;
        }
      }
      .payment-method__fieldset {
        min-width: 100%;
        max-width: 100%;
        margin-right: 0;
      }
      div.payment-info h4 {
        margin-top: 10px;
      }
      .payment-controls {
        margin-top: 10px;
        margin-bottom: 20px;
        &__list li {
          margin-bottom: 5px;
        }
        &__method {
          margin-top: 10px;
          .payment_method {
            margin-top: 10px;
          }
        }
      }
      &__submit {
        margin: 15px auto auto;
      }
    }
    section.shipping-address,
    section.payment-info {
      width: 100%;
      @include breakpoint($landscape-up) {
        width: 49%;
        float: left;
        margin-bottom: 30px;
      }
    }
    .payment-info,
    .shipping-address,
    .shipping-frequency {
      &__header {
        @include account_page_sub_header;
        margin-bottom: 5px;
      }
    }
  }
}

//Product section Styles
.replenishment-page,
.replenishment-detail-page {
  .product {
    position: relative;
    margin-bottom: 20px;
    padding-bottom: 15px;
    text-transform: uppercase;
    border-bottom: 1px solid $color-light-gray;
    &__img {
      float: left;
      width: 110px;
      height: 175px;
      margin-right: 3%;
      @include breakpoint($landscape-up) {
        width: 11.5%;
        margin-right: 2%;
        height: 150px;
      }
      img {
        width: 100%;
      }
    }
    &__info {
      @include breakpoint($landscape-up) {
        float: left;
        width: 35%;
      }
    }
    &__name {
      font-size: 14px;
      font-weight: bold;
      margin: 5px 0;
      line-height: 20px;
      letter-spacing: 0;
      @include breakpoint($landscape-up) {
        width: 50%;
      }
    }
    &__price {
      margin-top: 5px;
      @include breakpoint($landscape-up) {
        float: left;
        width: 29.5%;
      }
    }
    &__next-order-date {
      margin-top: 5px;
      @include breakpoint($landscape-up) {
        float: left;
      }
    }
  }
}
/* NO AR, PAST PURCHASES */
.replenishment-page .replenishment-top-products {
  &__header {
    font-size: 24px;
    letter-spacing: 0.06em;
    margin: 25px 0 0;
  }
  .slick-slider .replenishment-top-products__past-purchase {
    @include breakpoint($landscape-up) {
      padding-left: 10px;
    }
  }
  &__past-purchase {
    font-size: 12px;
    margin: 10px 0 25px;
    text-transform: uppercase;
    text-align: left;
    width: 100%;
    @include breakpoint($landscape-up) {
      width: 33.3%;
      float: left;
      padding-right: 10px;
    }
    .product-item {
      &__img {
        width: 120px;
        float: left;
        margin-right: 10px;
        min-height: 120px;
        @include breakpoint($landscape-up) {
          width: 40%;
        }
        img {
          width: 100%;
        }
      }
      &__price {
        &.past-purchase__price {
          display: none;
        }
      }
      &__re-order {
        display: none;
      }
      &__name {
        font-size: 18px;
        letter-spacing: 0.06em;
      }
      &__details div {
        margin-top: 5px;
      }
      &__add-to-cart {
        width: 100%;
        @include breakpoint($landscape-up) {
          width: 40%;
        }
        .product-add-favorites,
        .product-info__text {
          display: none;
        }
        a.button {
          width: 100%;
          text-align: center;
          margin-top: 10px;
        }
      }
    }
  }
  i.slick {
    &-arrow {
      font-size: 20px;
      position: absolute;
      top: 35%;
      z-index: 1;
      cursor: pointer;
      &:hover {
        text-decoration: none;
      }
    }
    &-prev {
      left: 0;
    }
    &-next {
      right: 0;
    }
    &-disabled {
      opacity: 0.3;
    }
  }
  .slick-dots li button:before {
    width: 14px;
    height: 14px;
    margin-top: 10px;
  }
  .product-brief {
    font-size: 14px;
    &__image,
    &__subline,
    &__inventory-status,
    &__quickshop-launch {
      margin-bottom: 10px;
    }
    &__name {
      padding-bottom: 10px;
    }
  }
}
