.terms-tooltip {
  bottom: 4px;
  position: relative;
  cursor: pointer;
  display: inline;
  @include breakpoint($landscape-up) {
    bottom: 7px;
  }
  .icon--information {
    width: 19px;
    margin-#{$ldirection}: 4px;
    vertical-align: middle;
  }
  .tooltiptext-under,
  .tooltiptext-over {
    visibility: hidden;
    width: 235px;
    background-color: $color-white;
    color: $color-black;
    border: 1px solid $color-darker-gray;
    padding: 10px;
    position: absolute;
    z-index: 1;
    #{$ldirection}: -108px;
    opacity: 0;
    transition: opacity 0.3s;
    height: auto;
    font-size: 12px;
    font-weight: normal;
    text-align: #{$ldirection};
    font-family: $font--text;
    letter-spacing: normal;
    line-height: 1.4;
  }
  .tooltiptext-under {
    top: 165%;
    @include breakpoint($landscape-up) {
      top: 150%;
    }
    &::after {
      content: '';
      position: absolute;
      bottom: 100%;
      #{$ldirection}: 50%;
      border-width: 5px;
      border-style: solid;
      border-color: transparent transparent $color-black transparent;
    }
  }
  .tooltiptext-over {
    @include breakpoint($landscape-up) {
      bottom: 125%;
    }
    &::before {
      content: '';
      position: absolute;
      top: 100%;
      #{$ldirection}: 17%;
      border-width: 5px;
      border-style: solid;
      border-color: $color-black transparent transparent transparent;
    }
  }
  &:hover {
    .tooltiptext-under,
    .tooltiptext-over {
      visibility: visible;
      opacity: 1;
    }
  }
}
