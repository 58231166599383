.password-reset-page {
  .password-reset__item {
    margin-top: 10px;
  }
}

.past-purchases-page__content {
  .past-purchases {
    .product-item {
      margin-bottom: 200px;
    }
  }
}
