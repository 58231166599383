@import '../../global/_variables.scss';

.waitlist {
  width: 100%;
  margin: 0 auto;
  background-size: cover;
  background-attachment: fixed;
  &__signup-block {
    text-align: center;
  }
  &__signup_text {
    width: 100%;
    text-align: #{$ldirection};
    margin: 0 auto;
    p {
      margin-bottom: 2%;
    }
    img {
      margin-bottom: 1.5em;
      width: 100%;
    }
  }
  &__terms-link {
    padding: 0.7em 0;
    .error-block {
      color: $color-red;
    }
  }
  &__promo-link {
    &.info-link-gdpr {
      display: inline-block;
      line-height: 2.4;
      margin-#{$ldirection}: 10px;
      padding-top: 5px;
    }
  }
  .form-item {
    margin-bottom: 10px;
  }
}
.email-teaser-text {
  width: 80%;
  margin: 0 auto;
  font-size: 16px;
}
.email_signup_copy {
  width: 95%;
}
.signin-block {
  &__checkboxes {
    width: 80%;
    margin: 0 auto;
    text-align: justify;
  }
  &__checkbox {
    float: #{$ldirection};
  }
}
.birth_day_container,
.birth_month_container,
.birth_year_container {
  select {
    width: 100px;
  }
}
.date_of_birth {
  &_content {
    width: auto;
    float: #{$ldirection};
    margin: 0 5px;
  }
  &_select {
    width: 100px;
  }
  &_section {
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    &_header {
      font-size: 1em;
      margin: 10px 0;
    }
  }
}
.clearer {
  clear: both;
}
.email_signup_tickbox_label {
  @include swap_direction(margin, 0 0 10px 0);
  display: block;
  position: relative;
  padding-#{$ldirection}: 23px;
  float: #{$ldirection};
}
.email_signup_tickbox_input {
  position: absolute;
  top: 2px;
  #{$ldirection}: 0;
}
.terms_all_content {
  width: 80%;
  margin: 1.5em auto;
  :hover {
    cursor: pointer;
  }
}
.terms_trigger {
  border-bottom: 1px solid $color-almost-black;
  border-top: 1px solid $color-almost-black;
  text-align: #{$ldirection};
  font-size: 1.35em;
  padding-top: 3px;
  padding-bottom: 3px;
}
.terms_arrow {
  float: #{$rdirection};
}
.terms_details {
  border-bottom: 1px solid $color-almost-black;
  padding-bottom: 10px;
  text-align: #{$ldirection};
  margin-bottom: 25px;
}
.email-optin {
  width: 100%;
  margin-top: 1.6em;
}
.submit-button {
  @include swap_direction(padding, 1em 0 0 10%);
  text-align: #{$ldirection};
  input {
    float: #{$ldirection};
  }
}
.name-field {
  width: 48%;
  input {
    width: 100%;
    height: 46px;
  }
  &-container {
    width: 80%;
    margin: 0 auto;
    div:nth-child(1) {
      float: #{$ldirection};
    }
    div:nth-child(2) {
      float: #{$rdirection};
    }
  }
}
.email-field {
  input {
    height: 46px;
  }
  .error-block {
    width: 80%;
  }
}
.tooltip {
  float: #{$ldirection};
  width: 50%;
  margin: 1%;
  margin-#{$ldirection}: 1.5%;
}
.tooltiptext-over p {
  color: $color-almost-black;
}
.waitlist_signup {
  padding-bottom: 1%;
  width: 55%;
  margin: 0 auto;
  &.field {
    color: $color-almost-black;
  }
}
.email_signup_copy p {
  margin-#{$ldirection}: 3%;
}
.form-submit {
  font-weight: 800;
}
.title-text-article,
.email-teaser-text {
  width: 44%;
  margin: 0 auto;
}
@media all and (min-width: $device-mobile-breakpoint) {
  .name-field {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    &.form-item {
      width: 48%;
    }
    &-container {
      display: flex;
      justify-content: space-between;
    }
  }
}
@media all and (max-width: $device-mobile-breakpoint) {
  .name-field {
    width: 100%;
    input {
      width: 100%;
      height: 40px;
    }
    &-container {
      div {
        float: initial;
      }
    }
  }
  .tooltip {
    width: 95%;
    margin-top: 3%;
  }
  .email-field input {
    height: 40px;
  }
  .email_signup_copy p {
    margin-#{$ldirection}: 8%;
  }
  .waitlist {
    &_signup {
      width: 85%;
    }
    &__signup_text p {
      margin-bottom: 4%;
    }
  }
  .submit-button input {
    margin-top: 2%;
    margin-bottom: 3%;
  }
  .waitlist_signup {
    padding-bottom: 9%;
  }
  .form-item {
    margin-bottom: 1em;
  }
  .error-block {
    clear: both;
    width: 100%;
    min-height: 4em;
  }
  .title-text-article,
  .email-teaser-text {
    width: 68%;
  }
}
#terms_details {
  display: none;
}
#terms_arrow_2 {
  display: none;
}
#waitlist_signup--field--PC_EMAIL_ADDRESS {
  width: 80%;
}
.error {
  color: $color-red;
}
.error-block {
  clear: both;
  width: 100%;
  min-height: 2em;
  text-align: #{$ldirection};
  margin: 0 auto;
  line-height: 0.9;
  &.birth-date {
    text-align: center;
    min-height: unset;
  }
}
.success {
  :last-child {
    margin-bottom: 20px;
  }
  &__image {
    width: 100%;
    margin-bottom: 1em;
  }
}
.progress {
  &__invisible {
    visibility: hidden;
  }
}
