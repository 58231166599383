.footer-icons {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .payment-provider-icons {
    display: flex;
    align-items: center;
    .payment-provider-icon {
      width: 50px;
    }
  }
}
