.gnav-util {
  &--cart {
    @include breakpoint($medium-up) {
      position: relative;
    }
  }
  &__content {
    &--cart {
      position: absolute;
      background-color: $color-white;
      width: 330px;
      #{$ldirection}: auto;
      max-height: 450px;
      bottom: 0;
      text-transform: uppercase;
      top: 50px;
      z-index: 999;
      #{$rdirection}: 0;
      display: block;
      @include breakpoint($medium-up) {
        max-width: 330px;
        top: 120px;
      }
      .cart-block {
        width: auto;
        padding: 0;
        &__title {
          font-size: 14px;
          line-height: 1.71429;
          display: inline;
          font-weight: bold;
        }
        &__item-count {
          &.cart-block__item-count--singular,
          &.cart-block__item-count--plural {
            display: none;
          }
        }
        &__header--edit {
          border-bottom: solid 1px $color-black;
          height: auto;
          float: #{$rdirection};
          padding: 0;
          margin-#{$rdirection}: 40px;
          text-transform: none;
          line-height: 1;
          margin-top: 5px;
          font-size: 14px;
          margin-#{$rdirection}: 30px;
        }
      }
      .cart-item {
        &__info-container {
          overflow: hidden;
          width: 63%;
          padding-#{$ldirection}: 2%;
          line-height: 1;
          padding-top: 2px;
        }
        &__info,
        &__cart-details {
          width: auto;
          margin: 0;
          text-align: #{$ldirection};
        }
        &__cart-details {
          width: auto;
          text-align: #{$ldirection};
          clear: #{$ldirection};
          text-transform: none;
        }
        &__img {
          width: 37%;
          margin: 0;
          text-align: #{$ldirection};
        }
      }
      .cart-item-kit {
        &__subitem-container {
          text-transform: none;
          font-size: 13px;
        }
      }
      .gnav-util {
        &__content-close {
          position: absolute;
          #{$rdirection}: 20px;
          padding: 0;
          font-size: 16px;
          font-weight: bold;
          height: auto;
          line-height: 1;
          top: 16px;
        }
        &__content__inner {
          background: $color-white;
          padding: 14px 20px 19px;
          font-size: 14px;
          line-height: 1.71429;
          .cart-block__content {
            padding: 0;
            .cart-item {
              padding: 0;
              height: auto;
              margin-top: 30px;
              border: 0;
              &:first-child {
                margin-top: 0;
              }
            }
          }
        }
      }
    }
  }
}

.cart-block {
  padding: 40px 0 0;
  @include breakpoint($landscape-up) {
    width: 450px;
  }
  &__content {
    padding: 0 2em 2em;
  }
  &__close {
    position: absolute;
    top: 0;
    #{$rdirection}: 0;
    cursor: pointer;
    padding: 0.25em;
  }
  &__header {
    @include pie-clearfix;
    border-bottom: solid 1px $color-silver;
    padding: 0 0 15px 0;
    margin-bottom: 30px;
    &__item-count {
      float: #{$rdirection};
    }
  }
  &__title {
    float: #{$ldirection};
    margin: 0;
    padding: 0;
    color: $color-black;
    &__text {
      display: none;
      &--empty {
        display: inline-block;
        .cart-block--nonempty &,
        .cart-block--added & {
          display: none;
          font-size: 13px;
        }
      }
      &--nonempty {
        .cart-block--nonempty & {
          display: inline-block;
          font-size: 13px;
        }
      }
      &--added {
        .cart-block--added & {
          display: inline-block;
          font-size: 13px;
        }
      }
    }
  }
  .cart-item {
    &__info__header,
    &__subheading,
    &__title,
    &__sku-label {
      @include swap_direction(margin, 15px 0 0 0);
      font-size: 13px;
      line-height: 1;
      text-transform: none;
    }
    &__title {
      font-weight: normal;
    }
    &__qty,
    &__price {
      @include swap_direction(margin, 20px 0 0 0);
      font-size: 13px;
      width: 100%;
      text-transform: none;
    }
    &__info__header,
    &__subheading {
      margin-top: 0;
    }
    &__subheading {
      font-weight: bold;
    }
  }
  &__item-count {
    color: $color-dark-gray;
    font-size: 1.25em;
    display: none;
    .cart-block--singular &--singular {
      display: block;
    }
    .cart-block--plural &--plural {
      display: block;
    }
    .cart-block--empty & {
      display: none;
    }
    .cart-block--added & {
      display: none;
    }
  }
  &__items {
    padding-bottom: 2em;
    // In the "new" state, only show the items that were just added:
    &--new {
      display: none;
      .cart-block--added & {
        display: block;
      }
    }
    .cart-block--added &--all {
      display: none;
    }
    &__view-bag {
      display: none;
      padding: 1em 0 2em;
    }
  }
  &__footer {
    border-top: solid 1px $color-silver;
    text-align: center;
    &__summary {
      @include pie-clearfix;
      display: none;
      margin: 0 0 8px;
      .cart-block--nonempty &,
      .cart-block--added & {
        display: block;
        padding-top: 18px;
        padding-bottom: 11px;
        height: auto;
      }
    }
    &__item-count {
      float: #{$ldirection};
      // Intentionally supressing item count in footer, see ORNF-122
      display: none;
      .cart-block--plural & {
        display: none;
      }
    }
    &__subtotal {
      color: $color-black;
      display: block;
      text-align: #{$rdirection};
      text-transform: capitalize;
      font-weight: bold;
      &__label {
        display: inline;
        font-size: 13px;
      }
      &__value {
        display: inline;
        font-size: 13px;
        margin-#{$ldirection}: 5px;
      }
      .cart-block--added & {
        display: none;
      }
    }
    &__button {
      border-bottom: none;
      width: 100%;
      &--checkout {
        display: none;
        padding: 14px 0;
        height: auto;
        .cart-block--nonempty &,
        .cart-block--added & {
          display: block;
          padding: 11px 0;
          height: auto;
        }
      }
      &--continue-shopping {
        .cart-block--nonempty &,
        .cart-block--added & {
          display: none;
        }
      }
      &--delivery-container {
        padding: 0;
        font-size: 12px;
        height: auto;
        margin-top: 14px;
        color: $color-black;
      }
    }
  }
  & &__footer__button--account {
    padding: 11px 0;
    margin-top: 17px;
    height: auto;
    border: solid 1px $color-black;
  }
  & &__footer__button--delivery {
    font-size: 12px;
  }
}
