
%button {
  @include button;
  &--inverted {
    @include button-inverted;
  }
}

@mixin button-secondary {
  background: $color-white;
  color: $color-black;
  border-color: $color-button-dark-border;
  &:hover {
    color: $color-button-light-gray;
    border-color: $color-button-dark-border;
    background: $color-button-dark-fill;
  }
  &--inverted {
    background: $color-black;
    color: $color-button-light-gray;
    border-color: $color-button-gray;
    &:hover {
      background: $color-button-light-gray;
      color: $color-black;
      border-color: $color-medium-stone-gray;
    }
  }
}

%button-secondary {
  @include button;
  @include button-secondary;
}

%button-secondary-inverted {
  background-color: transparent;
  color: $color-button-light-gray;
  border: 1px solid $color-button-gray;
  &:hover {
    background-color: $color-button-light-gray !important;
    color: $color-black;
    border: 1px solid $color-button-light-gray;
  }
}

// Standard Button
.button {
  @extend %button;
  &--inverted {
    @extend %button--inverted;
    &.button--disabled {
      color: $color-stone-gray;
      background: $color-light-stone-gray;
    }
  }
  &--disabled {
    background: $color-silver;
    color: $color-white;
    border-color: $color-silver;
    &:hover {
      background: $color-silver;
      color: $color-white;
      border-color: $color-silver;
    }
  }
}

// Secondary Button with outline style
.button-secondary {
  @extend %button-secondary;
  &--inverted {
    @extend %button-secondary--inverted;
  }
}

// Span 100% full width
.button,
.button-secondary {
  &--wide {
    width: 100%;
  }
}

///
/// Form Buttons
///
button,
input[type='submit'],
input[type='reset'] {
  @include button;
  @include appearance(none);
  border-radius: 0;
  border-width: 1px;
  vertical-align: middle;
  padding: 13px 15px;
  @include breakpoint($medium-up) {
    padding-top: 12px;
    padding-bottom: 12px;
  }
  &.button--inverted {
    @extend %button--inverted;
  }
  &[disabled],
  &.button--disabled {
    @extend .button--disabled;
  }
}

button {
  padding-top: 11px;
  padding-bottom: 11px;
  @include breakpoint($medium-up) {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

///
/// Links
///
@mixin link {
  @include tracking(100);
  font-size: 12px;
  font-weight: 500;
  color: $color-black;
  border-bottom: 1px solid $color-black;
  text-decoration: none;
  text-transform: uppercase;
  padding-bottom: 2px;
  transition: color $transition-duration $transition-effect,
    border-color $transition-duration $transition-effect;
  cursor: pointer;
  &:hover {
    color: $color-stone-gray;
    border-color: $color-stone-gray;
    text-decoration: none;
  }
  @include breakpoint($medium-up) {
    font-size: 14px;
  }
}
%link {
  @include link;
}

.link {
  @extend %link;
  &--inverted {
    @include font-smoothing;
    color: $color-white;
    border-color: $color-white;
    &:hover {
      color: $color-white;
    }
  }
}

#cboxClose {
  @include breakpoint($medium-up) {
    top: 16px;
    #{$rdirection}: 19px;
    padding: 0;
  }
}

.button-icon {
  display: inline-block;
  width: 60px;
  height: 60px;
  @include font-face--helvetica-roman;
  color: #999;
  border: 1px solid #ccc;
  &__inner {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    width: 60px;
    height: 60px;
    span {
      @include tracking(130);
      font-size: 9px;
      line-height: 12px;
      display: block;
      text-transform: uppercase;
      vertical-align: bottom;
    }
    .icon {
      transition: color 0.3s ease-in-out;
    }
  }
  &--inverted {
    &:hover {
      border-color: #6b6b6b;
      color: #6b6b6b;
      text-decoration: none;
      .icon {
        color: #6b6b6b;
      }
    }
    color: #999;
    background-color: rgba(0, 0, 0, 0.85);
    border: 1px solid #666;
    .icon {
      color: #999;
    }
  }
  &--play {
    .icon {
      font-size: 14px;
      margin-bottom: 8px;
    }
  }
  &--fullscreen {
    .icon {
      font-size: 14px;
      margin-bottom: 2px;
    }
    span {
    }
  }
}
