// common tout styles
.tout {
  height: 100%;
  position: relative;
  .tout__text {
    width: 100%;
    position: absolute;
    &--top-center {
      top: 0;
      #{$ldirection}: 0;
      #{$rdirection}: 0;
      margin-#{$ldirection}: auto;
      margin-#{$rdirection}: auto;
      text-align: center;
    }
    &--bottom-center {
      bottom: 0;
      #{$ldirection}: 0;
      #{$rdirection}: 0;
      margin-#{$ldirection}: auto;
      margin-#{$rdirection}: auto;
      text-align: center;
    }
  }
  .tout__text-inner {
    padding: 20px;
    text-align: center;
  }
  .tout__header {
    text-transform: uppercase;
    letter-spacing: 0.15em;
    &--large {
      @include line-behind-text();
      @include h2;
      line-height: 1;
    }
    &--medium {
      @include line-behind-text();
      @include h3;
      line-height: 1;
      padding-top: 5px;
      @include breakpoint($medium-up) {
        font-size: 14px;
      }
    }
    &--small {
      @include h6;
      line-height: 1;
    }
  }
  .tout__copy {
    letter-spacing: 0.06em;
    &--large {
      @include t1;
      @include breakpoint($medium-up) {
        line-height: 20px;
      }
    }
    &--medium {
      font-size: 11px;
      @include breakpoint($medium-up) {
        font-size: 13px;
        line-height: 18px;
      }
    }
    &--small {
      @include t3;
      @include breakpoint($medium-up) {
        line-height: 15px;
      }
    }
  }
  p {
    padding: 0;
    margin: 0;
    line-height: inherit;
  }
}
