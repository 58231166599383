/*** Millenial landing page ***/

.millenials-landing {
  background: $color-black;
  &__banner {
    min-height: 100%;
    background: none;
    position: relative;
    @include breakpoint($tablet-only) {
      background-position: center center;
    }
    @include breakpoint($medium-up) {
      min-height: 260px;
      background-repeat: no-repeat;
      background-position: 0 0;
      background-size: cover;
    }
    &--text {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin: auto;
      padding: 30px 0;
      @include breakpoint($tablet-only) {
        width: 300px;
      }
      @include breakpoint($medium-up) {
        width: 500px;
        position: absolute;
        top: 0;
        #{$ldirection}: 30%;
        bottom: 0;
        #{$rdirection}: 0;
        padding: 0;
      }
      h3 {
        margin: 0;
        font-family: $font--strong;
        font-size: 32px;
        color: $color-white;
        text-transform: uppercase;
        letter-spacing: 4px;
        margin-bottom: 12px;
        span {
          color: $color-near-solid-red;
        }
      }
      p {
        margin: 0;
        color: $color-button-light-gray;
        font-size: 12px;
        text-align: center;
        line-height: 16px;
        letter-spacing: 2px;
        width: 300px;
        @include breakpoint($medium-up) {
          text-transform: uppercase;
          line-height: 20px;
          width: 100%;
        }
      }
    }
  }
  &__choose {
    h4 {
      margin: 0;
      padding: 0 0 10px;
      color: $color-white;
      text-align: center;
      background: transparent;
      cursor: pointer;
      font-size: 14px;
      letter-spacing: 1px;
      font-weight: normal;
      text-transform: uppercase;
      @include breakpoint($medium-up) {
        background: $color-almost-black;
        padding: 12px 0;
        text-transform: none;
      }
      span {
        margin-#{$ldirection}: 5px;
        display: inline-block;
        &.active {
          transform: rotate(180deg);
          line-height: 0;
        }
        img {
          width: 12px;
          position: relative;
          bottom: 7px;
        }
      }
    }
    &--filter {
      text-align: center;
      padding-top: 25px;
      button {
        cursor: pointer;
        padding: 10px 30px;
        background: transparent;
        border: 1px solid $color-near-solid-red;
        border-radius: 20px;
        color: $color-near-solid-white;
        text-transform: uppercase;
        font-size: 8px;
        letter-spacing: 1px;
        margin: 0 8px 18px;
        transition: all ease-in 0.35s;
        position: relative;
        @include breakpoint($medium-up) {
          margin-bottom: 0;
        }
        i {
          width: 15px;
          height: 12px;
          background-repeat: no-repeat;
          background-size: 70%;
          position: absolute;
          #{$rdirection}: 8px;
          top: 12px;
          display: none;
        }
        &.active {
          background: $color-near-solid-red;
          i {
            display: block;
          }
        }
        &:hover {
          background: $color-white;
          color: $color-black;
        }
        &:focus {
          outline: none;
        }
      }
    }
  }
  &__grid {
    width: 100%;
    margin: 0 auto;
    background: $color-black;
    padding-#{$ldirection}: 0;
    padding-top: 40px;
    @include breakpoint($medium-up) {
      width: 980px;
      padding-#{$ldirection}: 20px;
    }
    @include breakpoint($tablet-only) {
      width: 768px;
      padding-#{$ldirection}: 15px;
      padding-top: 25px;
    }
    &--tile {
      padding-#{$rdirection}: 20px;
      padding-bottom: 20px;
      padding-#{$ldirection}: 20px;
      @include breakpoint($medium-up) {
        padding-#{$ldirection}: 0;
      }
      @include breakpoint($tablet-only) {
        padding-#{$rdirection}: 15px;
        padding-bottom: 15px;
      }
      img {
        width: 100%;
        display: block;
      }
    }
    &--icon {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      position: absolute;
      top: 0;
      bottom: 20px;
      #{$ldirection}: 0;
      #{$rdirection}: 20px;
      img {
        width: 45px;
        height: 45px;
        cursor: pointer;
        margin: auto;
      }
      &__overlay {
        background: transparent;
        opacity: 1;
        text-align: center;
        transition: 0.35s ease-in;
        cursor: pointer;
        bottom: 30px;
        top: auto;
        #{$ldirection}: auto;
        #{$rdirection}: 30px;
        &:hover {
          opacity: 0.8;
        }
        a {
          color: $color-white;
          width: 100%;
          text-decoration: none;
          font-family: $font--strong;
          font-size: 14px;
          letter-spacing: 2px;
          border: none;
          text-transform: uppercase;
          display: block;
          span {
            display: none;
            @include breakpoint($large-up) {
              margin-top: 10px;
              display: block;
            }
          }
        }
        @include breakpoint($large-up) {
          background: $color-black;
          opacity: 0;
          bottom: 0;
          top: 0;
          #{$ldirection}: 0;
          #{$rdirection}: 0;
        }
      }
    }
  }
  .tile-col {
    &--large {
      width: 100%;
      @include breakpoint($medium-up) {
        width: 480px;
        &.tile-col {
          &--half {
            width: 480px;
          }
        }
      }
      @include breakpoint($tablet-only) {
        width: 376px;
        &.tile-col {
          &--half {
            width: 376px;
          }
        }
      }
    }
    &--small {
      width: 100%;
      @include breakpoint($medium-up) {
        width: 240px;
        &.tile-col {
          &--half {
            width: 240px;
          }
        }
      }
      @include breakpoint($tablet-only) {
        width: 188px;
        &.tile-col {
          &--half {
            width: 188px;
          }
        }
      }
    }
    &--height {
      height: auto;
      @include breakpoint($medium-up) {
        height: 250px;
      }
      @include breakpoint($tablet-only) {
        height: 220px;
      }
    }
    &--half {
      width: 50%;
      float: $ldirection;
      @include breakpoint($medium-up) {
        float: none;
      }
    }
    &--right {
      padding-#{$ldirection}: 10px;
      @include breakpoint($medium-up) {
        padding-#{$ldirection}: 0;
      }
    }
    &--left {
      padding-#{$rdirection}: 10px;
      @include breakpoint($medium-up) {
        padding-#{$rdirection}: 20px;
      }
      @include breakpoint($tablet-only) {
        padding-#{$rdirection}: 15px;
      }
    }
    &--tiny {
      padding-#{$ldirection}: 80px;
      padding-#{$rdirection}: 80px;
      @include breakpoint($medium-up) {
        padding-#{$rdirection}: 20px;
        padding-#{$ldirection}: 0;
      }
      @include breakpoint($tablet-only) {
        padding-#{$rdirection}: 15px;
      }
    }
    &--fixed {
      z-index: 9;
    }
  }
  &__loader {
    text-align: center;
    padding: 30px 0 50px;
    display: none;
    width: 200px;
    margin: auto;
    img {
      width: 65px;
    }
    p {
      color: $color-white;
      text-transform: uppercase;
    }
  }
}

/*** Shoppable video ***/

.millennial-shoppable {
  background: $color-black;
  color: $color-dark-gray;
  text-align: center;
  padding-top: 30px;
  h1 {
    color: $color-white;
    font-family: $font--strong;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin: 0;
    padding: 0 20px 20px;
    font-size: 28px;
    line-height: 32px;
    @include breakpoint($medium-up) {
      padding: 0 0 35px;
    }
  }
  h2 {
    color: $color-white;
    font-family: $font--strong;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin: 0;
    padding-bottom: 15px;
    font-size: 24px;
    line-height: 28px;
  }
  p {
    color: $color-gray;
    margin: 0;
    padding-bottom: 25px;
    font-size: 14px;
    line-height: 20px;
    @include breakpoint($medium-up) {
      font-size: 13px;
      line-height: 24px;
    }
  }
  .carousel {
    &-controls {
      .slick {
        &-prev {
          #{$ldirection}: 0;
          &:before {
            #{$ldirection}: 0;
            font-size: 36px;
            color: $color-white;
            opacity: 0.3;
          }
        }
        &-next {
          #{$rdirection}: 0;
          &:before {
            #{$rdirection}: 0;
            font-size: 36px;
            color: $color-white;
            opacity: 0.3;
          }
        }
      }
    }
  }
  .product {
    &-brief {
      &__container {
        cursor: pointer;
        padding-#{$ldirection}: 18px;
        padding-#{$rdirection}: 18px;
        @include breakpoint($medium-up) {
          padding-#{$ldirection}: 10px;
          padding-#{$rdirection}: 10px;
        }
        &:hover {
          .product-brief__quickshop-launch-wrapper {
            a {
              color: $color-white;
              border-color: $color-white;
            }
          }
        }
        .product {
          &-brief {
            &__quickshop {
              &-launch {
                &-wrapper {
                  margin: 0;
                  padding-bottom: 20px;
                  a {
                    color: $color-white;
                    font-size: 10px;
                    line-height: 16px;
                  }
                }
              }
            }
            &__name {
              margin: 0;
              padding-bottom: 10px;
              a {
                color: $color-white;
                font-weight: normal;
                font-size: 12px;
                line-height: 18px;
              }
            }
            &__price {
              color: $color-white;
              margin: 0;
              padding-bottom: 20px;
              font-weight: normal;
            }
          }
        }
      }
    }
    &-rating {
      color: $color-white;
      font-size: 10px;
      line-height: 16px;
      @include breakpoint($medium-up) {
        font-size: 12px;
        line-height: 18px;
      }
    }
    &-grid {
      .carousel {
        &-dots {
          display: none;
        }
      }
      &.product {
        &-grid {
          .product {
            &-grid {
              &__content {
                margin-bottom: 60px;
              }
            }
          }
          &--carousel {
            .product {
              &__image {
                &-link {
                  padding-bottom: 20px;
                  margin-bottom: 0;
                  @include breakpoint($medium-up) {
                    padding-bottom: 40px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  &__content {
    max-width: 1280px;
    margin: 0 auto;
  }
  &__description {
    width: 100%;
    padding: 0 20px 20px;
    @include breakpoint($medium-up) {
      width: 500px;
      margin: 0 auto;
      padding-bottom: 30px;
    }
    a {
      border: 0;
      color: $color-white;
      font-family: impact;
      font-size: 15px;
      line-height: 26px;
      padding: 11px 33px;
      background: $color-near-red;
      margin-bottom: 10px;
      text-transform: uppercase;
      letter-spacing: 0.16em;
      display: inline-block;
      @include breakpoint($medium-up) {
        font-size: 16px;
        margin-bottom: 30px;
      }
      &:hover {
        background: $color-white;
        color: $color-black;
      }
    }
  }
  &__category {
    position: relative;
    margin: 0 20px 30px;
    @include breakpoint($medium-up) {
      margin: 0 auto 50px;
    }
    &:before {
      content: '';
      background: $color-darker-gray;
      position: absolute;
      #{$ldirection}: 0;
      top: 50%;
      width: 100%;
      height: 1px;
      transform: translateY(-50%);
    }
    p {
      display: inline-block;
      padding: 10px 5px;
      background: $color-black;
      z-index: 1;
      position: relative;
      font-size: 16px;
      line-height: 22px;
      letter-spacing: 2px;
      text-transform: uppercase;
      margin: 0 15px;
      @include breakpoint($medium-up) {
        font-size: 18px;
        line-height: 26px;
        padding: 20px;
        margin: 0;
      }
    }
  }
  .content {
    &-block1 {
      &-video {
        position: relative;
        margin-bottom: 20px;
        @include breakpoint($medium-up) {
          margin-bottom: 60px;
        }
      }
      &--has {
        &-videoplayer {
          .content {
            &-block1 {
              &__play {
                &-icon {
                  display: block;
                  #{$ldirection}: 50%;
                  transform: translate(-50%, -50%);
                  top: 50%;
                }
              }
            }
          }
        }
      }
    }
  }
}

/*** MPP section ***/

.millennial-mpp__section {
  .content {
    &-block1,
    &-block2 {
      &__heading {
        font-family: $font--strong;
        font-size: 30px;
        text-transform: uppercase;
        margin: 0;
        letter-spacing: 3px;
        line-height: 30px;
        span {
          color: $color-alert-red;
        }
      }
      &__subheading {
        margin: 0;
        &-text {
          font-size: 12px;
          line-height: 20px;
          margin: 20px 0;
          max-width: 650px;
        }
      }
      &__button {
        margin: 0;
      }
      &__textbox-pos--bottom {
        .content-block1,
        .content-block2 {
          &__inner {
            padding-top: 25px;
            padding-bottom: 25px;
          }
        }
      }
      &__image {
        height: auto;
        min-height: auto;
        img {
          position: static;
          transform: none;
          width: 100%;
        }
      }
    }
  }
  .product-grid__tout {
    .tout {
      &__text-inner {
        padding: 6px 12px;
        @include breakpoint($medium-up) {
          padding: 20px 12px;
        }
      }
      &__text--bottom-center {
        position: static;
      }
      &__cta {
        a {
          display: block;
          border: 1px solid $color-darker-gray;
          color: $color-light-gray-arrow;
          padding: 12px 5px;
          font-family: $font--strong;
          font-size: 16px;
          line-height: 22px;
          letter-spacing: 4px;
          @include breakpoint($medium-up) {
            display: inline-block;
            padding: 12px 28px;
          }
        }
      }
    }
  }
}

/*** SPP section ***/

.millenial {
  &-spp {
    .sticky-add-to-bag {
      .button {
        background: $color-light-dark-red;
        border: 0;
        color: $color-white;
        font-family: $font--strong;
        font-size: 16px;
        font-weight: normal;
        line-height: 22px;
      }
    }
    .product {
      &-full {
        &__name {
          font-family: $font--strong;
          text-transform: uppercase;
          color: $color-alert-red;
          font-size: 50px;
          line-height: 56px;
        }
        &__header {
          margin-bottom: 4px;
          @include breakpoint($medium-up) {
            margin-bottom: 8px;
          }
        }
        &__short {
          &-desc {
            margin-bottom: 10px;
            @include breakpoint($medium-up) {
              margin-bottom: 12px;
            }
          }
        }
        &__price {
          margin-bottom: 30px;
          @include breakpoint($medium-up) {
            margin-bottom: 22px;
          }
        }
        &__buttons {
          .button {
            background: $color-light-dark-red;
            border: 0;
            color: $color-white;
            font-family: $font--strong;
            font-size: 16px;
            font-weight: normal;
            line-height: 22px;
          }
        }
      }
    }
    &__video {
      .button {
        &-icon {
          &--play {
            .icon {
              @include swap_direction(padding, 6px 0 8px 12px);
              font-size: 40px;
              line-height: 46px;
              margin: 0;
              border: 7px solid $color-light-dark-red;
              border-radius: 50%;
            }
          }
          &--inverted {
            border: none;
            background: none;
          }
          &__inner {
            span {
              display: none;
            }
          }
        }
      }
      .content {
        &-block1 {
          &__image {
            text-align: center;
            height: auto;
            max-width: 1280px;
            min-height: auto;
            margin: 0 auto 26px;
            @include breakpoint($medium-up) {
              margin-bottom: 42px;
            }
            img {
              position: static;
              transform: none;
              width: 100%;
            }
            &-link {
              margin-bottom: 50px;
            }
          }
          &--has {
            &-videoplayer {
              .content {
                &-block1 {
                  &__play {
                    &-icon {
                      display: block;
                      #{$ldirection}: 50%;
                      text-align: center;
                      transform: translate(-50%, -50%);
                      top: 50%;
                    }
                  }
                }
              }
              .js-play {
                .icon {
                  color: $color-light-dark-red;
                }
                &:hover {
                  transform: none;
                }
              }
            }
          }
        }
      }
    }
    &__thanks {
      .basic {
        &-formatter {
          &__item {
            color: $color-white;
          }
          &__items {
            background: $color-black;
          }
        }
        &-responsive {
          background: $color-black;
          position: relative;
          margin: 0 15px 12px;
          max-width: 1200px;
          text-align: center;
          @include breakpoint($medium-up) {
            margin: 0 auto 29px;
          }
          &:before {
            content: '';
            background: $color-darker-gray;
            position: absolute;
            #{$ldirection}: 0;
            top: 50%;
            width: 100%;
            height: 1px;
            transform: translateY(-50%);
          }
          h1 {
            color: $color-white;
            display: inline-block;
            padding: 10px 15px;
            background: $color-black;
            z-index: 1;
            position: relative;
            font-family: $font--strong;
            font-size: 25px;
            line-height: 31px;
            letter-spacing: 2px;
            text-transform: uppercase;
            margin: 0;
            @include breakpoint($medium-up) {
              padding: 20px;
              font-size: 30px;
              line-height: 36px;
            }
          }
        }
      }
    }
    &__campaign {
      .basic {
        &-carousel {
          max-width: 966px;
          margin: 0 auto;
          color: $color-white;
          position: relative;
          &-formatter {
            .basic {
              &-carousel {
                &__title {
                  &-rule {
                    display: none;
                  }
                  &-text {
                    background: none;
                    font-family: $font--strong;
                    text-transform: uppercase;
                    font-size: 25px;
                    line-height: 31px;
                    margin-bottom: 10px;
                    letter-spacing: 3px;
                    @include breakpoint($medium-up) {
                      margin-bottom: 15px;
                      font-size: 30px;
                      line-height: 36px;
                    }
                  }
                }
                &__subtitle {
                  margin-top: 0;
                  margin-bottom: 20px;
                  @include breakpoint($medium-up) {
                    margin-bottom: 26px;
                  }
                  p {
                    font-size: 12px;
                    line-height: 18px;
                    letter-spacing: 1px;
                    @include breakpoint($medium-up) {
                      font-size: 14px;
                      line-height: 20px;
                    }
                  }
                }
                &__header {
                  &-text {
                    color: $color-white;
                  }
                }
                &__button {
                  letter-spacing: 0.16em;
                  border: 1px solid $color-white;
                  font-size: 14px;
                  font-family: $font--strong;
                  line-height: 20px;
                  padding: 12px 35px 11px 35px;
                  text-transform: uppercase;
                  display: inline-block;
                  text-decoration: none;
                  text-align: center;
                  cursor: pointer;
                  color: $color-white;
                  background: none;
                  margin-bottom: 26px;
                  width: 100%;
                  @include breakpoint($medium-up) {
                    font-size: 16px;
                    line-height: 22px;
                    width: auto;
                  }
                  &:hover {
                    border: 1px solid $color-white;
                    background: none;
                    color: $color-white;
                  }
                }
                &__slide {
                  padding: 0 35px;
                  margin-bottom: 70px;
                  @include breakpoint($medium-up) {
                    padding: 0 45px;
                  }
                }
              }
            }
            .carousel {
              &-controls {
                .slick {
                  &-next {
                    #{$rdirection}: -10px;
                  }
                  &-prev {
                    #{$ldirection}: -10px;
                  }
                }
              }
            }
            &__inner {
              background-position: bottom center;
              background-size: cover;
              background-repeat: no-repeat;
              padding: 35px 15px 0;
              @include breakpoint($medium-up) {
                padding: 68px 20px 0;
              }
            }
            &--margin {
              margin: 0;
            }
          }
          &__header {
            margin-bottom: 5px;
            @include breakpoint($medium-up) {
              margin-bottom: 25px;
            }
          }
        }
      }
      .content {
        &-block {
          &-carousel {
            &-slide {
              margin: 0;
              &__title {
                font-family: $font--strong;
                text-transform: uppercase;
                font-size: 20px;
                line-height: 26px;
                letter-spacing: 1px;
                margin-bottom: 12px;
                padding: 0;
                @include breakpoint($medium-up) {
                  margin-bottom: 20px;
                  font-size: 22px;
                  line-height: 28px;
                }
              }
              &__subtitle {
                font-size: 12px;
                line-height: 18px;
                letter-spacing: 1px;
              }
              &__link {
                margin-bottom: 24px;
                display: block;
                border: none;
              }
            }
          }
        }
      }
      .slick {
        &-dots {
          li {
            button {
              &:before {
                background: transparent;
                border: 1px solid $color-white;
              }
            }
            &.slick {
              &-active {
                button {
                  &:before {
                    background: $color-white;
                  }
                }
              }
            }
          }
        }
      }
      .campaign {
        &-sub {
          &-title {
            margin-bottom: 30px;
          }
        }
        &-content {
          max-width: 966px;
          margin: 0 auto;
          overflow: auto;
          text-align: center;
        }
        &-header {
          text-align: center;
          padding-bottom: 30px;
        }
        &-image {
          &-content {
            float: none;
            padding: 0;
            width: 100%;
            margin-bottom: 30px;
            @include breakpoint($medium-up) {
              float: $ldirection;
              width: 50%;
              margin-bottom: 0;
              padding: 0 40px;
            }
            img {
              width: 100%;
              margin-bottom: 30px;
            }
          }
        }
        &-video {
          &-content {
            float: none;
            padding: 0;
            width: 100%;
            margin-bottom: 30px;
            @include breakpoint($medium-up) {
              float: $ldirection;
              width: 50%;
              margin-bottom: 0;
              padding: 0 40px;
            }
            video {
              width: 100%;
              margin-bottom: 30px;
            }
          }
        }
      }
      .carousel {
        &-dots {
          position: absolute;
          bottom: 30px;
          z-index: 1;
        }
        &-controls {
          .slick {
            &-arrow {
              &:before {
                color: $color-white;
                font-size: 40px;
                opacity: 0.9;
                font-weight: normal;
              }
            }
          }
        }
      }
    }
    &__product {
      &-slider {
        .basic {
          &-formatter {
            &__items {
              background-position: bottom center;
              background-size: cover;
              background-repeat: no-repeat;
            }
          }
        }
        .carousel {
          &-controls {
            .slick-arrow {
              &:before {
                display: none;
                @include breakpoint($medium-up) {
                  display: block;
                }
              }
            }
            .slick {
              &-next {
                #{$rdirection}: 0;
              }
              &-prev {
                #{$ldirection}: 0;
              }
            }
          }
        }
        .product {
          &-grid {
            .carousel {
              &-dots {
                top: auto;
                bottom: -40px;
              }
            }
            .slick {
              &-dots {
                li {
                  button {
                    &:before {
                      background: $color-black;
                      border: 1px solid $color-white;
                    }
                  }
                  &.slick {
                    &-active {
                      button {
                        &:before {
                          background: $color-white;
                        }
                      }
                    }
                  }
                }
              }
            }
            &-wrapper {
              max-width: 100%;
              padding-bottom: 70px;
              color: $color-white;
              @include breakpoint($medium-up) {
                padding-bottom: 62px;
              }
              .product {
                &-grid {
                  max-width: 826px;
                  margin: 0px auto;
                }
              }
            }
            &.product {
              &-grid {
                &--carousel {
                  .product {
                    &-grid {
                      &__content {
                        width: 100%;
                        padding: 0 8px;
                        margin: 0;
                        @include breakpoint($medium-up) {
                          padding: 0;
                        }
                      }
                    }
                    &__image {
                      &-link {
                        margin-bottom: 30px;
                      }
                    }
                  }
                }
              }
            }
          }
          &-brief {
            &__container {
              padding: 0 6px 0;
              @include breakpoint($medium-up) {
                padding: 0 13px 0;
              }
              .product {
                &-brief {
                  &__quickshop {
                    &-launch {
                      &-wrapper {
                        margin: 0;
                        padding-bottom: 12px;
                        a {
                          color: $color-white;
                        }
                      }
                    }
                  }
                  &__name {
                    margin: 0;
                    padding-bottom: 5px;
                    font-weight: normal;
                    a {
                      color: $color-white;
                      font-size: 12px;
                      line-height: 18px;
                    }
                  }
                  &__price {
                    margin: 0;
                    padding-bottom: 10px;
                    font-weight: normal;
                  }
                }
                &-rating {
                  padding-bottom: 7px;
                }
                &__image {
                  &-link {
                    .product {
                      &__image {
                        max-width: 100%;
                      }
                    }
                  }
                }
              }
            }
          }
          &__image {
            &-link {
              margin-bottom: 18px;
            }
          }
          &-sku {
            &-price {
              &__value {
                font-weight: normal;
                padding-bottom: 10px;
              }
            }
          }
        }
      }
    }
  }
}

/*** Millennial Gallery ***/

.millennial {
  &-gallery {
    .basic-carousel-formatter {
      background: $color-black;
      .basic {
        &-carousel {
          &__subtitle {
            display: none;
          }
          &__title {
            &-rule {
              width: auto;
              border: none;
            }
            &-text {
              background: $color-black;
              color: $color-white;
              font-family: $font--strong;
              font-size: 30px;
              line-height: 1.1;
              padding: 32px 40px 0;
              letter-spacing: 3px;
              @include breakpoint($medium-up) {
                padding-top: 20px;
              }
            }
          }
          &__slide {
            padding: 0;
          }
          &-slider {
            @include breakpoint($medium-up) {
              max-width: 1280px;
            }
          }
        }
      }
      .carousel {
        &-dots {
          display: none;
        }
        &-controls {
          top: 180px;
          @include breakpoint($medium-up) {
            top: 260px;
          }
          @include breakpoint($tablet-only) {
            top: 220px;
          }
          .slick {
            &-next {
              #{$rdirection}: 5px;
              @include breakpoint($medium-up) {
                #{$rdirection}: 200px;
              }
              @include breakpoint($tablet-only) {
                #{$rdirection}: 100px;
              }
            }
            &-prev {
              #{$ldirection}: 5px;
              @include breakpoint($medium-up) {
                #{$ldirection}: 200px;
              }
              @include breakpoint($tablet-only) {
                #{$ldirection}: 100px;
              }
            }
            &-arrow {
              &:before {
                color: $color-white;
                @include breakpoint($medium-up) {
                  top: -50%;
                }
              }
            }
          }
        }
      }
    }
    .slick {
      &-active {
        .millennial {
          &-gallery {
            &__wrapper {
              display: block;
            }
          }
        }
      }
    }
    &__container {
      margin: 35px 0;
      @include breakpoint($medium-up) {
        margin: 40px 0 60px;
      }
    }
    &__count {
      color: $color-white;
      position: absolute;
      top: -40px;
      font-size: 15px;
      font-family: $font--strong;
      #{$ldirection}: 12px;
      @include breakpoint($medium-up) {
        top: 40px;
        font-size: 16px;
        #{$ldirection}: 0;
      }
      @include breakpoint($tablet-only) {
        #{$ldirection}: 0;
      }
    }
    &__image {
      width: 100%;
    }
    &__title {
      font-family: $font--strong;
      color: $color-white;
      font-size: 25px;
      letter-spacing: 2px;
      padding-top: 0;
      line-height: 18px;
      text-transform: uppercase;
      @include breakpoint($medium-up) {
        font-size: 24px;
      }
    }
    &__description {
      color: $color-white;
      margin: 20px auto 0;
      @include breakpoint($medium-up) {
        margin: 25px auto 0;
      }
      p {
        @include font-face--helvetica-roman;
        font-size: 12px;
        line-height: 20px;
        margin: 10px 0 0;
        letter-spacing: 0;
        @include breakpoint($medium-up) {
          font-size: 14px;
          line-height: 24px;
        }
      }
    }
    &__logo {
      width: 300px;
      display: block;
      margin: 50px auto;
    }
    &__action {
      min-width: 250px;
      font-size: 14px;
      padding: 16px;
      background: $color-light-dark-red;
      text-transform: uppercase;
      font-family: $font--strong;
      color: $color-white;
      letter-spacing: 2px;
      display: inline-block;
      border: none;
      margin: 0 auto 50px;
      @include breakpoint($medium-up) {
        font-size: 16px;
        margin: 0 auto;
        padding: 16px 40px;
      }
    }
    &__content {
      width: 100%;
      padding: 30px 20px 50px;
      @include breakpoint($medium-up) {
        width: 560px;
        margin: 0 auto;
        padding: 50px 0;
      }
      @include breakpoint($tablet-only) {
        width: 100%;
      }
    }
    &__wrapper {
      position: relative;
      display: none;
    }
  }
}

/*** Millennial Video Collections ***/

.millennial-collections {
  &__video {
    .basic-formatter {
      &__header {
        h2 {
          font-family: $font--strong;
          text-transform: uppercase;
          letter-spacing: 1.5px;
        }
      }
      &__item {
        .basic-carousel {
          &__slide {
            font-family: $font--strong;
            letter-spacing: 1.5px;
            font-size: 16px;
          }
        }
      }
    }
  }
}
